import React from 'react'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'

function LineChart({ chartData }) {
    return (
        <Line
            data={chartData}
            options={{
                scales: {
                    y: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                            stepSize: 10,
                            callback: function (value) { return value + "%" },
                        }
                    }
                },
                legend: {
                    labels: {
                        fontSize: 25,
                    },
                },
            }}
        />
    )

}

export default LineChart