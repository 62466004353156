import React from "react";
/* Como varias funciones utilizban un template de mensaje,
    decidi separarlo como un contenedor. - Rodrigo Montalvan */
function Msg({contenido, subcontenido}) {

    return (
        <div className="p-0 ps-2">
            <span>{contenido}</span>
            <p className="p-0 py-2 m-0">{subcontenido}</p>
        </div>
    )
}

export default Msg