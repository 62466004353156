import React from 'react'
import BriefInfo from '../components/Brief/Brief'
import Layout from '../hocs/Layout'
import BriefSnap from '../components/Brief/BriefSnap'
function Brief({id}) {

    

    if (id === 'generate') {
        return (
            <Layout>       
                <BriefInfo />
            </Layout>
        )
    } else {
        return (
            <Layout>
                <BriefSnap />
            </Layout>
        )
    }
}

export default Brief