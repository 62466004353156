import React, { useState, useEffect, createContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 } from "uuid";
import { static_items } from "./data";
import { EtapaStyle, EtapaWrapper, ListContainer, ListHeader } from "./styles";
import ListItems from "./Tareas";
import BtnAdd from "../../ModalComp/BtnAdd";
import Collapsible from 'react-collapsible';
import { useMediaQuery } from 'react-responsive'
import axios from 'axios'
import { IconContext } from 'react-icons';
import { FiChevronDown } from "react-icons/fi";
import moment from 'moment';
import { useParams } from 'react-router-dom'
import EtapaComp from "./EtapaComp";
import { toast } from 'react-toastify';

import secureLocalStorage from 'react-secure-storage'
import Msg from "../../containers/Message";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 1120 })
    return isNotMobile ? children : null
}

function Etapas({ check, users }) {
    // const [state, setState] = useState(static_items)

    const [stages, setStages] = useState([])
    const { project_url } = useParams()

    const [antes, setAntes] = useState([])
    const [despues, setDespues] = useState([])

    const [stageAntes, setStageAntes] = useState([])
    const [stageDespues, setStageDespues] = useState([])

    const [todoAntes, setTodoAntes] = useState([])
    const [todoDespues, setTodoDespues] = useState([])

    const [modalAdd, setModelAdd] = useState(false)

    useEffect(() => {
        const getTasksStages = async () => {
            try {
                const res = await axios.get(`https://kpinza.cl/api/task-stages/${secureLocalStorage.getItem("user")}/${project_url}/`,
                    {
                        headers: {
                            "Authorization": "Token " + secureLocalStorage.getItem("token")
                        }
                    })
                if (res.status === 200) {
                    setStages(res.data)
                }
            } catch (error) {
            }
        }
        getTasksStages()
    }, [project_url])

    const updateTaskIndex = async (id, index) => {
        try {
            const res = await axios.put(`https://kpinza.cl/api/change-task-position/${project_url}/`,
                {
                    user_id: secureLocalStorage.getItem("user"),
                    task_id: id,
                    newIndex: index,
                },
                {
                    headers: {
                        'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                    }
                }
            )
        } catch (e) {
        }
    }

    const updateStageIndex = async (id, index) => {
        try {
            const res = await axios.put(`https://kpinza.cl/api/change-stage-position/${project_url}/`,
                {
                    user_id: secureLocalStorage.getItem("user"),
                    task_stage_id: id,
                    newIndex: index
                },
                {
                    headers: {
                        'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                    }
                }
            )
        } catch (e) {
        }
    }

    const updateTaskToStage = async (id, newStage) => {
        try {
            const res = await axios.put(`https://kpinza.cl/api/change-task-to-stage/${project_url}/`,
                {
                    user_id: secureLocalStorage.getItem("user"),
                    task_id: id,
                    task_stage_id: newStage
                },
                {
                    headers: {
                        'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                    }
                })
        } catch (e) {
        }
    }
    /* Cambie varios detalles debido a un bug en el que
        al cambiar una tarea de etapa, esta no se actualizaba en el backend. - Rodrigo Montalvan */
    function onDragEnd(result) {

        if (!result.destination) {
            return;
        }
        const sourceIndex = result.source.index
        const destIndex = result.destination.index

        setStageAntes(stages)

        if (result.type === 'droppableItem') {
            const items = reorder(stages, sourceIndex, destIndex)
            setStageDespues(items)
            setStages(items)

        } else if (result.type === 'droppableSubItem') {
            const itemSubItemMap = stages.reduce((acc, item) => {
                acc[item.id] = item.tasks
                return acc;
            }, {})

            const sourceParentId = result.source.droppableId
            const destParentId = result.destination.droppableId

            const sourceSubItems = itemSubItemMap[sourceParentId]
            const destSubItems = itemSubItemMap[destParentId]

            // ACA JOSE
            setAntes(sourceSubItems)

            let newItems = [...stages]

            if (sourceParentId === destParentId) {
                let newSourceSubItems = [...sourceSubItems]
                const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)

                const reorderedSubItems = reorder(
                    sourceSubItems,
                    sourceIndex,
                    destIndex
                )

                setDespues(reorderedSubItems)

                //STATE UPDATE
                newItems = newItems.map(item => {
                    if (item.id === sourceParentId) {
                        item.tasks = reorderedSubItems;
                    }
                    return item
                })

                updateTaskToStage(draggedItem.id, destParentId)
                setStages(newItems)
                for (let i = 0; i < stages.length; i++) {
                    for (let j = 0; j < stages[i].tasks.length; j++) {
                        updateTaskIndex(stages[i].tasks[j].id, j)
                    }
                }

            } else {
                let newSourceSubItems = [...sourceSubItems]
                const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)
                // setDespues(newSourceSubItems)

                setStageDespues(newSourceSubItems)

                // changeTaskToStage(newSourceSubItems.item.task_state_id, )

                let newDestSubItems = [...destSubItems]
                newDestSubItems.splice(destIndex, 0, draggedItem)

                //STATE UPDATE
                newItems = newItems.map(item => {
                    if (item.id === sourceParentId) {
                        item.tasks = newSourceSubItems;
                    } else if (item.id === destParentId) {
                        item.tasks = newDestSubItems
                    }
                    return item
                })
                updateTaskToStage(draggedItem.id, destParentId)
                setStages(newItems)
                for (let i = 0; i < stages.length; i++) {
                    for (let j = 0; j < stages[i].tasks.length; j++) {
                        updateTaskIndex(stages[i].tasks[j].id, j)
                    }
                }
            }
        }
    }


    /* var cambiosIndex = despues.reduce((acc, item, index) => {
        if (index >= antes.length || item !== antes[index]) {
            // acc.push(index + 1);
            acc.push({ item, index });
        }
        return acc;
    }, []); */

    var cambiosStageIndex = stageDespues.reduce((acc, item, index) => {
        if (index >= stageAntes.length || item !== stageAntes[index]) {
            acc.push({ item, index })
        }
        return acc
    }, [])

    if (cambiosStageIndex.length > 0) {
        cambiosStageIndex.map((stage) => {
            updateStageIndex(stage['item'].id, stage.index)
        })
    }

    /* if (cambiosIndex.length > 0) {
        cambiosIndex.map((task) => {
            updateTaskIndex(task['item'].id, task['index'])
        })
    } */
    /* Agregue un toast para cuando no hay etapas creadas. */
    const addTarea = async (e) => {
        e.preventDefault()
        if (stages.length === 0) {
            toast(<Msg contenido={'Debe haber al menos una etapa.'}/>, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                type: 'error',
                theme: "light",
            });
            return
        } else {
            try {
                const horas = e.target['add-task'].value.replace(':', '')
                const select = document.getElementById('select').value
                const etapaSeleccionada = stages[select]


                const index = etapaSeleccionada.tasks.length

                const nombreTarea = e.target['task_name_input'].value
                if (nombreTarea === '' || horas === '') {
                    toast(<Msg contenido={'Todos los campos son obligatorios.'}/>, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        type: 'error',
                        theme: "light",
                    });
                    return;
                }
                const res = await axios.post(
                    `https://kpinza.cl/api/create-task/${project_url}/`,
                    {
                        user_id: secureLocalStorage.getItem("user"),
                        task_name: e.target[0].value,
                        feit: moment(e.target.start_id.value, "YYYY-MM-DD").format("YYYY-MM-DD"),
                        fett: moment(e.target.end_id.value, "YYYY-MM-DD").format("YYYY-MM-DD"),
                        estimated_time: horas,
                        index_task: index,
                        task_stage: etapaSeleccionada.id,
                    },
                    {
                        headers: {
                            Authorization: 'Token ' + secureLocalStorage.getItem('token')
                        }
                    }
                )
                if (res.status === 201) {
                    const newTask = {
                        id: res.data.data.id,
                        task_name: e.target['task_name'].value,
                        feit: moment(e.target.start_id.value, "YYYY-MM-DD").format("YYYY-MM-DD"),
                        fett: moment(e.target.end_id.value, "YYYY-MM-DD").format("YYYY-MM-DD"),
                        estimated_time: horas,
                        index_task: index,
                        task_stage: etapaSeleccionada.id,
                        task_state_id: res.data.data.task_state,
                        assigned_user_id: secureLocalStorage.getItem("user"),
                    }
                    etapaSeleccionada.tasks.push(newTask)
                    const newStages = stages.map(item => {
                        return item
                    })
                    setStages(newStages)
                    setModelAdd(false)
                }
            } catch (error) {
                toast(<Msg contenido={error.response.data.message}/>, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    type: 'error',
                    theme: "light",
                });
            }
        }
    }

    const addEtapa = async (e) => {
        e.preventDefault()
        try {
            const index = stages.length
            const res = await axios.post(
                `https://kpinza.cl/api/create-task-stage/${project_url}/`,
                {
                    user_id: secureLocalStorage.getItem("user"),
                    stage_name: e.target["task"].value,
                    index_stage: index
                },
                {
                    headers: {
                        Authorization: 'Token ' + secureLocalStorage.getItem('token')
                    }
                }
            )
            if (res.status === 201) {

                const newStage = {
                    id: res.data.data.id,
                    stage_name: res.data.data.stage_name,
                    stage_index: res.data.data.index_stage,
                    tasks: []
                }
                setStages(prevStages => [...prevStages, newStage])
                setModelAdd(false)
            }
        } catch (error) {
            toast(<Msg contenido={'Este campo no puede estar vacío.'}/>, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                type: 'error',
                theme: "light",
            });
        }
    }

    return (
        <>
            <IconContext.Provider value={{ color: '#ffffff', size: '25px' }}>
                <ListContainer className='container-fluid mb-5 mt-3'>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" type="droppableItem">
                            {(provided, snapshot) => (
                                <div ref={provided.innerRef}>
                                    <Default>
                                        <ListHeader className="child1 row">
                                            <div><p className="m-0" style={{ fontSize: '14px' }}>Nombre</p></div>
                                            <div><p className="m-0" style={{ fontSize: '14px' }}>Estado</p></div>
                                            <div><p className="m-0" style={{ fontSize: '14px' }}>Inicio - Entrega estimada</p></div>
                                            <div><p className="m-0" style={{ fontSize: '14px' }}>Inicio Real</p></div>
                                            <div><p className="m-0" style={{ fontSize: '14px' }}>Entrega Real</p></div>
                                            <div><p className="m-0" style={{ fontSize: '14px' }}>Tiempo Estimado</p></div>
                                            <div><p className="m-0" style={{ fontSize: '14px' }}>Tiempo Real</p></div>
                                            <div><p className="m-0" style={{ fontSize: '14px' }}>Responsable</p></div>
                                        </ListHeader>
                                    </Default>
                                    <div className="child2 row">
                                        {stages.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.stage_name + item.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <div className="p-0 m-0 child2child1">
                                                        <EtapaWrapper
                                                            className="col"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}>
                                                            <Collapsible
                                                                transitionTime={200}
                                                                overflowWhenOpen={"visible"}
                                                                easing='ease' open={true}
                                                                trigger={
                                                                    <EtapaStyle>
                                                                        <p className="h6 p-0 m-0 ps-2 disableSelect d-flex align-items-center">
                                                                            <span><FiChevronDown id="toggleArrow" /></span>
                                                                            <EtapaComp item={item} index={index} stages={stages} setStages={setStages} />
                                                                        </p>
                                                                        <span className="drag" {...provided.dragHandleProps}>
                                                                            <i className="fa-solid fa-grip"></i>
                                                                        </span>
                                                                    </EtapaStyle>
                                                                } >
                                                                <ListItems
                                                                    users={users}
                                                                    check={check}
                                                                    stages={stages}
                                                                    setStages={setStages}
                                                                    tasks={item.tasks}
                                                                    type={item.id}
                                                                    stageIndex={index} />
                                                            </Collapsible>
                                                        </EtapaWrapper>
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </ListContainer>
                <BtnAdd addEtapa={addEtapa} stages={stages} 
                addTarea={addTarea} 
                modalAdd={modalAdd}
                setModalAdd={setModelAdd}/>
            </IconContext.Provider>
        </>
    )
}

export default Etapas