import { useContext, useState } from 'react'
import EstadoTarea from './dropDowns';
import DateRangePickerComp from './DatePickers/DateRangePickerComp';
import DateSinglePicker from './DatePickers/DateSinglePicker'
import Select from 'react-select';
import { IconContext } from 'react-icons';
import { StyledDateRangePickerWrapper } from './DatePickers/styles';
import { RiDeleteBin6Fill } from 'react-icons/ri'
import { DeleteBtnWrapper, DeleteButton, Option } from './styles';
import Swal from 'sweetalert2'
import axios from 'axios'
import CambiarNombreTarea from './CambiarInputs/CambiarNombreTarea';
import { useParams } from 'react-router-dom'
import moment from 'moment'
import TimeInput from './TimeInput'
import secureLocalStorage from 'react-secure-storage'
import SideBarContext from '../navigation/SideBarContext';
/* Ahora todos los inputs se deshabilitan si la
    tarea se considera como Entregada; tampoco
    es posible eliminarlas. - Rodrigo Montalvan */
function Inputs({ item, stages, setStages, stageId, users }) {

    const [hover, setHover] = useState(false)
    const [status, setStatus] = useState(item["task_state_id"])
    const { project_url } = useParams()
    const {show, setShow} = useContext(SideBarContext)
    // Refs
    const [texto, setTexto] = useState(item['task_name'])
    const [showInput, setShowInput] = useState(false)
    const [matches, setMatches] = useState(false);

    function validarCampos(item, campo) {
        if (campo === 'inicio-real') {
            if (item.feit === null) {
                console.log('no puedes editar la fecha de inicio real si no has ingresado el inicio estimado')
            }
        } else if (campo === 'entrega-real') {
            if (item.fett === null) {
                console.log('no puedes editar este campo si no tienes la fecha de termino estimada ;)')
            }
        }
    }

    const deleteTask = () => {
        if (show === true) {
            setShow(false)
        }
        Swal.fire({
            title: '¿Estás seguro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminalo!',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(
                        `https://kpinza.cl/api/delete-task/${secureLocalStorage.getItem("user")}/${project_url}/${item.id}/`,
                        {
                            headers: {
                                'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                            }
                        }
                    )
                    if (res.status === 200) {
                        Swal.fire(
                            'Eliminado!',
                            '',
                            'success'
                        )
                        let etapa = {}
                        let tasks = {}
                        stages.map((stage, index) => {
                            if (stage.id === stageId) {
                                etapa = stage
                                tasks = stage.tasks.filter(task => task.id !== item.id)
                                etapa.tasks = tasks
                            }
                        })
                        setStages(stages.map(x => x.id === etapa.id ? { ...etapa } : x))
                    }

                } catch (e) {
                    if (result.isConfirmed) {
                        Swal.fire(
                            'No eliminado!',
                            '',
                            'error'
                        )
                    }
                }
            }
        })
    }

    const changeDateInicio = async (date) => {
        const newDate = date ? moment(date).format("YYYY-MM-DD") : ''
        try {
            const res = await axios.put(`https://kpinza.cl/api/edit-task/${project_url}/`,
                { 
                    user_id: secureLocalStorage.getItem("user"),
                    task_id: item.id, 
                    frit: newDate 
                },
                { 
                    headers: { 
                        'Authorization': 'Token ' + secureLocalStorage.getItem('token') 
                    } 
                })
            if (res.status === 200) {
                item.frit = newDate
            }
        } catch (error) {
        }
    }

    const changeDateTermino = async (date) => {
        const newDate = date ? moment(date).format("YYYY-MM-DD") : ''
        try {
            const res = await axios.put(`https://kpinza.cl/api/edit-task/${project_url}/`,
                { 
                    user_id: secureLocalStorage.getItem("user"),
                    task_id: item.id, 
                    frtt: newDate 
                },
                { 
                    headers: { 
                        'Authorization': 'Token ' + secureLocalStorage.getItem('token') 
                    } 
                })
            if (res.status === 200) {
                item.frtt = newDate
            }
        } catch (error) {
        }
    }

    let responsables = []
    users.map((member) => {
        let a = { value: member.name, label: member.alias, id: member.id }
        responsables.push(a)
    })

    const cambiarResponsable = async (id) => {
        try {
            const res = await axios.put(`https://kpinza.cl/api/change-responsable/${project_url}/`,
                { 
                    user_id: secureLocalStorage.getItem("user"),
                    task_id: item.id, 
                    nuevo_responsable: id 
                },
                { 
                    headers: { 
                        'Authorization': 'Token ' + secureLocalStorage.getItem('token') 
                    } 
                })
            if (res.status === 200) {
                item.assigned_user_id = id
            }
        } catch (error) {
        }
    }

    const Responsables = () => (
        <Select
            onChange={(res => cambiarResponsable(res.id))}
            options={responsables}
            dropdownIndicator={false}
            defaultValue={
                responsables.map((member) => member.id === item.assigned_user_id && member)
            }
        />
    )

    const updateTaskName = async () => {
        try {
            const res = await axios.put(`https://kpinza.cl/api/change-task-name/${project_url}/`,
                {
                    user_id: secureLocalStorage.getItem("user"),
                    task_id: item.id,
                    task_name: texto
                },
                {
                    headers: {
                        'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                    }
                })
            if (res.status === 200) {
            }
            setShowInput(false)
        } catch (err) {
        }
    }

    return (
        <IconContext.Provider value={{ color: '#ffffff', size: '25px' }}>
            {status === 2 ?
                <div className='d-flex align-items-center inputs' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <div className={`row  align-items-center pt-2 pb-2 text-${matches ? 'start' : 'center'} rowrowrow`} style={{ width: matches ? '100%' : '' }} id={item.id}>
                        <div className='text-start nombre-input'>
                            <CambiarNombreTarea
                                value={texto}
                                handleChange={(e) =>
                                    setTexto(e.target.value)
                                }
                                handleDoubleClick={() =>
                                    setShowInput(true)
                                }
                                handleKeyDown={(e) => e.key === 'Enter' ? updateTaskName() : null}
                                handleBlur={() => { updateTaskName() }}
                                showInputEle={showInput}
                            /></div>

                        <EstadoTarea item={item} disabled={true}/>

                        <StyledDateRangePickerWrapper className='fecha_estimada'>
                            <DateRangePickerComp item={item} 
                            inicio={'Inicio'} entrega={'Entrega'} 
                            start_date={item.feit} end_date={item.fett} 
                            form={false} disabled={true}/>
                        </StyledDateRangePickerWrapper>
                        <div className={`inicio-real ${matches ? 'text-center' : ''}`}>
                            <DateSinglePicker
                                onFocus={() => validarCampos(item, 'inicio-real')}
                                changeDate={changeDateInicio} fecha={item.frit}
                                id='id_inicio' placeholder={'Inicio real'} 
                                disabled={true}/>
                        </div>
                        <div className={`entrega-real ${matches ? 'text-center' : ''}`}>
                            <DateSinglePicker onFocus={() => validarCampos(item, 'entrega-real')}
                                changeDate={changeDateTermino}
                                fecha={item.frtt} id='id_termino'
                                placeholder={'Entrega real'} 
                                disabled={true}/>
                        </div>


                        <div><TimeInput item={item} id='estimated_time' mensaje='Tiempo Estimado' 
                        disabled={true}/></div>
                        <div><TimeInput item={item} id='real_time' mensaje='Tiempo Real'
                        disabled={true}/></div>

                        <div className='responsables'><Responsables /></div>
                        {
                            matches && (
                                <>
                                    <div className='hora-labels d-flex justify-content-between'>
                                        <span className='text-purple'>Tiempo estimada</span>
                                        <span>  -  </span>
                                        <span className='text-dark'>Tiempo Real</span>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div> :
                <div className='d-flex align-items-center inputs' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <div className={`row  align-items-center pt-2 pb-2 text-${matches ? 'start' : 'center'} rowrowrow`} style={{ width: matches ? '100%' : '' }} id={item.id}>
                        <div className='text-start nombre-input'>
                            <CambiarNombreTarea
                                value={texto}
                                handleChange={(e) =>
                                    setTexto(e.target.value)
                                }
                                handleDoubleClick={() =>
                                    setShowInput(true)
                                }
                                handleKeyDown={(e) => e.key === 'Enter' ? updateTaskName() : null}
                                handleBlur={() => { updateTaskName() }}
                                showInputEle={showInput}
                            /></div>

                        <EstadoTarea item={item} setStatus={setStatus}/>

                        <StyledDateRangePickerWrapper className='fecha_estimada'>
                            <DateRangePickerComp item={item} inicio={'Inicio'} entrega={'Entrega'} start_date={item.feit} end_date={item.fett} form={false} />
                        </StyledDateRangePickerWrapper>
                        <div className={`inicio-real ${matches ? 'text-center' : ''}`}>
                            <DateSinglePicker
                                onFocus={() => validarCampos(item, 'inicio-real')}
                                changeDate={changeDateInicio} fecha={item.frit}
                                id='id_inicio' placeholder={'Inicio real'} />
                        </div>
                        <div className={`entrega-real ${matches ? 'text-center' : ''}`}>
                            <DateSinglePicker onFocus={() => validarCampos(item, 'entrega-real')}
                                changeDate={changeDateTermino}
                                fecha={item.frtt} id='id_termino'
                                placeholder={'Entrega real'} />
                        </div>


                        <div><TimeInput item={item} id='estimated_time' mensaje='Tiempo Estimado' /></div>
                        <div><TimeInput item={item} id='real_time' mensaje='Tiempo Real' /></div>

                        <div className='responsables'><Responsables /></div>
                        {
                            matches && (
                                <>
                                    <div className='hora-labels d-flex justify-content-between'>
                                        <span className='text-purple'>Tiempo estimada</span>
                                        <span>  -  </span>
                                        <span className='text-dark'>Tiempo Real</span>
                                    </div>
                                </>
                            )
                        }
                    </div>
                    {
                        hover && item.assigned_user_id == secureLocalStorage.getItem('user') && (
                            <DeleteButton onClick={deleteTask}>
                                <DeleteBtnWrapper>
                                    <RiDeleteBin6Fill />
                                </DeleteBtnWrapper>
                            </DeleteButton>
                        )
                    }
                </div>}
        </IconContext.Provider >
    )
}

export default Inputs