import Collapsible from 'react-collapsible';
import { Contenido, ProyectosHeader, EstudiantesWrapper, Info, InvisibleElement } from './styles';
import ProyectosInd from '../../ModalComp/ProyectosIndicador';
import { useEffect, useState } from 'react';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';

function Proyectos() {
    
    const [projects, setProjects] = useState()
    useEffect(() => {
        async function getProjects() {
            try {
                const res = await axios.get(`https://kpinza.cl/api/get-supervisor-projects/${secureLocalStorage.getItem("user")}/`, {
                    headers: {
                        'Authorization': `Token ${secureLocalStorage.getItem('token')}`
                    }
                })
                if (res.status === 200) {
                    setProjects(res.data.data)   
                }
            } catch (error) {
                
            }
        }
        getProjects()
    }, [])


    return (
        <EstudiantesWrapper className='container-fluid m-auto p-0'>
            <div className="row mb-3 align-items-center">
                <div className="col">
                    <p className='h2 disableSelect'>Proyectos</p>
                </div>
                <div className="col">
                    <table className='table'>
                        <tbody className='table-none'>
                            <tr className='text-center disableSelect'>
                                <td style={{ minWidth: '80px' }}>TTP</td>
                                <td style={{ minWidth: '80px' }}>TEAF</td>
                                <td style={{ minWidth: '80px' }}>TRAF</td>
                                <td style={{ minWidth: '80px' }}>% Avance</td>
                                <td><InvisibleElement /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {projects !== undefined ? projects.map(item => (
                <Collapsible transitionTime={200} overflowWhenOpen={"visible"} easing='ease' open={false} trigger={
                    <ProyectosHeader hover='#EAEDED'>
                        <div className="row align-items-center">
                            <div className="col">
                                <span className='disableSelect ms-2'>{item.project.project_name}</span>
                            </div>
                            <div className="col">
                                <table className='table m-0'>
                                    <tbody className='table-none align-middle'>
                                        <tr className='text-center'>
                                            <td className='text-white' style={{ minWidth: '80px' }}>{item.brief[item.brief.length - 1] !== undefined ? item.brief[item.brief.length - 1].data.totales : 'NB'} </td>
                                            <td className='text-white' style={{ minWidth: '80px' }}>{item.brief[item.brief.length - 1] !== undefined ? item.brief[item.brief.length - 1].data.estimadas : 'NB'}</td>
                                            <td className='text-white' style={{ minWidth: '80px' }}>{item.brief[item.brief.length - 1] !== undefined ? item.brief[item.brief.length - 1].data.entregadas : 'NB'}</td>
                                            <td className='text-white' style={{ minWidth: '80px' }}><span>{item.brief[item.brief.length - 1] !== undefined ? item.brief[item.brief.length - 1].data.avance + '%' : 'NB'}</span></td>
                                            <td className='d-inline-block'><ProyectosInd /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ProyectosHeader>
                }>
                    <Contenido>
                        <Info className='row m-0'>
                            <div>
                                <table className='table table-sm table-borderless m-0 text-center bg-light rounded'>
                                    <thead>
                                        <tr>
                                            <th className='text-start'>Integrantes</th>
                                            <th>Eficacia</th>
                                            <th>Eficiencia</th>
                                            <th>Efectividad</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {console.log(item.brief[item.brief.length - 1].data.members[0])} */}
                                        
                                        {item.brief[item.brief.length - 1] !== undefined ? item.brief[item.brief.length - 1].data.members.map(member => (
                                            <tr>
                                                <td className='text-start'>{member.member}</td>
                                                <td>{member.indicadores.eficacia}%</td>
                                                <td>{member.indicadores.eficiencia}%</td>
                                                <td>{member.indicadores.efectividad * 100}%</td>
                                            </tr>
                                        )) : 'No hay datos para mostrar.'}
                                    </tbody>
                                </table>
                            </div>
                        </Info>
                    </Contenido>
                </Collapsible>
             )) : 'No hay proyectos para mostrar.'} 
        </EstudiantesWrapper>
    )
}

export default Proyectos