import React, { useState } from 'react'
import { FormContainer, ButtonBox, BtnToggle, BtnBackground } from '../Form/FormStyles'
import FormEtapa from './FormEtapa'
import FormTarea from './FormTarea'


function FormEtapaTarea({ addEtapa, stages, addTarea, setOpen }) {

    const [valor, setValor] = useState(0)
    const [altura, setAltura] = useState('350px')
    const [posicion, setPosicion] = useState('')

    const showForm = (boton) => {

        const tarea = document.getElementById('tarea')
        const etapa = document.getElementById('etapa')
        if (boton === 'etapa') {
            setValor(105)
            setAltura('190px')
            setPosicion('-160px')
            tarea.style.display = 'none'
            etapa.style.display = 'block'
        } else {
            setValor(4)
            setAltura('350px')
            setPosicion('')
            etapa.style.display = 'none'
            tarea.style.display = 'block'
        }
    }

    return (
        <>
            <FormContainer altura={altura} posicion={posicion}>
                <ButtonBox>
                    <BtnBackground slider={valor}></BtnBackground>
                    <BtnToggle onClick={() => showForm('tarea')}>Tarea</BtnToggle>
                    <BtnToggle onClick={() => showForm('etapa')}>Etapa</BtnToggle>
                </ButtonBox>

                <FormTarea showForm={showForm} stages={stages} addTarea={addTarea}/>
                <FormEtapa showForm={showForm} addEtapa={addEtapa}/>
            </FormContainer>
        </>
    )
}

export default FormEtapaTarea