import styled from "styled-components";
import { COLORS } from '../../colors'
import { Link } from 'react-router-dom'
// SIDEBAR
export const SidebarLink = styled(Link)`
    display: flex;
    color: white;
`

export const SidebarLabel = styled.span`
  margin-left: 16px;
`;

export const DropdownLink = styled(Link)`
    display: flex;
    font-size: 15px;
    padding: 6px 10px;
    width: 80%;
    border-radius: 10px;
    transition: .3s;
    margin-left: 25px;
    color: ${COLORS.whitesmoke};
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
  &:hover {
    cursor: pointer;
    background-color: ${COLORS.medium};
  }
  `;

export const DropdownLinkMobile = styled(DropdownLink)`
  margin: 0;
  color: ${COLORS.whitesmoke};
  width: 100%;
  border: 4px solid ${COLORS.dark};
  background-color: ${COLORS.dark};
  &:hover {
    cursor: pointer;
    color: ${COLORS.whitesmoke};
  }
  /* padding: 0; */
  `

export const LinkMobileWrapper = styled.div`
  width: 80%;
  margin-left: -90px;
  border-radius: 10px;
  padding: 10px;
  background-color: ${COLORS.medium};
`

export const Notifications = styled.div`
  padding: 5px 9px;
  border-radius: 35px;
  transition: ease .1s;
  cursor: pointer;
  * {
    font-size: 25px;
    color: white;
  }
  &:hover {
    background-color: ${COLORS.dark};
  }
`
export const UserMenu = styled.div`
  background-color: white;
`

export const NotificationsMenu = styled.div`
  background-color: white;
  border-radius: 9px;
  margin-top: 5px;
  /* padding: 9px; */
  p {
    font-size: 14px;
    padding: 9px;
    margin: 0;
    transition: ease .3s;
  }
  p:first-child {
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
  }
  /* p:hover {
    background-color: lightgray;
  } */
  .all {
    border-radius: 0px;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
  }
  /* width: 200px; */
`
