import { AddButton, FormInputs } from '../Form/FormStyles'

function FormEtapa({ showForm, addEtapa }) {

    const handleChange = (e) => {
        const inputNombre = document.getElementById('etapa').elements['task']
        const nombre = inputNombre.value.length
        const maximo = nombre === inputNombre.maxLength
        if (maximo) {
            inputNombre.classList.add('is-invalid')
            inputNombre.classList.remove('mb-2')
        } else {
            inputNombre.classList.remove('is-invalid')
            inputNombre.classList.add('mb-2')
        }
    }

    return (
        <FormInputs id='etapa' onSubmit={addEtapa} showForm={showForm}>
            <input type="text" className='form-control mb-2' placeholder='Nombre Etapa' name='task' onChange={handleChange} maxLength={30} />
            <div className="invalid-feedback p-0 m-0">
                Ha llegado al maximo de caracteres.
            </div>
            <AddButton id='btnEtapa' className="btn mt-2" type='submit'>Añadir</AddButton>
        </FormInputs>
    )
}

export default FormEtapa