import React from "react";
import { Component } from "react";

// Creat an CambiarNombreTarea component

function CambiarNombreTarea({ showInputEle, value, handleChange, handleBlur, handleKeyDown, handleDoubleClick }) {



    return (
        <span>
            {
                showInputEle ? (
                    <input
                        type="text"
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        className="form-control form-control-sm"
                        maxLength={50}
                        id='asd'
                    />
                ) : (
                    <span
                        onDoubleClick={handleDoubleClick}
                        className='remedial-span p-1 disableSelect nombre-tarea text-start text-break'
                        // className="form-control"
                        style={{
                            display: "inline-block",
                            // height: "25px",
                            // minWidth: "",
                        }}
                    >
                        {value}
                    </span>
                )
            }
        </span>
    )
}
// class CambiarNombreTarea extends Component {



//     // Render a <span> element
//     render() {
//         return (
//             <span>
//                 {
//                     this.props.showInputEle ? (
//                         <input
//                             type="text"
//                             value={this.props.value}
//                             onChange={this.props.handleChange}
//                             onBlur={this.props.handleBlur}
//                             onKeyDown={this.props.handleKeyDown}
//                             autoFocus
//                             className="form-control form-control-sm"
//                         />
//                     ) : (
//                         <span
//                             onDoubleClick={this.props.handleDoubleClick}
//                             className='remedial-span p-1 disableSelect nombre-tarea text-start text-break'
//                             // className="form-control"
//                             style={{
//                                 display: "inline-block",
//                                 // height: "25px",
//                                 // minWidth: "",
//                             }}
//                         >
//                             {this.props.value}
//                         </span>
//                     )
//                 }
//             </span>
//         );
//     }
// }

export default CambiarNombreTarea;