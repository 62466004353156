import React from 'react'
import { AddButton, FormInputs, SelectForm } from '../Form/FormStyles'
import DateRangePickerComp from '../List/DatePickers/DateRangePickerComp'
import { StyledDateRangeFormWrapper } from '../List/DatePickers/styles'
import TimeInput from '../List/TimeInput'

function FormTarea({ showForm, stages, addTarea }) {

    const handleChange = (e) => {
        const inputNombre = document.getElementById('tarea').elements['task_name']
        const nombre = inputNombre.value.length
        const maximo = inputNombre.maxLength
        if (nombre === maximo) {
            inputNombre.classList.add('is-invalid')
            inputNombre.classList.remove('mb-3')
            inputNombre.classList.add('mb-1')
        } else {
            inputNombre.classList.remove('is-invalid')
            inputNombre.classList.remove('mb-1')
            inputNombre.classList.add('mb-3')
        }
    }

    return (
        <FormInputs id='tarea' showForm={showForm} onSubmit={addTarea}>
            <input id='task_name_input' type="text" className='form-control mb-3 mt-2' placeholder='Nombre de la tarea' name='task_name' onChange={handleChange} maxLength={50} />
            <div className="invalid-feedback p-0 m-0">
                Ha superado el maximo de caracteres.
            </div>
            <div className='mb-3'>
                <StyledDateRangeFormWrapper className='form-control text-center'>
                    <DateRangePickerComp inicio={'Inicio Estimado'} entrega={'Entrega Estimada'} form={true} />
                </StyledDateRangeFormWrapper>
            </div>

            <TimeInput id='add-task' mensaje='Tiempo estimado' />

            {stages.length === 0
                ? <SelectForm disabled={true} id='select' className="btn btn-secondary form-select mb-3 mt-3">
                    {stages.map((el, index) => {
                        return (
                            <option className='text-break' key={el.id} value={index}>Etapa {index + 1} : {el.stage_name}</option>
                        )
                    })}
                </SelectForm>
                : <SelectForm id='select' className="btn btn-secondary form-select mb-3 mt-3">
                    {stages.map((el, index) => {
                        return (
                            <option className='text-break' key={el.id} value={index}>Etapa {index + 1} : {el.stage_name}</option>
                        )
                    })}
                </SelectForm>}
            <AddButton className='btn' type="submit">Añadir</AddButton>
        </FormInputs>
    )
}

export default FormTarea