import React from 'react'
import { useState, useEffect } from 'react'
import { DATA } from '../estData'
import LineChart from '../LineChart'
import axios from 'axios'
import secureLocalStorage from 'react-secure-storage'
import moment from 'moment'

function ProyectChart({ indicadores }) {

    let ind = []
    indicadores.map((item) => {
        ind.push(item.data.indicadores)
    })

    const datos = {
        labels: indicadores.map((item) => moment(item.created_at, "YYYY-MM-DD").format("DD-MM")),
        datasets: [
            {
                label: 'Eficacia',
                data: ind.map((item) => item.eficacia),
                backgroundColor: '#01D5AC',
                borderColor: '#01D5AC',
                pointRadius: 5,
                lineTension: 0.2,
            },
            {
                label: 'Eficiencia',
                data: ind.map((item) => item.efiencia),
                backgroundColor: '#2B7EFE',
                borderColor: '#2B7EFE',
                pointRadius: 5,
                lineTension: 0.2,
            },
            {
                label: 'Efectividad',
                data: ind.map((item) => item.efectividad),
                backgroundColor: '#9EE677',
                borderColor: '#9EE677',
                pointRadius: 5,
                lineTension: 0.2,
            }
        ]
    }

    return (
        <LineChart chartData={datos} />
    )
}

export default ProyectChart