import styled from "styled-components";
import { COLORS } from "../../colors";

export const EstudiantesWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
`

export const ProyectosHeader = styled.div`
    background-color: ${COLORS.medium};
    color: white;
    padding: 5px;
    transition: .3s;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-bottom: 1px solid ${COLORS.dark};
`

export const Contenido = styled.div`
    background-color: ${COLORS.light};
    padding: 10px;
    color: white;
    margin-bottom: 9px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`

export const Info = styled.div`
    max-width: 50%;
    align-items: center;
    td {
        padding: 5px 9px;
        font-size: 13px;
    }
    th {
        font-size: 15px;
        padding: 5px 9px;
        border-bottom: 1px solid black;
    }
`

export const TresE = styled.table`
    padding: 0;
    margin: 0;
    td {
        padding: 2px;
        text-align: center;
    }
`

export const IndicadorModal = styled.div`
    max-width: 800px;
    background-color: white;
    padding: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 auto;
`

export const Indicadores = styled.div`
cursor: pointer;
    width: fit-content;
    background-color: ${props => props.color} !important;
    border-radius: 5px;
    padding: 5px;
    * {
        font-size: 1.6em;
        color: white;
        display: inline-block;
    }
`

export const InvisibleElement = styled.div`
    width: 45px;
`

export const CloseModal = styled.div`
    padding: 6px 12px;
    border-radius: 10px;
    transition: ease .2s;
    &:hover {
        background-color: lightgray;
    }
`