import { Provider } from 'react-redux';
import store from './store'
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom'

import ListView from './containers/ListView';
import Error404 from './containers/errors/Error404';

import Estudiantes from './containers/Estudiantes';
import ProyectosList from './containers/ProyectosList';
import Brief from './containers/BriefInfo';
import Inicio from './containers/Inicio';
import Register from './containers/RegisterLogin/Register';
import Login from './containers/RegisterLogin/Login';
import ChangePass from './containers/RegisterLogin/ChangePass';
import PassChange from './containers/RegisterLogin/PassChange';
import Notifications from './containers/Notifications';
import HistorialBriefs from './containers/HistorialBriefs';
import secureLocalStorage from "react-secure-storage";
import VerifyAcc from './components/RegisterLogin/VerifyAccount';

import { useEffect, useState } from 'react';

function App() {

	const auth = secureLocalStorage.getItem('auth')
	
	const [isLogged, setIsLogged] = useState()
	
	const Protected = ({ children }) => {
		const location = useLocation()
		const data = location.state
		
		if (data) {
			setIsLogged(true)
		}
		// console.log(data)
		if (auth === true || data === true || isLogged) {
			return children
		} else {
			return <Navigate to="/iniciar-sesion" />
		}
	}

	const AlreadyLoggedIn = ({ children }) => {
		// if (isLoggedIn == 'true' || isLoggedIn !== null) {
		// 	return <Navigate to="/inicio" replace />
		// }
		return children
	}

	return (
		<Provider store={store}>
			<Router>
				<Routes>

					<Route path="*" element={
						<Protected>
                            <Error404 />
                        </Protected>
					} />

					<Route exact path="/proyectos/:project_url/brief" element={
						<Protected>
							<Brief id={'generate'} />
						</Protected>
					} />
					<Route exact path="/proyectos/:project_url/historial-brief/:id_brief" element={
						<Protected>
							<Brief id={'view_only'} />
						</Protected>
					} />

					<Route exact path="/proyectos/:project_url/historial-briefs" element={
						<Protected>
							<HistorialBriefs />
						</Protected>
					} />

					<Route exact path="/proyectos/:project_url" element={
						<Protected>
							<ListView />
						</Protected>
					} />


					<Route exact path="/estudiantes" element={
						<Protected>
							<Estudiantes />
						</Protected>
					} />

					<Route exact path="/listado-proyectos" element={
						<Protected>
							<ProyectosList />
						</Protected>
					} />

					<Route exact path="/inicio" element={
						<Protected>
							<Inicio />
						</Protected>
					} />
					<Route exact path="/notificaciones" element={
						<Protected>
							<Notifications />
						</Protected>
					} />

					<Route exact path="/notificaciones/:id" element={
						<Protected>
							<Notifications />
						</Protected>
					} />

					<Route exact path="/registrarse" element={
						<AlreadyLoggedIn>
							<Register />
						</AlreadyLoggedIn>
					} />
					<Route exact path="/iniciar-sesion" element={
						<AlreadyLoggedIn>
							<Login />
						</AlreadyLoggedIn>
					} />
					<Route exact path="/cambio-pass" element={
						<AlreadyLoggedIn>
							<ChangePass/>
						</AlreadyLoggedIn>
					} />

					<Route exact path="/cambio-pass/:token" element={
						<PassChange/>
					}
					/>

					<Route exact path="/verificar/:token" element={
						<VerifyAcc/>
					} />

				</Routes>
			</Router>
		</Provider>
	);
}

export default App;
