import { useState } from 'react'
// import 'react-dates/initialize';
import 'react-dates-gte-react-17-21.8.0-version-fixed/initialize'
import { DateRangePicker } from 'react-dates-gte-react-17-21.8.0-version-fixed';
import 'react-dates-gte-react-17-21.8.0-version-fixed/lib/css/_datepicker.css';
import moment from 'moment';
import axios from 'axios';
import { useParams } from 'react-router-dom'
import { NavItem } from 'react-bootstrap';
import secureLocalStorage from 'react-secure-storage'
import { toast } from 'react-toastify';
import Msg from '../../../containers/Message';

function DateRangePickerComp({ inicio, entrega, form, start_date, end_date, item, disabled }) {

  const [startDate, setStartDate] = useState(start_date ? moment(start_date, "YYYY-MM-DD") : null);
  const [endDate, setEndDate] = useState(end_date ? moment(end_date, "YYYY-MM-DD") : null);
  const { project_url } = useParams()
  const [focusedInput, setFocusedInput] = useState();

  function changeState(startDate, endDate) {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const updateDates = async () => {

    if (item !== undefined) {
      const newStartDate = startDate ? moment(startDate).format("YYYY-MM-DD") : "";
      const newEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : "";
      try {
        const res = await axios.put(`https://kpinza.cl/api/change-estimated-date/${project_url}/`,
          {
            user_id: secureLocalStorage.getItem("user"),
            task_id: item.id,
            feit: newStartDate,
            fett: newEndDate
          },
          {
            headers: {
              'Authorization': 'Token ' + secureLocalStorage.getItem('token')
            }
          })
        if (res.status === 200) {
        }
      } catch (error) {
        toast(<Msg contenido={error.response.data.message} />, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'error',
          theme: "light",
        });
      }
    }
  }

  return (
    <>
      {disabled ?
        <DateRangePicker
          disabled={true}
          startDate={startDate}
          startDateId='start_id'
          endDate={endDate}
          endDateId='end_id'
          onDatesChange={({ startDate, endDate }) => changeState(startDate, endDate)}
          focusedInput={focusedInput}
          onFocusChange={focusedInput => setFocusedInput(focusedInput)}
          onClose={() => updateDates(startDate, endDate)}
          numberOfMonths={1}
          startDatePlaceholderText={inicio}
          endDatePlaceholderText={entrega}
          small={true}
          displayFormat={() => "YYYY-MM-DD"}
        /> :
        <DateRangePicker
          startDate={startDate}
          startDateId='start_id'
          endDate={endDate}
          endDateId='end_id'
          onDatesChange={({ startDate, endDate }) => changeState(startDate, endDate)}
          focusedInput={focusedInput}
          onFocusChange={focusedInput => setFocusedInput(focusedInput)}
          onClose={() => updateDates(startDate, endDate)}
          numberOfMonths={1}
          startDatePlaceholderText={inicio}
          endDatePlaceholderText={entrega}
          small={true}
          displayFormat={() => "YYYY-MM-DD"}
        />}
    </>
  )
}

export default DateRangePickerComp