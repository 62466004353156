import styled from 'styled-components';
import { useState } from 'react'
import Popup from 'reactjs-popup';


import { FaChartLine, FaTimes } from "react-icons/fa";
import { IndicadorModal, Indicadores, CloseModal } from '../components/ProyectosList/styles';
import ProyectChart from '../Charts/Proyecto/Indicadores';

const StyledPopup = styled(Popup)`
        &-overlay {
            background:rgba(0,0,0,.10);
            position: absolute;
            top: 0;
        }
    `
function ProyectosInd() {

    const Modal = () => {
        return (
            <StyledPopup
                trigger={<Indicadores color='#87BCDE'><FaChartLine /></Indicadores>}
                modal
                closeOnDocumentClick
                closeOnEscape
            >
                <IndicadorModal>
                    <div className="row mb-2">
                        <span role='button' className='d-flex justify-content-between align-items-baseline'>
                            <p className='h2'>Proyecto 1</p>
                        </span>
                        <ProyectChart />
                    </div>
                </IndicadorModal>
            </StyledPopup>
        )
    }

    return (
        <>
            <Modal />
        </>
    )
}

export default ProyectosInd