import React from 'react'
import Layout from '../../hocs/Layout'
import { useNavigate } from 'react-router-dom'

export const Error404 = () => {

    const navigate = useNavigate()

    const redirect = () => {
        navigate("/inicio")
    }

    return (
        <Layout>
            <div className="container d-flex align-items-center justify-content-around">
                <div>
                    <svg width="413.08" height="451.874" viewBox="0 0 413.08 451.874">
                        <g id="colibri" transform="translate(-387.2 -306.819)">
                            <path id="Trazado_1" data-name="Trazado 1" d="M649.148,689.685c7.48-.422,15.48,9.188,21.828,17.373s14.31,24.5,16.928,30.737,3.564,21.159-2.45,20.491c-2.766-.307-11.415-4.009-20.491-12.027s-18.487-24.5-18.487-24.5-6.236-12.7-6.236-20.046S641.669,690.107,649.148,689.685Z" fill="#795a92" />
                            <path id="Trazado_2" data-name="Trazado 2" d="M649.292,688.476c1.864-.133,12.94,7.234,18.22,22.942s3.75,32.608,3.295,39.891c-.4,6.352.128,22.3-5.59,37.641s-13.9,23.743-13.9,23.743,3.4-4.126,1.737-14.477-10.424-26.928-10.424-26.928l-15.925-19.979s-3.185-13.9,2.606-36.483S645.239,688.766,649.292,688.476Z" transform="translate(36 -54)" fill="#f578a6" />
                            <path id="Trazado_4" data-name="Trazado 4" d="M649.148,689.685c5.791.891,17.782,23.641,23.093,35.292s6.682,44.546,5.654,45.231-11.831-4.121-21.245-21.245a98.334,98.334,0,0,1-11.479-43.175C645.172,698.438,643.357,688.794,649.148,689.685Z" transform="translate(-19 -83)" fill="#2b4884" />
                            <path id="Trazado_5" data-name="Trazado 5" d="M648.445,620.047s8.053,1.628,12.85,7.367,6.082,8.138,6.339,15.591-5.311,14.22-5.311,14.22l-10.451-25.7A58.6,58.6,0,0,1,648.445,620.047Z" fill="#2b4884" />
                            <path id="Trazado_6" data-name="Trazado 6" d="M603.209,493.069s19.155,10.691,47,53.9,22.719,79.069,21.159,80.628-50.114-26.95-61.028-52.119-12.918-52.787-11.582-67.487S603.209,493.069,603.209,493.069Z" fill="#91ec90" />
                            <path id="Trazado_8" data-name="Trazado 8" d="M625.9,491.582c2.637-.29,4.583,6.66,15.956,13.319s37.352,11.292,55.014,28.376c24.033,23.743,17.952,44.88,12.74,55.593s-16.5,18.531-16.5,18.531,6.37-15.057,6.37-23.743-4.343-27.29-16.5-41.406-19.038-6.949-32.14-15.057-14.035-8.469-20.268-17.373S623.266,491.872,625.9,491.582Z" fill="#91ec90" />
                            <path id="Trazado_9" data-name="Trazado 9" d="M654.716,533.55c-4.9,3.063,5.568,21.828,13.809,39.423s8.241,30.959,16.928,30.069,11.526-25.391,2.673-42.319S659.616,530.488,654.716,533.55Z" fill="#f578a6" />
                            <path id="Trazado_10" data-name="Trazado 10" d="M599.813,493.125c-6.682,1.559-21.828,47-22.718,72.387s.223,45.66,12.25,58.8,24.5,17.6,27.619,15.814,10.914-63.478,4.009-97.779S606.494,491.566,599.813,493.125Z" fill="#01d5ac" />
                            <path id="Trazado_11" data-name="Trazado 11" d="M602.294,493.069s-6.721,11.666-1.186,51.467S623.645,593.3,623.645,593.3s4.086-24.645-3.427-58.253S602.294,493.069,602.294,493.069Z" fill="#01d461" />
                            <path id="Trazado_12" data-name="Trazado 12" d="M592.677,481.783c1.885-.685,6.168,7.539,1.713,33.238s-14.563,54.312-19.532,69.56.385,29.769-2.4,25.871-13.792-9.295-8.738-41.462S590.792,482.468,592.677,481.783Z" fill="#91ec90" />
                            <path id="Trazado_13" data-name="Trazado 13" d="M596.347,496.218a191.854,191.854,0,0,0-14.379,38.207c-4.88,20.046-5.14,41.976-5.14,41.976a356.962,356.962,0,0,0,12.507-39.577A348.47,348.47,0,0,0,596.347,496.218Z" fill="#01d461" />
                            <path id="Trazado_14" data-name="Trazado 14" d="M588.009,434.8c2.913-4.54,10.023,1.756,17.3,16.962S618.677,493.9,617.649,495.1s-12.507.685-23.986-25.186S585.1,439.336,588.009,434.8Z" fill="#f578a6" />
                            <path id="Trazado_15" data-name="Trazado 15" d="M610.5,443.876c4.969-2.227,6.5-2.334,16.105,4.455,16.962,11.993,13.921,19.96,24.329,29.64s14.906,6.768,25.186,17.133,18.161,21.416,15.934,24.329-7.41-6.039-21.588-13.364-21.8-3.769-35.98-14.392-15.634-14.349-21.416-26.214S605.527,446.1,610.5,443.876Z" fill="#f578a6" />
                            <path id="Trazado_16" data-name="Trazado 16" d="M582.906,454.256c.608,15.815-12.672,32.087-8.921,32.746s19.465-5.677,23.926-30.11c2.636-22-9.11-28.855-12.267-28.285C581.347,429.359,582.3,438.44,582.906,454.256Z" fill="#795a92" />
                            <path id="Trazado_17" data-name="Trazado 17" d="M589.185,433.517c-3.194.75-4.544,5.759-3.419,14.877s7.918,21.6,7.918,21.6,5.669-15.315,4.559-24.475S592.38,432.767,589.185,433.517Z" fill="#773e80" />
                            <path id="Trazado_18" data-name="Trazado 18" d="M497.023,392.86c.343.268,18.2.48,31.336,3.366S542.725,399,551.951,404.4s60.781,32.928,60.781,32.928,6.682,1.225,18.932,12.027,16.482,20.881,30.069,31.182,17.818,11.137,23.164,10.023,10.914-9.577-9.577-46.328c-12.247-14.569-23.212-17.9-33.156-17.884-10.477.015-19.635,4.152-27.2,1.625-8.878-2.964-32.741-11.137-32.741-11.137l-22.273-11.359a100.884,100.884,0,0,0-24.963-9.255C521.075,393.184,496.4,392.369,497.023,392.86Z" fill="#2b4884" />
                            <path id="Trazado_19" data-name="Trazado 19" d="M588.609,409.353c6.853-7.539,48.124-33.388,89.718-3.538s30.726,86.215,30.726,86.215S722.632,506.1,729.6,517.966c2.322,3.952,6.055,19.418,6.208,22.676s14.344,36.534-6.208,66.873c-14.68,32.3-55.3,19.573-55.3,19.573s41.594-11.255,48.934-45.508-15.17-42.573-22.02-69.975-.979-38.168-12.723-61.657-31.318-35.232-48.934-35.232c-6.518,0-24.956,3.915-24.956,3.915s-22.8-2.046-33.112-5.6a15.285,15.285,0,0,1-2.827-1.274c-3.519-2.146-23.339-12.478-43.175-16.79-19.348-4.207-38.721-2.4-38.721-2.4s7.025-7.025,20.9-5.654c2.686.265,14.306,2.7,32.21,8.053S585.776,412.185,588.609,409.353Z" fill="#2b7efe" />
                            <path id="Trazado_7" data-name="Trazado 7" d="M590.066,483.384c-1.716-1.248-9.2,1.248-24.276,16.59s-14.923,38.978-14.923,39.2,14.7.223,28.064-18.264S591.782,484.632,590.066,483.384Z" fill="#01d5ac" />
                            <path id="Trazado_3" data-name="Trazado 3" d="M662.453,704.8c10.326,12.951,10.342,21.336,12.234,26.767s2.576,18.427-1.771,17.845c-.974-.13-7.792-.49-13.069-8.781s-6.62-17.418-8.039-24.382a173.076,173.076,0,0,1-2.956-28.065S652.127,691.852,662.453,704.8Z" transform="translate(13.782 8.875)" fill="#773e80" />
                            <g id="Grupo_1" data-name="Grupo 1" transform="translate(-539.518 105.358)">
                                <path id="Trazado_20" data-name="Trazado 20" d="M1183.139,1539.274c.312-1.56,3.919-4.406,7.876-4.211s7.019,2.885,7.019,6-1.17,5.3-6,6.161c-1.3.231-4.279-.646-5.693-1.482A7.1,7.1,0,0,1,1183.139,1539.274Z" transform="translate(-8 -1204)" fill="#2b7efe" />
                                <path id="Trazado_21" data-name="Trazado 21" d="M1183.411,1540.229c.5.994,3.568,1.092,5.381.858s1.767-.61,3.119-1.326c2.651-1.4,3.47.663,4.211.468s1.833-1.872,0-3.119a9.187,9.187,0,0,0-7.331-1.872C1185.636,1535.881,1182.4,1538.2,1183.411,1540.229Z" transform="translate(-7.612 -1203)" fill="#6ca6ff" />
                            </g>
                            <text id="_" data-name="?" transform="translate(457.16 639.092) rotate(-13)" fill="#f578a6" fontSize={288} fontFamily="SegoeUI, Segoe UI"><tspan x={0} y={0}>?</tspan></text>
                            <text id="_2" data-name="?" transform="translate(730.968 678.025) rotate(22)" fill="#01d5ac" fontSize={111} fontFamily="SegoeUI, Segoe UI"><tspan x={0} y={0}>?</tspan></text>
                        </g>
                    </svg>
                </div>
                <div className="ms-n-5">
                    <h1 className="display-2 mb-3 fw-bold text-secondary">ERROR 404</h1>
                    <p className="mt-3 mb-2">
                        <i className="h3 fw-light">¡Vaya! El colibrí se ha desorientado...</i>
                    </p>
                    <p className="h3 fw-light mt-3 mb-3">La página que buscas no se encuentra.</p>
                    <button className="btn btn-purple text-white mt-3" onClick={redirect}><p className="h5 fw-normal p-1 m-0">Volver a inicio</p></button>
                </div>
            </div>
        </Layout>
    )
}

export default Error404