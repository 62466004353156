import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
function CambioResponsable({ item }) {

    const antes = '#CCCCB3'
    const despues = '#5F912E'

    const data = {
        'atributo_cambiado': 'Inicio Estimado',
        'tarea_antes': {
            'Nombre de la tarea': 'Nombre de la tarea hola',
            'Estado': 'En curso',
            'Inicio Estimado': '20/20/20',
            'Entrega Estimada': '20/20/20',
            'Inicio Real': '20/20/20',
            'Entrega Real': '20/20/20',
            'Tiempo Estimado': '30:30',
            'Tiempo Real': ''
        },
        'tarea_despues': {
            'Nombre de la tarea': 'Nombre de la tarea hola',
            'Estado': 'En curso',
            'Inicio Estimado': '20/20/20',
            'Entrega Estimada': '20/20/20',
            'Inicio Real': '20/20/20',
            'Entrega Teal': '20/20/20',
            'Tiempo Estimado': '30:30',
            'Tiempo Real': ''
        }
    }

    return (
        <>
            <div className='notification-header'>
                <p className='h3 fw-light mt-3 mb-3'>Reasignación de tarea</p>
                <p className='small'><span className='fw-semibold'>Proyecto:</span>{item.data.project_name}</p>
                <p className='small'><span className='fw-semibold'>Etapa 0:</span> Inicio del desarrollo</p>
                <p className='small pb-3'>Has recibido esta notificación debido a que el usuario {item.data.user_invite} (Joseblock12) ha reasignado una tarea en la que estas o estabas involucrado.</p>
            </div>

            <div className="row m-auto align-items-center justify-content-around p-0 m-0 mt-3 modifications-table">
                <div className="col-5 p-0">
                    <table className="table table-hover table-sm align-middle">
                        <tbody className=''>
                            <tr>
                                <td>Responsable</td>
                                <td style={{ backgroundColor: antes }}>José Gutierrez</td>
                            </tr>
                            <tr>
                                <td>Nombre de la tarea</td>
                                <td>Inserte nombre de la tarea uy soy largo a ver que tan largo puedo ser hola</td>
                            </tr>
                            <tr>
                                <td>Estado</td>
                                <td>En curso</td>
                            </tr>
                            <tr>
                                <td>Inicio Estimado</td>
                                <td>20/20/20</td>
                            </tr>
                            <tr>
                                <td>Entrega Estimada</td>
                                <td>20/20/20</td>
                            </tr>
                            <tr>
                                <td>Inicio Real</td>
                                <td>20/20/20</td>
                            </tr>
                            <tr>
                                <td>Entrega Real</td>
                                <td>20/20/20</td>
                            </tr>
                            <tr>
                                <td>Tiempo Estimado</td>
                                <td>20/20/20</td>
                            </tr>
                            <tr>
                                <td>Tiempo Real</td>
                                <td>20/20/20</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-1 p-0">
                    <HiArrowNarrowRight style={{ fontSize: '60px' }} />
                </div>
                <div className="col-5 p-0">
                    <table className="table table-hover table-sm align-middle">
                        <tbody className=''>
                            <tr>
                                <td>Responsable</td>
                                <td style={{ backgroundColor: despues }}>Denisse Alfaro Pérez</td>
                            </tr>
                            <tr>
                                <td>Nombre de la tarea</td>
                                <td>Inserte nombre de la tarea uy soy largo a ver que tan largo puedo ser hola</td>
                            </tr>
                            <tr>
                                <td>Estado</td>
                                <td>En curso</td>
                            </tr>
                            <tr>
                                <td>Inicio Estimado</td>
                                <td>20/20/20</td>
                            </tr>
                            <tr>
                                <td>Entrega Estimada</td>
                                <td>20/20/20</td>
                            </tr>
                            <tr>
                                <td>Inicio Real</td>
                                <td>20/20/20</td>
                            </tr>
                            <tr>
                                <td>Entrega Real</td>
                                <td>20/20/20</td>
                            </tr>
                            <tr>
                                <td>Tiempo Estimado</td>
                                <td>20/20/20</td>
                            </tr>
                            <tr>
                                <td>Tiempo Real</td>
                                <td>20/20/20</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default CambioResponsable