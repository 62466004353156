import React, { useContext, useState } from 'react'
import styled from 'styled-components';
import { COLORS } from '../colors'
import Popup from 'reactjs-popup';
import FormEditProyecto from '../components/Form/FormEditProyecto';
import { EditHeader } from '../components/List/HeaderProyecto/styles';
import { IoSettingsSharp } from "react-icons/io5";
import SideBarContext from '../components/navigation/SideBarContext';


const StyledPopup = styled(Popup)`
        &-overlay {
            background:rgba(0,0,0,.10);
            position: absolute;
            top: 0;
        }
    `

function EditProyectModal({ project, setProject }) {

    const [open, setOpen] = useState(false)
    const {show, setShow} = useContext(SideBarContext)
    
    const openModal = () => {
        if (show === true) {
            setShow(false)
        }
        setOpen(true)
    }
    const closeModal = () => setOpen(false)

    const Modal = ({ project }) => (
        <>
            <EditHeader 
            className='btn-medium' 
            role='button'
            onClick={openModal}>
                <IoSettingsSharp />
            </EditHeader>
            <StyledPopup
                open={open}
                closeOnDocumentClick
                onClose={closeModal}
            >
                <FormEditProyecto project={project}
                setProject={setProject} 
                closeModal={closeModal}/>
            </StyledPopup>
        </>
    )

    return (
        <>
            <Modal project={project} />
        </>
    )
}

export default EditProyectModal