import React from 'react'
import HistorialBriefsList from '../components/Brief/HistorialBriefsList'
import Layout from '../hocs/Layout'

function HistorialBriefs() {
    return (
        <Layout>
            <HistorialBriefsList />
        </Layout>
    )
}

export default HistorialBriefs