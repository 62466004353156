import React, { useState, useRef } from 'react'
import { templateParser, templateFormatter, parseDigit } from 'input-format'
import ReactInput from 'input-format/react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useEffect } from 'react'
import secureLocalStorage from 'react-secure-storage'

const TEMPLATE = 'xx:xx'
const parse = templateParser(TEMPLATE, parseDigit)
const format = templateFormatter(TEMPLATE)

const TimeInput = ({ item, id, mensaje, disabled }) => {

    const { project_url } = useParams()
    const hora = useRef()
    const [horas, setHoras] = useState()

    const HandleChange = (e) => {
        setHoras(e)
        if (hora.current.value.length === 5) {
            if (hora.current.value.substring(hora.current.value.length - 2) >= 59) {
                let replacement = '59';
                let result = hora.current.value.replace(/.{0,3}$/, '') + replacement;
                setHoras(result)
            }
        }
    }


    useEffect(() => {
        if (id === 'estimated_time') {
            setHoras(item.estimated_time)
        } else if (id === 'real_time') {
            setHoras(item.real_time)
        }

    }, [TimeInput])

    /* Aqui habia un bug que los tiempos no se actualizaban correctamente,
        dado que no se actualizaba el state. - Rodrigo Montalvan */

    const updateRealTime = async (resultado) => {
        try {
            const res = await axios.put(`https://kpinza.cl/api/change-real-time/${project_url}/`,
                {
                    user_id: secureLocalStorage.getItem("user"),
                    task_id: item.id,
                    real_time: resultado
                },
                {
                    headers: {
                        'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                    }
                })
            if (res.status === 200) {
                item.real_time = resultado
            }

        } catch (e) {
        }
    }

    const updateEstimatedTime = async (resultado) => {
        try {
            const res = await axios.put(`https://kpinza.cl/api/change-estimated-time/${project_url}/`,
                {
                    user_id: secureLocalStorage.getItem("user"),
                    task_id: item.id,
                    estimated_time: resultado
                },
                {
                    headers: {
                        'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                    }
                })
            if (res.status === 200) {
                item.estimated_time = resultado
            }

        } catch (e) {
        }
    }
    const updateTime = async (e) => {
        let resultado;
        if (hora.current.value.length < 3) {
            resultado = hora.current.value.padEnd(4, "0");
            setHoras(resultado)
        } else if (hora.current.value.length >= 4) {
            let minutoSlice = hora.current.value.toString().slice(-2)
            let horaSlice = hora.current.value.toString().slice(0, 2)
            let result = minutoSlice.replace(":", "");
            resultado = horaSlice + result.padEnd(2, '0')
            setHoras(resultado)
        }
        if (e.target.id === 'estimated_time') {
            updateEstimatedTime(resultado)
        } else if (e.target.id === 'real_time') {
            updateRealTime(resultado)
        }
    }

    return (
        <>
            {disabled ?
                < ReactInput
                    disabled={true}
                    placeholder={mensaje}
                    className='form-control input-time border'
                    id={id}
                    value={horas}
                    onChange={HandleChange}
                    // onFocus={handleFocus}
                    ref={hora}
                    parse={parse}
                    format={format}
                    onBlur={updateTime}
                /> :
                < ReactInput
                    placeholder={mensaje}
                    className='form-control input-time border'
                    id={id}
                    value={horas}
                    onChange={HandleChange}
                    // onFocus={handleFocus}
                    ref={hora}
                    parse={parse}
                    format={format}
                    onBlur={updateTime}
                />}
        </>
    )
}

export default TimeInput