import styled from "styled-components";
import { COLORS } from "../../colors";

export const IconsWrapper = styled.span`
  background-color:  ${props => props.color};
  color: white;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 20px;
`;

export const NotiContainer = styled.div`
  display: flex;
  border-left: 15px solid ${props => props.color};
  align-items: center;
  min-height: 75px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  cursor: pointer;
  transition: .2s;
  :hover {
    background-color: ${COLORS.whitesmoke};
  }
`