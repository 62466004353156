import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
function Desvincular({ item }) {
    return (
        <>
            <div className='notification-header'>
                <p className='h3 fw-light mt-3 mb-3'>Solicitan desvincular a integrante</p>
                <p className='h4 pb-3'><span className='fw-semibold'>Proyecto:</span> {item.data.project_name}</p>
            </div>

            <div className="row mt-5 m-auto">
                <div className="bg-light rounded p-3">
                    <p>Se solicita desvincular al integrante Denisse Alfaro Pérez (Daralet) del Proyecto {item.data.project_name}.</p>
                    <div className="pt-3 pb-2 text-center">
                        <button className='btn btn-lg btn-danger'>Autorizar desvinculacion</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Desvincular