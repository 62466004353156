import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios'
import secureLocalStorage from 'react-secure-storage';
import { Navigate, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Msg from '../../../containers/Message';

function Invitacion({ item }) {

    const navigate = useNavigate()

    const aceptarInvitacion = async () => {
        try {
            const res = await axios.get(item.data.link, {
                headers: {
                    'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                }
            })
            if (res.status === 200) {
                try {
                    navigate(`/proyectos/${item.data.project_url}`)
                } catch (error) {
                }
            }
        } catch (error) {
            if (error.response.status === 404) {
                toast(<Msg contenido={error.response.data.message}/>, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    type: "error",
                    theme: "light",
                })
            }
            if (error.response.status === 410) {
                toast(<Msg contenido={error.response.data.message}/> , {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    type: "warning",
                    theme: "light",
                })
            }
        }
    }
    return (
        <>
            <div className='notification-header'>
                <p className='h3 fw-light mt-3 mb-3'>{item.data.user_invite} ({item.data.user_alias})</p>
                <p className='h4 pb-3'><span className='fw-semibold'>Proyecto:</span>  {item.data.project_name} </p>
            </div>

            <div className="row mt-5 m-auto">
                <div className="bg-light rounded p-3">
                    <p className='fw-semibold mb-2 m-0'>Descripcion del proyecto:</p>
                    <p>{item.data.project_description}</p>
                    <div className="mt-5 text-center">
                        <button className='btn btn-dark' onClick={aceptarInvitacion}>Aceptar Invitacion</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Invitacion