import React from 'react'
import ContentEditable from 'react-contenteditable'

class Remedial extends React.Component {
  constructor() {
    super()
    this.contentEditable = React.createRef();
    this.state = { html: "" };
  };

  handleChange = evt => {
    this.setState({ html: evt.target.value });
  };

  render = () => {
    return <ContentEditable
      innerRef={this.contentEditable}
      html={this.state.html}
      disabled={false}
      onChange={this.handleChange}
      tagName='article'
      className="remedial-textarea"
    />
  };
};

export default Remedial