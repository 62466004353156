import React, { useState, useEffect } from 'react'
import { NotiContainer } from './styles'

import Modificacion from './templates/Modificacion';
import Eliminacion from './templates/Eliminacion';
import Invitacion from './templates/Invitacion';
import Desvincular from './templates/Desvincular';
import Desvinculado from './templates/Desvinculado';
import InvitacionSupervisor from './templates/InvitacionSupervisor'
import CambioResponsable from './templates/CambioResponsable';

import TiposValidation from './Tipos'

import { Link, useParams } from 'react-router-dom'
import moment from 'moment/moment';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios'
import DaySinglePicker from '../List/DatePickers/DayPicker';

function NotificationView() {

    const { id } = useParams()

    const [searchTerm, setSearchTerm] = useState("")
    const [date, setDate] = useState(null)
    const [notificaciones, setNotificaciones] = useState([])
    const [filtered, setFiltered] = useState([])
    const [uniqueDates, setUniqueDates] = useState([])

    useEffect(() => {
        async function seen() {
            try {
                const notifs = await axios.put(`https://kpinza.cl/api/set-seen/`,
                    {
                        user_id: secureLocalStorage.getItem("user")
                    },
                    {
                        headers: {
                            "Authorization": "Token " + secureLocalStorage.getItem("token")
                        }
                    })
                if (notifs.status === 200) {
                    secureLocalStorage.setItem("isSeen", 0)
                }
            } catch (error) {
            }
        }

        async function getNotifs() {
            try {
                const notifs = await axios.get(`https://kpinza.cl/api/notifications/${secureLocalStorage.getItem("user")}/`,
                    {
                        headers: {
                            "Authorization": "Token " + secureLocalStorage.getItem("token")
                        }
                    })
                if (notifs.status === 200) {
                    setNotificaciones(notifs.data.notifications)
                    setFiltered(notifs.data.notifications)
                    setUniqueDates(notifs.data.dates)
                }
            } catch (error) {
            }
        }
        getNotifs()
        seen()
    }, [])

    useEffect(() => {
        const filter = notificaciones?.filter((notifs) => {
            if (searchTerm === "" || searchTerm === null) {
                return true
            }
            let name = notifs.data.user_invite
            let project = notifs.data.project_name

            if (name.toLowerCase().includes(searchTerm.toLowerCase())) {
                return true
            }
            if (project.toLowerCase().includes(searchTerm.toLowerCase())) {
                return true
            }
        }).filter((notifs) => {
            if (date === "" || date === null) {
                return true
            }
            let fecha = notifs.created_at
            return fecha.includes(moment(date).format("YYYY-MM-DD"))
        })
        setFiltered(filter)
    }, [searchTerm, date])

    const Enviado = ({ item }) => {
        return (
            <div className='hour d-flex flex-column text-end pe-2 pt-2 align-self-start'>
                <span>{moment(item.created_at).format("MM-DD-YYYY")}</span>
                <span>{moment(item.created_at).format("h:mm a")}</span>
            </div>
        )
    }

    let color = ''
    const Mensaje = ({ item }) => {
        const tipo = item.data.type
        if (tipo === 'project_invitation') {
            return (
                <>
                    <span>{item.data.user_invite} te ha invitado a un proyecto</span>
                    <span className='small'>{item.data.project_name}</span>
                </>
            )
        } else if (tipo === 'project_invitation_supervisor') {
            return (
                <>
                    <span>{item.data.user_invite} te ha invitado a supervisar un proyecto</span>
                    <span className='small'>{item.data.project_name}</span>
                </>
            )
        } else if (tipo === 'project_desvinculation') {
            return (
                <>
                    <span>Solicitan desvinculacion de integrante</span>
                    <span className='small'>{item.data.project_name}</span>
                </>
            )
        } else if (tipo === 'set_responsable') {
            return (
                <>
                    <span>Reasignación de tarea</span>
                    <span className='small'>{item.data.project_name}</span>
                </>
            )
        } else if (tipo === 'deleted_task') {
            return (
                <>
                    <span>{item.data.user_invite} ha eliminado una tarea</span>
                    <span className='small'>{item.data.project_name}</span>
                </>
            )
        } else if (tipo === 'modified_task') {
            return (
                <>
                    <span>{item.data.user} ha modificado una tarea</span>
                    <span className='small'>{item.data.project_name}</span>
                </>
            )
        } else if (tipo === 'project_desvinculated') {
            return (
                <>
                    <span>Te han desvinculado de un proyecto</span>
                    <span className='small'>{item.data.project_name}</span>
                </>

            )
        }
    }

    const Elemento = ({ item, color }) => {
        return (
            <Link to={'/notificaciones/' + item.id} className='text-dark'>
                <NotiContainer key={item.id} color={color} className='d-flex justify-content-between align-items-center'>
                    <div className="d-flex flex-column ps-2">
                        <Mensaje item={item} />
                    </div>
                    <Enviado item={item} />
                </NotiContainer>
            </Link>
        )
    }

    const Renderizar = ({ item }) => {
        let tipo = item.data.type
        if (tipo === 'project_invitation') {
            return (
                <Invitacion item={item} />
            )
        } else if (tipo === 'project_invitation_supervisor') {
            return (
                <InvitacionSupervisor item={item} />
            )
        } else if (tipo === 'project_desvinculation') {
            return (
                <Desvincular item={item} />
            )
        } else if (tipo === 'set_responsable') {
            return (
                <CambioResponsable item={item} />
            )
        } else if (tipo === 'deleted_task') {
            return (
                <Eliminacion item={item} />
            )
        } else if (tipo === 'modified_task') {
            return (
                <Modificacion item={item} />
            )
        } else if (tipo === 'project_desvinculated') {
            return (
                <Desvinculado item={item} />
            )
        }
    }

    return (
        <div className="container negative container-notification p-0">
            <div className="row p-0 m-0" style={{ height: 'calc(100vh - 135px)' }}>
                <div className="col">
                    <div className="row">
                        <div className="border btn-whitesmoke d-flex align-items-center" style={{ height: '10vh' }}>
                            <div className='row align-item-center'>
                                <div className='col w-50'>
                                    <input className='form-control' type="search"
                                        name="filtrar-notificacion" id="" placeholder='Buscar...'
                                        onInput={e => setSearchTerm(e.target.value)} />
                                </div>
                                <div className='col w-50'>
                                    <DaySinglePicker style={{width: "105px"}}
                                    date={date} setDate={setDate}
                                    uniqueDates={uniqueDates}/>
                                </div>
                            </div>
                        </div>
                        <div className="btn-dark overflow-y p-0" style={{ height: '71.7vh' }}>
                            {filtered?.map(item => {
                                let tipo = item.data.type
                                if (tipo === 'project_invitation') {
                                    color = '#3DACD2'
                                    return (
                                        <Elemento item={item} color={color} key={item.id} />
                                    )
                                } else if (tipo === 'project_desvinculation' || tipo === 'project_desvinculated') {
                                    color = '#DC3545'
                                    return (
                                        <Elemento item={item} color={color} key={item.id} />
                                    )
                                } else if (tipo === 'set_responsable') {
                                    color = '#916A82'
                                    return (
                                        <Elemento item={item} color={color} key={item.id} />
                                    )
                                } else if (tipo === 'deleted_task') {
                                    color = '#912E2E'
                                    return (
                                        <Elemento item={item} color={color} key={item.id} />
                                    )
                                } else if (tipo === 'modified_task') {
                                    color = '#FF9901'
                                    return (
                                        <Elemento item={item} color={color} key={item.id} />
                                    )
                                } else if (tipo === 'project_invitation_supervisor') {
                                    color = '#3DACD2'
                                    return (
                                        <Elemento item={item} color={color} key={item.id} />
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
                <div className="col-7 overflow-auto btn-whitesmoke second-row" style={{ height: '81.7vh' }}>
                    <div className="row">

                        {
                            filtered?.map(noti => {
                                if (noti.id == id) {
                                    return (
                                        <Renderizar item={noti} key={noti.id} />
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div >
        </div >
    )
}



export default NotificationView