import React, { useEffect } from 'react'
import { BriefsHeader, BriefsWrapper } from './styles'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useState } from 'react'
import secureLocalStorage from 'react-secure-storage'

function HistorialBriefsList() {

    const { project_url } = useParams()
    const [briefs, setBriefs] = useState()

    useEffect(() => {
        async function getBriefs() {
            const res = await axios.get(`https://kpinza.cl/api/get-briefs/${secureLocalStorage.getItem("user")}/${project_url}/`,
                {
                    headers: {
                        'Authorization': `Token ${secureLocalStorage.getItem('token')}`
                    }
                })
            if (res.status === 200) {
                setBriefs(res.data.briefs)
            }
        }
        getBriefs()
    }, [])

    return (
        <BriefsWrapper className='container-fluid m-auto p-0'>
            <div className="row align-items-center mb-3">
                <div className="col-12 p-0">
                    <p className='h2 m-0'>Historial de Briefs</p>
                    
                </div>
            </div>
            {
                briefs !== undefined && briefs.map((item, index) => {
                    return (
                        <Link to={`/proyectos/${project_url}/historial-brief/${item.id}`}>
                            <BriefsHeader className="row align-items-center">
                                <span>{item.created_at} - <span className='fw-bold'>Generado por: </span> {item.data.creador}</span>
                            </BriefsHeader>
                        </Link>
                    )
                })
            }
        </BriefsWrapper>
    )
}

export default HistorialBriefsList