export const COLORS = {
    btnAddHover: '#008bcc',
    btnAddBase: '#006ccc',

    listBg: '#1c2833',
    listItemBg: '#d4e6f1',
    listHeaderBg: '#eaeded',
    etapaWrapperBg: '#17202a',

    aero: '#87bcde',



    mauveTaupe: '#805e73',
    independence: '#4e4d5c',
    aeroDark: '#80afcd',
    timberWolf: '#e4dfda',

    /* Navbar */
    charcoal: '#2d4654',
    charcoalDark: '#243b4a',

    estados: {
        'Entregado': '#33cc33',
        'NoEntregado': '#ccccb3',
        'EnCurso': '#4dd2ff',
        'Pendiente': '#ff9900',
    },


    dark: "#243B4A",
    medium: "#2D4654",
    light: "#BCD3E0",
    whitesmoke: "#EAEDED",
    purple: '#805E73',
    purpleLight: '#916A82',
    darkLight: '#80afcd',

    darkred: "#912E2E",
    red: "#DC3545",
    yellow: "#FADC46",

}