import React from 'react'
import Proyectos from '../components/ProyectosList/Proyectos'
import Layout from '../hocs/Layout'

function ProyectosList() {
    return (
        <Layout>
            <Proyectos />
        </Layout>
    )
}

export default ProyectosList