import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
function Eliminacion({item}) {
    return (
        <>
            <div className='notification-header'>
                <p className='h3 fw-light mt-3 mb-3'>{item.data.user} Ha Eliminado una Tarea</p>
                <p className='small'><span className='fw-semibold'>Proyecto:</span>  KPINZA</p>
                <p className='small pb-3'><span className='fw-semibold'>Etapa 0:</span> Inicio del desarrollo</p>
            </div>

            <div className="row m-auto align-items-center justify-content-around p-0 m-0 mt-3 modifications-table">
                <div className="col-5 p-0">
                    <table className="table table-hover table-sm align-middle">
                        <tbody className=''>
                            <tr>
                                <td>Nombre de la tarea</td>
                                <td>Inserte nombre de la tarea uy soy largo a ver que tan largo puedo ser hola</td>
                            </tr>
                            <tr>
                                <td>Estado</td>
                                <td>En curso</td>
                            </tr>
                            <tr>
                                <td>Inicio Estimado</td>
                                <td>20/20/20</td>
                            </tr>
                            <tr>
                                <td>Entrega Estimada</td>
                                <td>20/20/20</td>
                            </tr>
                            <tr>
                                <td>Inicio Real</td>
                                <td>20/20/20</td>
                            </tr>
                            <tr>
                                <td>Entrega Real</td>
                                <td>20/20/20</td>
                            </tr>
                            <tr>
                                <td>Tiempo Estimado</td>
                                <td>20/20/20</td>
                            </tr>
                            <tr>
                                <td>Tiempo Real</td>
                                <td>20/20/20</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Eliminacion