import styled from "styled-components";

export const HeaderWrapper = styled.div`
    width: 90%;
`

export const DescContainer = styled.div`
    max-width: 800px;
    background-color: white;
    padding: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 auto;
`

export const EditHeader = styled.div`
    color: white;
    padding: 5px 9px;
    margin-left: 20px;
    border-radius: 10px;
    * {
        font-size: 25px;
    }
`
/* Botoncito para cerrar los modales.
    Porque me molestaba que no tuvieran. - Rodrigo Montalvan */
export const Close = styled.button`
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 0;
    border: none;
    background: none;
    outline: none;
    line-height: 20px;
    right: 10px;
    top: 10px;
    font-size: 24px;
    opacity: .5;
    transition: opacity 200ms cubic-bezier(0.08,0.52,0.52,1);
    
    &:hover {
        opacity: 0.7;
    }
`