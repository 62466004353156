import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
function Modificacion({item}) {
    const itemdata = item.data
    const task = item.data.task

    console.log(item.data.task)

    const caca = (id) => {
        if (id === 1) {
            return "Pendiente"
        } else if (id === 2) {
            return "Entregada"
        } else if (id === 3) {
            return "En curso"
        } else if (id === 4) {
            return "No entregada"
        }
    }

    const data = {
        'tarea_antes': {
            'Nombre de la tarea': task.task_name,
            'Estado': itemdata.tarea_antes ? caca(itemdata.tarea_antes) : caca(task.task_state),
            'Inicio Estimado': itemdata.inicio_antes ? itemdata.inicio_antes : task.feit,
            'Entrega Estimada': itemdata.termino_antes ? itemdata.termino_antes : task.fett,
            'Inicio Real': '20/20/20',
            'Entrega Real': '20/20/20',
            'Tiempo Estimado': itemdata.tiempo_estimado_antes ? itemdata.tiempo_estimado_antes : task.estimated_time,
            'Tiempo Real':  itemdata.tiempo_real_antes ? itemdata.tiempo_real_antes : task.real_time,
            // 'Responsable': itemdata.responsable_antes ? itemdata.responsable_antes : ''
        },
        'tarea_despues': {
            'Nombre de la tarea': task.task_name,
            'Estado': caca(task.task_state),
            'Inicio Estimado': task.feit,
            'Entrega Estimada': task.fett,
            'Inicio Real': '20/20/20',
            'Entrega Real': '20/20/20',
            'Tiempo Estimado': task.estimated_time,
            'Tiempo Real': task.real_time,
            // 'Responsable': itemdata.responsable_despues
        }
    }
    let atributo_cambiado = ''
    let termino_estimado = false
    let inicio_estimado = false
    if (data['tarea_antes']['Nombre de la tarea'] !== data['tarea_despues']['Nombre de la tarea']) {
        atributo_cambiado = 'Nombre de la tarea'
    } 
    if (data['tarea_antes']['Estado'] !== data['tarea_despues']['Estado']) {
        atributo_cambiado = 'Estado'
    } 
    if (data['tarea_antes']['Inicio Estimado'] !== data['tarea_despues']['Inicio Estimado']) {
        inicio_estimado = true
        atributo_cambiado = 'Inicio Estimado'
    }
    if (data['tarea_antes']['Entrega Estimada'] !== data['tarea_despues']['Entrega Estimada']) {
        termino_estimado = true
        atributo_cambiado = 'Entrega Estimada'
    } 
    if (data['tarea_antes']['Inicio Real'] !== data['tarea_despues']['Inicio Real']) {
        atributo_cambiado = 'Inicio Real'
    }
    if (data['tarea_antes']['Entrega Real'] !== data['tarea_despues']['Entrega Real']) {
        atributo_cambiado = 'Entrega Real'
    } 
    if (data['tarea_antes']['Tiempo Estimado'] !== data['tarea_despues']['Tiempo Estimado']) {
        atributo_cambiado = 'Tiempo Estimado'
    }
    if (data['tarea_antes']['Tiempo Real'] !== data['tarea_despues']['Tiempo Real']) {
        atributo_cambiado = 'Tiempo Real'
    }

    const antesList = data.tarea_antes
    const despuesList = data.tarea_despues
    const cambio = atributo_cambiado
    console.log(cambio)


    
    // })

    const antes = '#CCCCB3'
    const despues = '#5F912E'

    Object.entries(antesList).map(([key, value]) => {
        console.log(key)
    })

    return (
        <>
            <div className='notification-header'>
                <p className='h3 fw-light mt-3 mb-3'>{item.data.user} Ha realizado una modificación</p>
                <p className='small'><span className='fw-semibold'>Proyecto: </span>{item.project_name}</p>
                <p className='small pb-3'><span className='fw-semibold'>Modificacion:</span> Entrega Estimada</p>
            </div>

            <div className="row m-auto align-items-center justify-content-around p-0 m-0 mt-3 modifications-table">
                <div className="col-5 p-0">
                    <table className="table table-hover table-sm align-middle">
                        <tbody className=''>
                            {Object.entries(antesList).map(([key, value]) => (
                                <tr>
                                    <td>{key}</td>
                                    {/* <td style={{ backgroundColor: key === atributo_cambiado ? '#CCCCB3' : '' }}>{value}</td> */}
                                    <td style={{ backgroundColor: inicio_estimado ? key == "Inicio Estimado" || key == "Entrega Estimada" ? '#CCCCB3' : '' : key == atributo_cambiado ? '#CCCCB3' : ''}}>{value}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="col-1 p-0">
                    <HiArrowNarrowRight style={{ fontSize: '60px' }} />
                </div>
                <div className="col-5 p-0">
                    <table className="table table-hover table-sm align-middle">
                        <tbody className=''>
                            {Object.entries(despuesList).map(([key, value]) => (
                                <tr>
                                    <td>{key}</td>
                                    <td style={{ backgroundColor: inicio_estimado ? key == "Inicio Estimado" || key == "Entrega Estimada" ? '#5F912E' : '' : key == atributo_cambiado ? '#5F912E' : ''}}>{value}</td>
                                    {/* <td style={{ backgroundColor: key === cambio  ? '#5F912E' : '' }}>{value}</td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Modificacion