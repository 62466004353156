import moment from "moment";
import React, { useState } from "react";
import { SingleDatePicker, isSameDay } from "react-dates-gte-react-17-21.8.0-version-fixed";
import styled from "styled-components"
import { COLORS } from "../../../colors";
/* Selector de fecha individual para
    el filtro de fechas del historial de cambios. - Rodrigo Montalvan */
const StyledDayPickerWrapper = styled.div`
    & .SingleDatePicker,
    .SingleDatePickerInput {

        .DateInput {
            height: 37px;
            display: flex;
        }

        .DateInput_input {
            font-size: 1rem;
            font-weight: 400;
        }
    }

    .SingleDatePickerInput__withBorder {
        border-radius: 5px;
        vertical-align: middle;
        overflow: hidden;
        border: 1px solid #ced4da;

        :hover,
        .DateInput_input__focused {
        /* border: 1px solid gray; */
        }

        .CalendarDay__highlighted_calendar {
        background: ${COLORS.estados.Pendiente};
        border: blueviolet;
        }
    }

    .SingleDatePicker_picker.SingleDatePicker_picker {
        top: 43px;
        left: 2px;
        /* top: 43px !important;
        left: 2px !important; */
    }
}
`;

function DaySinglePicker({ date, setDate, uniqueDates }) {

    const [focused, setFocused] = useState(false)

    const funcChangeDate = (date) => {
        setDate(date)
        return date
    }

    return (
        <StyledDayPickerWrapper>
            <SingleDatePicker
                date={date}
                onDateChange={(date) => funcChangeDate(date)}
                focused={focused}
                onFocusChange={({ focused }) => setFocused(focused)}
                numberOfMonths={1}
                placeholder={"Fecha..."}
                displayFormat={() => "YYYY-MM-DD"}
                isOutsideRange={() => false}
                isDayHighlighted={day1 => uniqueDates !== null && 
                    uniqueDates.some(day2 => 
                        isSameDay(moment(day1), moment(day2)))}
                showClearDate
                block
            >

            </SingleDatePicker>
        </StyledDayPickerWrapper>
    )
}

export default DaySinglePicker