import React from 'react'
import Home from '../components/Inicio/Home'
import Layout from '../hocs/Layout'


function Inicio() {

    return (
        <Layout>
            <Home />
        </Layout>
    )
}

export default Inicio