import { v4 } from "uuid";


export const static_items = [
    {
        id: v4(),
        content: "Inicio del desarrollo",
        index: 1,
        subItems: [
            {
                id: v4(),
                content: "Tarea nueva",
                index: 2,
                estado: 2,
                start_date: '27/09/2022',
                end_date: '30/09/2022'
            },
            {
                id: v4(),
                content: "Tarea 1",
                estado: 1,
                index: 0,
                start_date: '29/09/2022',
                end_date: '30/09/2022'
            },
            {
                id: v4(),
                content: "Tarea 2",
                index: 1,
                estado: 2,
                start_date: '27/09/2022',
                end_date: '30/09/2022'
            },
        ]
    },
    {
        id: v4(),
        index: 0,
        content: "Deployment",
        subItems: [
            {
                id: v4(),
                index: 1,
                content: "Tarea 3",
                estado: 1,
                start_date: '29/09/2022',
                end_date: '30/09/2022'
            },
            {
                id: v4(),
                index: 0,
                content: "Tarea 4",
                estado: 2,
                start_date: '27/09/2022',
                end_date: '30/09/2022'
            }
        ]
    },
];
