import React from 'react'
import EstudiantesList from '../components/Estudiantes/EstudiantesList'
import Layout from '../hocs/Layout'

function Estudiantes() {
    return (
        <Layout>
            <EstudiantesList />
        </Layout>
    )
}

export default Estudiantes