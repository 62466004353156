import styled from "styled-components";

export const StyledDateRangePickerWrapper = styled.div`
  & .DateRangePicker,
  .DateRangePickerInput {

    .DateInput {
      height: 30px;
      
      .DateInput_input {
        font-size: 13px;
        border-bottom: 0;
        text-align: center;
      }
    }
    
    .DateInput__small {
      width: 90px;
    }

    .DateRangePickerInput__withBorder {
      border-radius: 5px;
      overflow: hidden;
      border: none;
      display: flex;
      :hover,
      .DateInput_input__focused {
        /* border: 1px solid gray; */
      }

      /* .CalendarDay__selected {
        background: blue;
        border-radius: 20px;
        border: blueviolet;
      }
      .CalendarDay__selected_span {
        background-color: red;
      } */
    }

    .DateRangePicker_picker.DateRangePicker_picker {
      top: 43px;
      left: 2px;
      /* top: 43px !important;
      left: 2px !important; */
    }
  }
`;

export const StyledDateRangeFormWrapper = styled.div`
  & .DateRangePicker,
  .DateRangePickerInput {

    .DateInput {
      height: 100%;
      
      .DateInput_input {
        font-size: 15px;
        width: 100%;
        border-bottom: 0;
        text-align: center;
        padding: 0;
      }
    }
    
    .DateInput__small {
      width: 100%;
    }

    .DateRangePickerInput_arrow {
        color: white;
    }

    .DateRangePickerInput__withBorder {
      overflow: hidden;
      border: none;
      display: flex;
      :hover,
      .DateInput_input__focused {
        /* border: 1px solid gray; */
      }

      /* .CalendarDay__selected {
        background: blue;
        border-radius: 20px;
        border: blueviolet;
      }
      .CalendarDay__selected_span {
        background-color: red;
      } */
    }

    .DateRangePicker_picker.DateRangePicker_picker {
      top: 43px;
      left: 2px;
      /* top: 43px !important;
      left: 2px !important; */
    }
  }
`
