import { useState, useEffect, useContext } from 'react'
import { HeaderWrapper } from './styles.js'
import { Link } from 'react-router-dom';
import EditProyectModal from '../../../ModalComp/EditProyectInfo.jsx';
import TaskSwitch from './TaskSwitch.jsx';
import { useParams, Redirect, useNavigate } from 'react-router-dom';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { useRef } from 'react';
import moment from 'moment';

function HeaderProyecto({ check, setCheck }) {
    const { project_url } = useParams()
    const navigate = useNavigate();
    const [data, setData] = useState()

    useEffect(() => {
        const getProject = async () => {
            try {
                const res = await axios.get(`https://kpinza.cl/api/tasks/${secureLocalStorage.getItem("user")}/${project_url}/`, {
                    headers: {
                        'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                    }
                })
                if (res.status === 200) {
                    setTasks(res.data)
                }
            } catch (e) {
                return navigate('/inicio')
            }
        }
        const getTasks = async () => {
            try {
                const res = await axios.get(`https://kpinza.cl/api/projects/${secureLocalStorage.getItem("user")}/${project_url}/`, {
                    headers: {
                        'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                    }
                })
                if (res.status === 200) {
                    setProject(res.data)
                }
            } catch (e) {
                return navigate('/inicio')
            }
        }

        getProject()
        getTasks()
    }, [project_url])

    const [project, setProject] = useState({})
    const [tasks, setTasks] = useState([])

      /* Inicio proyecto */
      const inicioEstimadas = tasks.map((item) => {
        return moment(item.feit, 'YYYY/MM/DD').format('DD/MM/YYYY')
    })
    const inicio_ordenadas = inicioEstimadas.sort(function (a, b) {
        a = a.split('/').reverse().join('');
        b = b.split('/').reverse().join('');
        return a > b ? 1 : a < b ? -1 : 0
    })
    
    const inicio_proyecto = inicio_ordenadas[0]
    
    /* Termino proyecto */
    const terminoEstimadas = tasks.map((item) => {
        return moment(item.fett, 'YYYY/MM/DD').format('DD/MM/YYYY')
    })

    const termino_ordenadas = terminoEstimadas.sort(function (a, b) {
        a = a.split('/').reverse().join('');
        b = b.split('/').reverse().join('');
        return a > b ? 1 : a < b ? -1 : 0
    })

    // console.log('inicio',inicio_ordenadas)
    // console.log('termino',termino_ordenadas)

    const termino_proyecto = termino_ordenadas[termino_ordenadas.length - 1]

    const generateBrief = async () => {
        try {
            const res = await axios.get(`https://kpinza.cl/api/generate-brief/${secureLocalStorage.getItem("user")}/${project_url}/`, {
                headers: {
                    'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                }
            })

            const proy = await axios.get(`https://kpinza.cl/api/get-projects-task/${secureLocalStorage.getItem("user")}/`,
                {
                    headers: {
                        'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                    }
                })


            if (res.status === 200) {
                navigate(`/proyectos/${project_url}/brief`, {
                    state: {
                        "data": res.data.data,
                        "projects": proy.data, 
                    }
                });

            }
        } catch (e) {
        }
    }

    return (
        <HeaderWrapper className='container-fluid m-auto p-0'>
            <div className='row align-items-center'>
                <div className="col">
                    <div className="d-flex ">
                        <div className='d-flex align-items-center'>
                            <p className='h2 disableSelect' 
                            role='button'
                            style={{paddingTop: "5px"}}>{project.project_name}</p>
                            <EditProyectModal project={project} setProject={setProject} />
                        </div>
                    </div>
                    <div className='d-flex gap-2 mt-3 align-items-center'>
                        {/* <Link to={`/proyectos/${project_url}/brief`}> */}
                        <button onClick={generateBrief} className='btn btn-purple text-white btn-sm'>Generar Brief</button>
                        {/* </Link> */}
                        <TaskSwitch setCheck={setCheck} check={check} content='Mis tareas' type="lista" />
                    </div>
                </div>
                <div className="col col-lg-3">
                    <table className='table table-sm'>
                        <tbody>
                            <tr>
                                <td>Fecha inicio:</td>
                                <td>{inicio_proyecto}</td>
                            </tr>

                            <tr>
                                <td>Fecha termino:</td>
                                <td>{termino_proyecto}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </HeaderWrapper>
    )

}

export default HeaderProyecto