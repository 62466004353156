import '../styles/index.css'

import Sidebar from '../components/navigation/Sidebar'
import { ToastContainer } from 'react-toastify'

const Layout = (props) => {

    return (
        <>
            <ToastContainer style={{zIndex: '100000000000'}}/>
            <Sidebar content={props.children} />
        </>
    )
}

export default Layout