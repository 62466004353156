import React from 'react'
import { Notifications } from './styles';
import { FaUserCircle } from 'react-icons/fa'
import SupportModal from '../../ModalComp/Support';


function Navbar({ show, setShow }) {
    return (
        <header className={`header ${show ? 'space-toggle' : null}`} style={{ zIndex: '999999' }}>
            <div className='header-toggle p-2' onClick={() => setShow(!show)}>
                <i className={`fas fa-bars`}></i>
            </div>
            <div className="d-flex align-items-center">
                <SupportModal show={show} setShow={setShow}/>
                <Notifications>
                    <FaUserCircle />
                </Notifications>
            </div>
        </header>
    )
}

export default Navbar