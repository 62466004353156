import React, { useState } from 'react'
import styled from 'styled-components';
import { COLORS } from '../colors'
import Popup from 'reactjs-popup';
import { IndicadorModal } from '../components/Estudiantes/styles';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Msg from '../containers/Message';


const BtnInfo = styled.button`
        outline: none;
        border-radius: 10px;
        border-style: none;
        font-size: 13px;
        padding: 7px 20px;
        font-style: italic;
        transition: 0.3s;
        background-color: ${COLORS.dark};
        color: white;

        &:hover {
            background-color: ${COLORS.dark};
            transform: scale(1.03);
            transition: transform .3s;
        }

        &:active {
            transform: scale(.9);
        }
    `

const StyledPopup = styled(Popup)`
        &-overlay {
            background:rgba(0,0,0,.10);
            position: absolute;
            top: 0;
        }
    `
function SupportModal({ show, setShow }) {

    const [open, setOpen] = useState(false)
    const openModal = () => {
        if (show === true) {
            setShow(false)
        }
        setOpen(true)
    }
    const closeModal = () => setOpen(false)

    const sendSupport = async (e) => {
        e.preventDefault()
        try {
            const res = await axios.post(`https://kpinza.cl/api/send-support/`,
                {
                    user_id: secureLocalStorage.getItem("user"),
                    message: e.target.descripcion.value
                },
                {
                    headers: {
                        'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                    }
                })
            if (res.status === 200) {
                toast(<Msg contenido={'Comentario enviado correctamente'}/>, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    type: 'success',
                    theme: 'light',
                })
            }
        } catch (error) {
            toast(<Msg contenido={'Ha ocurrido un error al enviar el comentario.'}/>, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                type: 'warning',
                theme: 'light',
            })
        }
        e.target.descripcion.value = ''
    }

    const Modal = () => (
        <>
            <BtnInfo
                onClick={openModal}>
                ¿Problemas? Contáctanos aquí.
            </BtnInfo>
            <StyledPopup open={open}
                closeOnDocumentClick
                onClose={closeModal}
            >
                <IndicadorModal style={{ minWidth: '500px' }}>
                    <p className="h3">Problemas y/o Sugerencias</p>
                    <p className="fs-6 p-0 m-0 fw-light pb-3 fst-italic text-muted">Tus comentarios nos ayudarán a seguir mejorando.</p>
                    <form className='form' onSubmit={sendSupport}>
                        <div className="mb-3">
                            <textarea className="form-control" placeholder='' id="descripcion" rows="3" name="descripcion-proyecto" maxLength={600}></textarea>
                        </div>
                        <div>
                            <input onSubmit={sendSupport} className='btn btn-success btn-md' type="submit" value='Enviar' />
                        </div>
                    </form>
                </IndicadorModal>
            </StyledPopup>
        </>
    )

    return (
        <>
            <Modal />
        </>
    )
}

export default SupportModal