import React, { useState, useRef, useEffect } from 'react'
import Remedial from './Remedial'
import ProyectChart from '../../Charts/Proyecto/Indicadores';
import MemberIndicadores from '../../Charts/Estudiante/BriefIndicadores';
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import axios from 'axios'
import secureLocalStorage from 'react-secure-storage'
import { useParams, useNavigate } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import HistorialCambios from './HistorialCambios';
import { BriefsWrapper } from './styles';
/* Varios cambios añadidos a los briefs.
    Un grafico grupal con los indicadores.
    Graficos individuales con los indicadores.
    Historial de cambios hechos al proyecto. - Rodrigo Montalvan*/
function BriefInfo(props) {
    const { project_url } = useParams()
    const location = useLocation();
    const data = location.state.data
    const navigate = useNavigate()
    const remedialRef = useRef(null)
    const projects = location.state.projects

    for (let index = 0; index < projects.length; index++) {
        if (project_url == projects[index].project.project_url) {
            var project = projects[index].project
        }
    }

    const [indicadores, setIndicadores] = useState([])
    const [record, setRecord] = useState([])
    const [filtered, setFiltered] = useState([])
    const [uniqueDates, setUniqueDates] = useState([])

    useEffect(() => {
        async function getData() {
            try {
                const res = await axios.get(`https://kpinza.cl/api/get-brief-info/${secureLocalStorage.getItem("user")}/${project.id}/`,
                    {
                        headers: {
                            'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                        }
                    })

                const rec = await axios.get(`https://kpinza.cl/api/records/${project_url}/`,
                    {
                        headers: {
                            "Authorization": "Token " + secureLocalStorage.getItem("token")
                        }
                    })
                setIndicadores(res.data)
                setRecord(rec.data.records)
                setFiltered(rec.data.records)
                setUniqueDates(rec.data.uniqueDates)
            } catch (error) {
            }
        }
        getData()
    }, [])

    const saveBrief = async (e) => {
        e.preventDefault()
        const res = await axios.post(`https://kpinza.cl/api/save-brief/${project_url}/`,
            {
                user_id: secureLocalStorage.getItem("user"),
                data: data,
                remedial: remedialRef.current.state.html
            },
            {
                headers: {
                    'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                }
            })
        if (res.status === 200) {
            navigate(`/proyectos/${project_url}`)
        }
    }

    return (
        <div className='container-fluid'>
            <Tabs forceRenderTabPanel defaultIndex={0}>
                <TabList>
                    <Tab>
                        <p className='display-6 px-3 pt-2'>Estado del Proyecto</p>
                    </Tab>
                    <Tab>
                        <p className='display-6 px-3 pt-2'>Integrantes</p>
                    </Tab>
                    <Tab>
                        <p className='display-6 px-3 pt-2'>Historial</p>
                    </Tab>
                </TabList>
                <TabPanel>
                    <table className='table table-brief table-bordered table-striped table-sm table-hover'>
                        <thead>
                            <tr>
                                <th colSpan={4}>Nombre Etapa</th>
                                <th colSpan={2}>Tareas Etapa</th>
                                <th>Tareas Solicitadas a la Fecha</th>
                                <th>Tareas Realizadas a la Fecha</th>
                                <th>Duracion Estimada Total</th>
                                <th>Duracion Real</th>
                                <th>% Avance Etapa</th>
                                <th>% Atraso</th>
                            </tr>
                        </thead>
                        <tbody>{
                            data.etapas.map((etapa, index) => {
                                return (
                                    <tr>
                                        <td className='text-start p-2' colSpan={4}>Etapa {index + 1}: {etapa.stage_name}</td>
                                        <td colSpan={2}>{etapa.totales}</td>
                                        <td>{etapa.estimadas}</td>
                                        <td>{etapa.entregadas}</td>
                                        <td>{etapa.tiempo_estimado}</td>
                                        <td>{etapa.tiempo_real}</td>
                                        <td>{etapa.avance}</td>
                                        <td>{etapa.atraso}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                        <thead>
                            <tr>
                                <th colSpan={3}>% Avance Proyecto</th>
                                <th colSpan={3}>% Atraso Proyecto</th>
                                <th className='fst-italic' colSpan={1}>Eficacia</th>
                                <th className='fst-italic' colSpan={1}>Eficiencia</th>
                                <th className='fst-italic' colSpan={1}>Efectividad</th>
                                <th colSpan={7}>Remediales</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={3}>{Math.round(data.avance * 100) / 100}</td>
                                <td colSpan={3}>{Math.round(data.atraso * 100) / 100}</td>
                                <td colSpan={1}>{Math.round(data.indicadores.eficacia * 100) / 100}%</td>
                                <td colSpan={1}>{Math.round(data.indicadores.efiencia * 100) / 100}%</td>
                                <td colSpan={1}>{Math.round(data.indicadores.efectividad * 100) / 100}%</td>
                                <td className='text-start p-4' colSpan={7}>
                                    {/* Doble click para añadir remedial (opcional) */}
                                    <Remedial
                                        ref={remedialRef}
                                    // value={texto}
                                    // handleChange={(e) =>
                                    //     setTexto(e.target.value)
                                    // }
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p className='display-6'>Historial de Indicadores del Grupo</p>
                    {/* Grafico Grupal */}
                    <ProyectChart indicadores={indicadores} />
                </TabPanel>
                <TabPanel> {/* Graficos Individuales */}
                    <BriefsWrapper className='row gap-3'>
                        {
                            data.members !== null && data.members.map((item, index) => (
                                <div className='card p-3 col-12' key={index} style={{ width: "30rem" }}>
                                    <div className='card-body'>
                                        <h6>Miembro: {item.member}</h6>
                                        <MemberIndicadores indicadores={indicadores} id={item.id_member} />
                                    </div>
                                </div>
                            ))
                        }
                    </BriefsWrapper>
                </TabPanel>
                <TabPanel> {/* Historial */}
                    <HistorialCambios record={record} filtered={filtered}
                        setFiltered={setFiltered} uniqueDates={uniqueDates}
                        members={data.members} />
                </TabPanel>
            </Tabs>

            <Link to={`/proyectos/${project_url}`}>
                <button onClick={saveBrief} className='btn btn-medium text-white mt-2'>Guardar Brief</button>
            </Link>
        </div>
    )
}

export default BriefInfo