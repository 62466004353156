import React, { useState } from 'react'
import { FaUserTimes } from 'react-icons/fa'
import { InfoProyectoBtn, EditProyecto } from '../../Form/FormStyles'

export const IntegrantesList = ({ solicitarDesvinculo, user }) => {
    const [hoverInt, setHoverInt] = useState(false)

    return (
        <tr className='border-bottom d-block' onMouseEnter={() => setHoverInt(true)} onMouseLeave={() => setHoverInt(false)}>
            <td className='d-flex justify-content-between align-items-center'>
                <span className='p-1'>{user['name']}</span>
                {/* {hoverInt && (<InfoProyectoBtn role='button' className='bg-danger' onClick={solicitarDesvinculo}>
                    <FaUserTimes />
                </InfoProyectoBtn>)} */}
            </td>
        </tr>
    )
}
