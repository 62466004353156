import Layout from '../hocs/Layout'
import Etapas from '../components/List/Etapas'
import HeaderProyecto from '../components/List/HeaderProyecto/HeaderProyecto'
import { useEffect, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'

export const ListView = (props) => {

    const { project_url } = useParams()

    const [usuarios, setUsuarios] = useState([])
    useEffect(() => {
        const getUsers = async () => {
            try {
                const res = await axios.get(`https://kpinza.cl/api/projects/${project_url}/members/`,
                    {
                        headers:
                        {
                            "Authorization": "Token " + secureLocalStorage.getItem("token")
                        }
                    })
                if (res.status === 200) {
                    setUsuarios(res.data.users)
                }
            } catch (error) {
            }
        }
        getUsers()
    }, [project_url])


    const [check, setCheck] = useState(false)
    return (
        <Layout>
            <HeaderProyecto check={check} setCheck={setCheck} />
            <Etapas users={usuarios} check={check} />
        </Layout>
    )
}

export default ListView