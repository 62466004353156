import styled from 'styled-components';
import React from 'react'
import Popup from 'reactjs-popup';

import { FaChartLine } from "react-icons/fa";
import { IndicadorModal } from '../components/Estudiantes/styles';
import { Indicadores } from '../components/ProyectosList/styles';
import EstudianteIndicadores from '../Charts/Estudiante/Indicadores';

const StyledPopup = styled(Popup)`
        &-overlay {
            background:rgba(0,0,0,.10);
            position: absolute;
            top: 0;
        }
    `
function EstInd() {

    const Modal = () => (
        <StyledPopup
            trigger={
                <Indicadores color='#805E73'><FaChartLine /></Indicadores>
            }
            modal
            closeOnDocumentClick
            closeOnEscape
        >
            <IndicadorModal>
                <div className="row mb-2">
                    <span role='button' className='d-flex justify-content-between align-items-baseline'>
                        <p className='h2'>Proyecto 1</p>
                    </span>
                    <EstudianteIndicadores />
                </div>
            </IndicadorModal>
        </StyledPopup>
    )

    return (
        <>
            <Modal />
        </>
    )
}

export default EstInd