import React, { useContext, useState } from 'react'
import styled from 'styled-components';
import { COLORS } from '../colors'
import Popup from 'reactjs-popup';
import FormEtapaTarea from '../components/Form/FormEtapaTarea';
import SideBarContext from '../components/navigation/SideBarContext';

const BtnPlus = styled.button`
        outline: none;
        border-radius: 50%;
        border-style: none;
        height: 4.5rem;
        width: 4.5rem;
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 0 1rem 1rem;
        transition: 0.3s;
        background-color: ${COLORS.medium} ;

        &:hover {
            background-color: ${COLORS.dark};
            transform: scale(1.1);
            transition: transform .3s;
        }

        &:active {
            transform: scale(.9);
        }
    `

const StyledPopup = styled(Popup)`
        &-overlay {
            background:rgba(0,0,0,.10);
            position: absolute;
            top: 0;
        }
    `

function BtnAdd({ addEtapa, stages, addTarea, modalAdd, setModalAdd }) {

    const {show, setShow} = useContext(SideBarContext)
    
    const openModal = () => {
        if (show === true) {
            setShow(false)
        }
        setModalAdd(true)
    }
    const closeModal = () => setModalAdd(false)

    const Modal = ({ addEtapa, stages }) => (
        <>
            <BtnPlus
                onClick={openModal}>
                <i className="fa-solid fa-plus plusIcon" />
            </BtnPlus>
            <StyledPopup
                open={modalAdd}
                closeOnDocumentClick
                onClose={closeModal}
            >
                <FormEtapaTarea addEtapa={addEtapa} 
                stages={stages} 
                addTarea={addTarea}/>
            </StyledPopup>
        </>
    )

    return (
        <>
            <Modal addEtapa={addEtapa} stages={stages} addTarea={addTarea} />
        </>
    )
}

export default BtnAdd