import React from 'react'
import { useState, useEffect } from 'react'
import { DATA } from '../estData'
import LineChart from '../LineChart'
import axios from 'axios'
import secureLocalStorage from 'react-secure-storage'
import moment from 'moment'

function EstudianteIndicadores({ item }) {

    const [indicadores, setIndicadores] = useState([])
    useEffect(() => {
        async function getData() {
            try {
                const res = await axios.get(`https://kpinza.cl/api/get-brief-info/${secureLocalStorage.getItem("user")}/${item.project.id}/`,
                    {
                        headers: {
                            'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                        }
                    })
                setIndicadores(res.data)
            } catch (error) {
            }
        }
        getData()
    }, [])

    const exists = (el) => el.id_member == secureLocalStorage.getItem("user");
    /* Agregue un pequeño detalle, en caso de que
        durante un proyecto agreguen a un nuevo integrante.
        En caso de, los indicadores para briefs guardados no existentes se llenan de 0s,
        para evitar que los siguientes esten disparejos. - Rodrigo Montalvan */
    let ind = []
    indicadores.map((item) => {
        if (item.data.members.some(exists)) {
        } else {
            ind.push({
                "eficacia": 0,
                "eficiencia": 0,
                "efectividad": 0
            })
        }
        item.data.members.map((el) => {
            if (el.id_member === secureLocalStorage.getItem('user')) {
                ind.push(el.indicadores)// ind = el.indicadores
            }
        })
    })

    const datos = {
        labels: indicadores.map((item) => moment(item.created_at, "YYYY-MM-DD").format("DD-MM")),
        datasets: [
            {
                label: 'Eficacia',
                data: ind.map((item) => item.eficacia),
                backgroundColor: '#01D5AC',
                borderColor: '#01D5AC',
                pointRadius: 5,
                lineTension: 0.2,
            },
            {
                label: 'Eficiencia',
                data: ind.map((item) => item.eficiencia),
                backgroundColor: '#2B7EFE',
                borderColor: '#2B7EFE',
                pointRadius: 5,
                lineTension: 0.2,
            },
            {
                label: 'Efectividad',
                data: ind.map((item) => item.efectividad),
                backgroundColor: '#9EE677',
                borderColor: '#9EE677',
                pointRadius: 5,
                lineTension: 0.2,
            }
        ]
    }

    return (
        <LineChart chartData={datos} />
    )
}

export default EstudianteIndicadores