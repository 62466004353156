import React, { useEffect, useState } from "react";
import {
    Arrow, BriefsWrapper,
    DeletedCollapse, DeletedTask, DeliveredCollapse,
    DeliveredTask, EditedCollapse, EditedTask,
    FixedTable,
    RecordHeader,
    InProgressCollapse,
    InProgressTask,
    PendingCollapse,
    PendingTask,
    UndeliveredCollapse,
    UndeliveredTask
} from "./styles";
import { COLORS } from "../../colors";
import chroma, { lab } from "chroma-js";
import Select from "react-select"
import Collapsible from "react-collapsible";
import { HiArrowSmRight } from "react-icons/hi";
import DaySinglePicker from "../List/DatePickers/DayPicker";
import moment from "moment";
import { motion, AnimatePresence } from "framer-motion"
/* Componente que muestra el historial de cambios
    de un proyecto. - Rodrigo Montalvan */
function HistorialCambios({ record, filtered, setFiltered, uniqueDates , members}) {

    const [bgColor, setBgColor] = useState("white")
    const [memberBg, setMemeberBg] = useState("white")
    const [date, setDate] = useState(null)
    const [select, setSelect] = useState(0)
    const [integrante, setIntegrante] = useState(0)
    const [open, setOpen] = useState(false)
    /* 3 filtros para facilitar ubicar datos.
        Por fecha, miembro y estado. */
    useEffect(() => {
        const filter = record?.filter((notifs) => {
            if (date === "" || date === null) {
                return true;
            }
            let fecha = notifs.record.created_at
            return fecha.includes(moment(date).format("YYYY-MM-DD"))
        }).filter((notifs) => {
            if (select !== 0) {
                return notifs.record.data.search_id === select
            }
            return true
        }).filter((notifs) => {
            if (integrante !== 0) {
                return notifs.record.user === integrante
            }
            return true
        })
        setOpen(false)
        setFiltered(filter)
    }, [date, select, integrante])

    function parseTime(time) {
        let start = time.slice(0, 2)
        let end = time.slice(2, 4)
        return (start + ":" + end)
    }

    const miembros = members?.map((member) => {
        return { "value" : member.id_member, "label" : member.member}
    })

    const options = [
        { value: 1, label: 'Pendiente', color: COLORS.estados.Pendiente },
        { value: 2, label: 'Entregado', color: COLORS.estados.Entregado },
        { value: 3, label: 'En Curso', color: COLORS.estados.EnCurso },
        { value: 4, label: 'No Entregado', color: COLORS.estados.NoEntregado },
        { value: 5, label: "Modificado", color: COLORS.yellow },
        { value: 6, label: "Eliminado", color: COLORS.red }
    ];

    const styles = {
        1: { label: 'Pendiente', color: COLORS.estados.Pendiente },
        2: { label: 'Entregado', color: COLORS.estados.Entregado },
        3: { label: 'En Curso', color: COLORS.estados.EnCurso },
        4: { label: 'No Entregado', color: COLORS.estados.NoEntregado },
        5: { label: "Modificado", color: COLORS.yellow },
        6: { label: "Eliminado", color: COLORS.red }
    }

    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: bgColor }),
        singleValue: (styles, { data }) => ({ ...styles, ...setBgColor(data.color) }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : undefined,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                textShadow: "1px  1px  1px  black",

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? data.color
                            : color.alpha(0.3).css()
                        : undefined,
                },
            };
        }
    }

    const memberStyles = {
        control: (styles) => ({ ...styles, backgroundColor: memberBg }),
        singleValue: (styles, { data }) => ({ ...styles, ...setMemeberBg(COLORS.aeroDark) }),
    }

    return (
        <>
            <BriefsWrapper className="container-fluid m-auto p-0">
                <RecordHeader className="row align-items-center">
                    <div className="col">
                        <DaySinglePicker date={date} setDate={setDate}
                            uniqueDates={uniqueDates}/>
                    </div>
                    <div className="col">
                        <Select
                            placeholder={"Miembro..."}
                            className="reactSelect"
                            classNamePrefix="reactSelect"
                            onChange={(e) => {
                                setMemeberBg("white")
                                e !== null
                                    ? setIntegrante(e.value)
                                    : setIntegrante(0)
                            }}
                            isSearchable={true}
                            isClearable={true}
                            options={miembros}
                            styles={memberStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}>

                        </Select>
                    </div>
                    <div className="col">
                        <Select
                            placeholder={"Estado..."}
                            className="reactSelect"
                            classNamePrefix="reactSelect"
                            onChange={(e) => {
                                setBgColor("white")
                                e !== null
                                    ? setSelect(e.value)
                                    : setSelect(0)
                            }}
                            isSearchable={false}
                            isClearable={true}
                            options={options}
                            styles={colourStyles}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                        >
                        </Select>
                    </div>
                </RecordHeader>
            </BriefsWrapper>
            <BriefsWrapper className="container-fluid m-auto p-0">
                <AnimatePresence mode="wait">
                    {filtered !== undefined && filtered.map((item, index) => {
                        if (item.record.data.type == "record_changeState") {
                            if (item.record.data.state_after == 1) {
                                return (
                                    <motion.div layout="position"
                                        key={index}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}>
                                        <Collapsible
                                            transitionTime={200}
                                            triggerElementProps={{ id: "" + index }}
                                            contentElementId={"" + index}
                                            overflowWhenOpen={"visible"}
                                            easing="ease"
                                            open={open}
                                            trigger={
                                                <PendingTask className="row align-items-center">
                                                    <span>{item.record.created_at} - <span className="fw-bold"> Hecho por: </span> {item.user.name} ({item.user.alias})</span>
                                                </PendingTask>
                                            }>
                                            <PendingCollapse className="text-center">
                                                <p className='fw-light h2'>Tarea Pendiente</p>
                                                <span>Tarea {item.record.data.task_data.task_name} de la Etapa {item.record.data.stage}</span>
                                                <p className="fw-bold">Cambios</p>
                                                <FixedTable className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {styles[item.record.data.state_before].label}
                                                            </td>
                                                            <td>
                                                                <Arrow>
                                                                    <HiArrowSmRight />
                                                                </Arrow>
                                                                {styles[item.record.data.state_after].label}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </FixedTable>
                                            </PendingCollapse>
                                        </Collapsible>
                                    </motion.div>
                                )
                            }
                            else if (item.record.data.state_after == 2) {
                                return (
                                    <motion.div layout="position"
                                        key={index}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}>
                                        <Collapsible
                                            transitionTime={200}
                                            triggerElementProps={{ id: "" + index }}
                                            contentElementId={"" + index}
                                            overflowWhenOpen={"visible"}
                                            easing="ease"
                                            open={open}
                                            trigger={
                                                <DeliveredTask className="row align-items-center">
                                                    <span>{item.record.created_at} - <span className="fw-bold"> Hecho por: </span> {item.user.name} ({item.user.alias})</span>
                                                </DeliveredTask>
                                            }>
                                            <DeliveredCollapse className="text-center">
                                                <p className='fw-light h2'>Tarea Entregada</p>
                                                <span>Tarea {item.record.data.task_data.task_name} de la Etapa {item.record.data.stage}</span>
                                                <p className="fw-bold">Cambios</p>
                                                <FixedTable className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {styles[item.record.data.state_before].label}
                                                            </td>
                                                            <td>
                                                                <Arrow>
                                                                    <HiArrowSmRight />
                                                                </Arrow>
                                                                {styles[item.record.data.state_after].label}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </FixedTable>
                                            </DeliveredCollapse>
                                        </Collapsible>
                                    </motion.div>
                                )
                            } else if (item.record.data.state_after == 3) {
                                return (
                                    <motion.div layout="position"
                                        key={index}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}>
                                        <Collapsible
                                            transitionTime={200}
                                            triggerElementProps={{ id: "" + index }}
                                            contentElementId={"" + index}
                                            overflowWhenOpen="visible"
                                            easing="ease"
                                            open={open}
                                            trigger={
                                                <InProgressTask className="row align-items-center">
                                                    <span>{item.record.created_at} - <span className="fw-bold"> Hecho por: </span> {item.user.name} ({item.user.alias})</span>
                                                </InProgressTask>
                                            }>
                                            <InProgressCollapse className="text-center">
                                                <p className='fw-light h2'>Tarea en Curso</p>
                                                <span>Tarea {item.record.data.task_data.task_name} de la Etapa {item.record.data.stage}</span>
                                                <p className="fw-bold">Cambios</p>
                                                <FixedTable className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {styles[item.record.data.state_before].label}
                                                            </td>
                                                            <td>
                                                                <Arrow>
                                                                    <HiArrowSmRight />
                                                                </Arrow>
                                                                {styles[item.record.data.state_after].label}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </FixedTable>
                                            </InProgressCollapse>
                                        </Collapsible>
                                    </motion.div>
                                )
                            } else if (item.record.data.state_after == 4) {
                                return (
                                    <motion.div layout="position"
                                        key={index}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}>
                                        <Collapsible
                                            transitionTime={200}
                                            triggerElementProps={{ id: "" + index }}
                                            contentElementId={"" + index}
                                            overflowWhenOpen={"visible"}
                                            easing="ease"
                                            open={open}
                                            trigger={
                                                <UndeliveredTask className="row align-items-center">
                                                    <span>{item.record.created_at} - <span className="fw-bold"> Hecho por: </span> {item.user.name} ({item.user.alias})</span>
                                                </UndeliveredTask>
                                            }>
                                            <UndeliveredCollapse className="text-center">
                                                <p className='fw-light h2'>Tarea NO Entregada</p>
                                                <span>Tarea {item.record.data.task_data.task_name} de la Etapa {item.record.data.stage}</span>
                                                <p className="fw-bold">Cambios</p>
                                                <FixedTable className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {styles[item.record.data.state_before].label}
                                                            </td>
                                                            <td>
                                                                <Arrow>
                                                                    <HiArrowSmRight />
                                                                </Arrow>
                                                                {styles[item.record.data.state_after].label}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </FixedTable>
                                            </UndeliveredCollapse>
                                        </Collapsible>
                                    </motion.div>
                                )
                            }
                        } else if (item.record.data.type == "record_deleteTask") {
                            return (
                                <motion.div layout="position"
                                    key={index}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    <Collapsible
                                        transitionTime={200}
                                        triggerElementProps={{ id: "" + index }}
                                        contentElementId={"" + index}
                                        overflowWhenOpen="visible"
                                        easing="ease"
                                        open={open}
                                        trigger={
                                            <DeletedTask className="row align-items-center">
                                                <span>{item.record.created_at} - <span className="fw-bold"> Hecho por: </span> {item.user.name} ({item.user.alias})</span>
                                            </DeletedTask>
                                        }
                                    >
                                        <DeletedCollapse className="text-center">
                                            <p className='fw-light h2'>Tarea Eliminada</p>
                                            <span>Tarea {item.record.data.task_data.task_name} de la Etapa {item.record.data.stage}</span>
                                            <p className='fw-bold'>Estado de la Tarea en su Eliminación: {styles[item.record.data.task_data.task_state].label}</p>
                                            <FixedTable className="table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Tiempo Estimado
                                                        </th>
                                                        <th>
                                                            Fecha Inicio Estimada
                                                        </th>
                                                        <th>
                                                            Fecha Término Estimada
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {(item.record.data.task_data.estimated_time === null ||
                                                                item.record.data.task_data.estimated_time === "") ?
                                                                "No se decidió." :
                                                                parseTime(item.record.data.task_data.estimated_time)
                                                            }
                                                        </td>
                                                        <td>
                                                            {item.record.data.task_data.feit === null ?
                                                                "No se decidió." :
                                                                item.record.data.task_data.feit
                                                            }
                                                        </td>
                                                        <td>
                                                            {item.record.data.task_data.fett === null ?
                                                                "No se decidió." :
                                                                item.record.data.task_data.fett
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </FixedTable>
                                            <FixedTable className="table mt-1">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Tiempo Real
                                                        </th>
                                                        <th>
                                                            Fecha Inicio Real
                                                        </th>
                                                        <th>
                                                            Fecha Término Real
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {(item.record.data.task_data.real_time === null
                                                                || item.record.data.task_data.real_time === "") ?
                                                                "No se decidió." :
                                                                parseTime(item.record.data.task_data.real_time)}
                                                        </td>
                                                        <td>
                                                            {item.record.data.task_data.frit === null ?
                                                                "No se decidió." :
                                                                item.record.data.task_data.frit}
                                                        </td>
                                                        <td>
                                                            {item.record.data.task_data.frtt === null ?
                                                                "No se decidió." :
                                                                item.record.data.task_data.frtt}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </FixedTable>
                                        </DeletedCollapse>
                                    </Collapsible>
                                </motion.div>
                            )
                        } else if (item.record.data.type == "record_deleteStage") {
                            return (
                                <motion.div layout="position"
                                    key={index}
                                    animate={{ opacity: 1 }}
                                    initial={{ opacity: 0 }}
                                    exit={{ opacity: 0 }}>
                                    <Collapsible
                                        transitionTime={200}
                                        triggerElementProps={{ id: "" + index }}
                                        contentElementId={"" + index}
                                        overflowWhenOpen="visible"
                                        easing="ease"
                                        open={open}
                                        trigger={
                                            <DeletedTask className="row align-items-center">
                                                <span>{item.record.created_at} - <span className="fw-bold"> Hecho por: </span> {item.user.name} ({item.user.alias})</span>
                                            </DeletedTask>
                                        }
                                    >
                                        <DeletedCollapse className="text-center">
                                            <p className="fw-light h2">Etapa Eliminada</p>
                                            <span>La Etapa {item.record.data.stage} ha sido Eliminada</span>
                                            <p className="fw-bold">Tareas que a su vez fueron eliminadas:</p>
                                            <BriefsWrapper className="container-fluid m-auto p-0">
                                                {(item.record.data.task_data.length === 0)
                                                    ? "Sin tareas Eliminadas"
                                                    : item.record.data.task_data.map((task, index) => {
                                                        return (
                                                            <Collapsible
                                                                transitionTime={200}
                                                                triggerElementProps={{ id: "" + index }}
                                                                contentElementId={"" + index}
                                                                overflowWhenOpen="visible"
                                                                easing="ease"
                                                                open={open}
                                                                trigger={
                                                                    <DeletedTask className="row align-items-center">
                                                                        <span> Tarea {task.task.task_name} -
                                                                            <span className="fw-bold"> Asignada a: </span> {task.user.name} ({task.user.alias}) -
                                                                            <span className="fw-bold"> Estado: <span
                                                                                style={{
                                                                                    fontWeight: 900,
                                                                                    color: styles[task.task.task_state].color,
                                                                                    textShadow: "1px  1px  2px  black",
                                                                                }}>
                                                                                {styles[task.task.task_state].label}
                                                                            </span></span></span>
                                                                    </DeletedTask>
                                                                }
                                                            >
                                                                <div>
                                                                    <FixedTable className="table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    Tiempo Estimado
                                                                                </th>
                                                                                <th>
                                                                                    Fecha Inicio Estimada
                                                                                </th>
                                                                                <th>
                                                                                    Fecha Término Estimada
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    {(task.task.estimated_time === null ||
                                                                                        task.task.estimated_time === "") ?
                                                                                        "No se decidió." :
                                                                                        parseTime(task.task.estimated_time)
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {task.task.feit === null ?
                                                                                        "No se decidió." :
                                                                                        task.task.feit
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {task.task.fett === null ?
                                                                                        "No se decidió." :
                                                                                        task.task.fett
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </FixedTable>
                                                                    <FixedTable className="table mt-1">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    Tiempo Real
                                                                                </th>
                                                                                <th>
                                                                                    Fecha Inicio Real
                                                                                </th>
                                                                                <th>
                                                                                    Fecha Término Real
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    {(task.task.real_time === null
                                                                                        || task.task.real_time === "") ?
                                                                                        "No se decidió." :
                                                                                        parseTime(task.task.real_time)}
                                                                                </td>
                                                                                <td>
                                                                                    {task.task.frit === null ?
                                                                                        "No se decidió." :
                                                                                        task.task.frit}
                                                                                </td>
                                                                                <td>
                                                                                    {task.task.frtt === null ?
                                                                                        "No se decidió." :
                                                                                        task.task.frtt}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </FixedTable>
                                                                </div>
                                                            </Collapsible>
                                                        )
                                                    })
                                                }
                                            </BriefsWrapper>
                                        </DeletedCollapse>
                                    </Collapsible>
                                </motion.div>
                            )
                        } else if (item.record.data.type == "record_startDate") {
                            return (
                                <motion.div layout="position"
                                    key={index}
                                    animate={{ opacity: 1 }}
                                    initial={{ opacity: 0 }}
                                    exit={{ opacity: 0 }}>
                                    <Collapsible
                                        transitionTime={200}
                                        triggerElementProps={{ id: "" + index }}
                                        contentElementId={"" + index}
                                        overflowWhenOpen="visible"
                                        easing="ease"
                                        open={open}
                                        trigger={
                                            <EditedTask className="row align-items-center">
                                                <span>{item.record.created_at} - <span className="fw-bold"> Hecho por: </span> {item.user.name} ({item.user.alias})</span>
                                            </EditedTask>
                                        }
                                    >
                                        <EditedCollapse className="text-center">
                                            <p className="fw-light h2">Tarea Modificada</p>
                                            <span>Tarea {item.record.data.task_data.task_name} de la Etapa {item.record.data.stage}</span>
                                            <p className="fw-bold">Cambio de Fecha Real de Inicio</p>
                                            <FixedTable className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {(item.record.data.startDate_before === null ||
                                                                item.record.data.startDate_before === "") ?
                                                                "No decidido." :
                                                                item.record.data.startDate_before}
                                                        </td>
                                                        <td>
                                                            <Arrow>
                                                                <HiArrowSmRight />
                                                            </Arrow>
                                                            {(item.record.data.startDate_after === null ||
                                                                item.record.data.startDate_after === "") ?
                                                                "No decidido." :
                                                                item.record.data.startDate_after}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </FixedTable>
                                        </EditedCollapse>
                                    </Collapsible>
                                </motion.div>
                            )
                        } else if (item.record.data.type == "record_endDate") {
                            return (
                                <motion.div layout="position"
                                    key={index}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    <Collapsible
                                        transitionTime={200}
                                        triggerElementProps={{ id: "" + index }}
                                        contentElementId={"" + index}
                                        overflowWhenOpen="visible"
                                        easing="ease"
                                        open={open}
                                        trigger={
                                            <EditedTask className="row align-items-center">
                                                <span>{item.record.created_at} - <span className="fw-bold"> Hecho por: </span> {item.user.name} ({item.user.alias})</span>
                                            </EditedTask>
                                        }>
                                        <EditedCollapse className="text-center">
                                            <p className="fw-light h2">Tarea Modificada</p>
                                            <span>Tarea {item.record.data.task_data.task_name} de la Etapa {item.record.data.stage}</span>
                                            <p className="fw-bold">Cambio de Fecha Real de Término</p>
                                            <FixedTable className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {(item.record.data.endDate_before === null ||
                                                                item.record.data.endDate_before === "") ?
                                                                "No decidido." :
                                                                item.record.data.endDate_before}
                                                        </td>
                                                        <td>
                                                            <Arrow>
                                                                <HiArrowSmRight />
                                                            </Arrow>
                                                            {(item.record.data.endDate_after === null ||
                                                                item.record.data.endDate_after === "") ?
                                                                "No decidido." :
                                                                item.record.data.endDate_after}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </FixedTable>
                                        </EditedCollapse>
                                    </Collapsible>
                                </motion.div>
                            )
                        } else if (item.record.data.type == "record_realTime") {
                            return (
                                <motion.div layout="position"
                                    key={index}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    <Collapsible
                                        transitionTime={200}
                                        triggerElementProps={{ id: "" + index }}
                                        contentElementId={"" + index}
                                        overflowWhenOpen="visible"
                                        easing="ease"
                                        open={open}
                                        trigger={
                                            <EditedTask className="row align-items-center">
                                                <span>{item.record.created_at} - <span className="fw-bold"> Hecho por: </span> {item.user.name} ({item.user.alias})</span>
                                            </EditedTask>
                                        }
                                    >
                                        <EditedCollapse className="text-center">
                                            <p className="fw-light h2">Tarea Modificada</p>
                                            <span>Tarea {item.record.data.task_data.task_name} de la Etapa {item.record.data.stage}</span>
                                            <p className="fw-bold">Cambio de Tiempo Real Dedicado</p>
                                            <FixedTable className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {(item.record.data.time_before === null ||
                                                                item.record.data.time_before === "" ?
                                                                "No registrado." :
                                                                parseTime(item.record.data.time_before))}
                                                        </td>
                                                        <td>
                                                            <Arrow>
                                                                <HiArrowSmRight />
                                                            </Arrow>
                                                            {(item.record.data.time_after === null ||
                                                                item.record.data.time_after === "" ?
                                                                "No registrado." :
                                                                parseTime(item.record.data.time_after))}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </FixedTable>
                                        </EditedCollapse>
                                    </Collapsible>
                                </motion.div>
                            )
                        } else if (item.record.data.type == "record_estimatedTime") {
                            return (
                                <motion.div layout="position"
                                    key={index}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    <Collapsible
                                        transitionTime={200}
                                        triggerElementProps={{ id: "" + index }}
                                        contentElementId={"" + index}
                                        overflowWhenOpen="visible"
                                        easing="ease"
                                        open={open}
                                        trigger={
                                            <EditedTask className="row align-items-center">
                                                <span>{item.record.created_at} - <span className="fw-bold"> Hecho por: </span> {item.user.name} ({item.user.alias})</span>
                                            </EditedTask>
                                        }
                                    >
                                        <EditedCollapse className="text-center">
                                            <p className="fw-light h2">Tarea Modificada</p>
                                            <span>Tarea {item.record.data.task_data.task_name} de la Etapa {item.record.data.stage}</span>
                                            <p className="fw-bold">Cambio de Tiempo Estimado</p>
                                            <FixedTable className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {(item.record.data.time_before === null ||
                                                                item.record.data.time_before === "" ?
                                                                "No registrado." :
                                                                parseTime(item.record.data.time_before))}
                                                        </td>
                                                        <td>
                                                            <Arrow>
                                                                <HiArrowSmRight />
                                                            </Arrow>
                                                            {(item.record.data.time_after === null ||
                                                                item.record.data.time_after === "" ?
                                                                "No registrado." :
                                                                parseTime(item.record.data.time_after))}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </FixedTable>
                                        </EditedCollapse>
                                    </Collapsible>
                                </motion.div>
                            )
                        } else if (item.record.data.type == "record_estimatedDates") {
                            return (
                                <motion.div layout="position"
                                    key={index}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    <Collapsible
                                        transitionTime={200}
                                        triggerElementProps={{ id: "" + index }}
                                        contentElementId={"" + index}
                                        overflowWhenOpen="visible"
                                        easing="ease"
                                        open={open}
                                        trigger={
                                            <EditedTask className="row align-items-center">
                                                <span>{item.record.created_at} - <span className="fw-bold"> Hecho por: </span> {item.user.name} ({item.user.alias})</span>
                                            </EditedTask>
                                        }
                                    >
                                        <EditedCollapse className="text-center">
                                            <p className="fw-light h2">Tarea Modificada</p>
                                            <span>Tarea {item.record.data.task_data.task_name} de la Etapa {item.record.data.stage}</span>
                                            <p className="fw-bold">Cambio de Fechas Estimadas</p>
                                            <FixedTable className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Comienzo
                                                        </td>
                                                        <td>
                                                            {(item.record.data.start_before === null ||
                                                                item.record.data.start_before === "" ?
                                                                "No decidido." :
                                                                item.record.data.start_before)}
                                                        </td>
                                                        <td>
                                                            <Arrow>
                                                                <HiArrowSmRight />
                                                            </Arrow>
                                                            {(item.record.data.start_after === null ||
                                                                item.record.data.start_after === "" ?
                                                                "No decidido." :
                                                                item.record.data.start_after)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Término
                                                        </td>
                                                        <td>
                                                            {(item.record.data.end_before === null ||
                                                                item.record.data.end_before === "" ?
                                                                "No decidido." :
                                                                item.record.data.end_before)}
                                                        </td>
                                                        <td>
                                                            <Arrow>
                                                                <HiArrowSmRight />
                                                            </Arrow>
                                                            {(item.record.data.end_after === null ||
                                                                item.record.data.end_after === "" ?
                                                                "No decidido." :
                                                                item.record.data.end_after)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </FixedTable>
                                        </EditedCollapse>
                                    </Collapsible>
                                </motion.div>
                            )
                        } else if (item.record.data.type == "record_responsible") {
                            return (
                                <motion.div layout="position"
                                    key={index}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    <Collapsible
                                        transitionTime={200}
                                        triggerElementProps={{ id: "" + index }}
                                        contentElementId={"" + index}
                                        overflowWhenOpen="visible"
                                        easing="ease"
                                        open={open}
                                        trigger={
                                            <EditedTask className="row align-items-center">
                                                <span>{item.record.created_at} - <span className="fw-bold"> Hecho por: </span> {item.user.name} ({item.user.alias})</span>
                                            </EditedTask>
                                        }
                                    >
                                        <EditedCollapse className="text-center">
                                            <p className="fw-light h2">Tarea Modificada</p>
                                            <span>Tarea {item.record.data.task_data.task_name} de la Etapa {item.record.data.stage}</span>
                                            <p className="fw-bold">Cambio de Responsable</p>
                                            <FixedTable className="table">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {item.record.data.before_name} ({item.record.data.before_alias})
                                                        </td>
                                                        <td>
                                                            <Arrow>
                                                                <HiArrowSmRight />
                                                            </Arrow>
                                                            {item.record.data.after_name} ({item.record.data.after_alias})
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </FixedTable>
                                        </EditedCollapse>
                                    </Collapsible>
                                </motion.div>
                            )
                        } else {
                            return (
                                <motion.div layout="position"
                                    key={index}
                                    animate={{ opacity: 1 }}
                                    initial={{ opacity: 0 }}
                                    exit={{ opacity: 0 }}>
                                    <EditedTask className="row align-items-center">
                                        <span>{item.record.created_at} - <span className="fw-bold"> Hecho por: </span> {item.user.name} ({item.user.alias})</span>
                                    </EditedTask>
                                </motion.div>
                            )
                        }
                    })}
                </AnimatePresence>
            </BriefsWrapper>
        </>
    )
}

export default HistorialCambios