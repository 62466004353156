import React from 'react'
import { useState } from 'react'
import PieChart from '../PieChart'

const options = {
    plugins: {
        legend: {
            display: false,
        },
    },
};

function EstudianteTareas({ item }) {
    let pendientes = 0
    let entregadas = 0
    let en_curso = 0
    let no_entregada = 0

    // proyecto.map((item) => {
    item.tasks.map((task) => {
        const estado = task.task_state_id
        if (estado == 1) {
            return pendientes += 1
        }
        if (estado == 2) {
            return entregadas += 1
        }
        if (estado == 3) {
            return en_curso += 1
        }
        if (estado == 4) {
            return no_entregada += 1
        }
    })
    // })

    const estados_array = [pendientes, entregadas, en_curso, no_entregada]
    const DATA_TAREAS = {
        labels: ['Pendientes', 'Entregadas', 'En curso', 'No entregadas'],
        datasets: [
            {
                label: 'mis_tareas',
                data: estados_array,
                // data: [0, 0, 0, 0],
                backgroundColor: [
                    'rgba(255, 153, 1, 0.7)',
                    'rgba(95, 145, 46, 0.7)',
                    'rgba(61, 172, 210, 0.7)',
                    'rgba(204, 204, 179, 0.7)',
                ],
                borderColor: [
                    'rgba(255, 153, 1, 1)',
                    'rgba(95, 145, 46, 1)',
                    'rgba(61, 172, 210, 1)',
                    'rgba(204, 204, 179, 1)',
                ],
                borderWidth: 1,
            },
        ],
        options: {
            plugins: {
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false
                }
            }
        }
    };

    const datos = DATA_TAREAS.datasets[0].data
    let condicion = datos.every(item => item === 0)

    return (
        <PieChart chartData={DATA_TAREAS} options={options} />
    )
}

export default EstudianteTareas