import styled from "styled-components";
import { COLORS } from "../../colors";

export const ListWrapper = styled.div`
`


export const ListHeader = styled.div`
    background-color: ${COLORS.dark};
    color: white;
    padding: 10px;
    align-items: center;
    `
export const ListContainer = styled.div`
    border-radius: 10px;
    width: 90%;
    margin: 0 auto;
    background-color: ${COLORS.dark};
`

export const ListItemsStyle = styled.div`
    background-color: ${COLORS.light};
    font-size: 15px;
    padding: 0px 1em;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

`

export const EtapaWrapper = styled.div`

`

export const EtapaIcon = styled.span`
    transform: rotate(${props => props.isActive ? -180 : 0}deg);
    transition: all 0.2s;
`

export const HeaderList = styled.div`
    display: grid;
    grid-template-columns: repeat(8,1fr);
`

export const EtapaStyle = styled.div`
    background-color: ${COLORS.charcoal};
    color: white;
    display: flex;
    justify-content: space-between;
    border-top:2px solid ${COLORS.charcoalDark};
    align-items: center;
    i {
        font-size: 20px;
    }
    span {
        padding: 5px;
        padding-right: 15px;
    }
    .drag:hover {
        transform: scale(1.3);
        transition-duration: .3s;
    }
`

export const Options = styled.option`
    background-color: ${props => props.color};
`

export const DeleteButton = styled.div`
    position: absolute;
    right: 10px;
    padding: 20px;
    @media (max-width: 1015px) {
        right: 5px;
        padding: 15px;
    }
    @media (min-width: 1400px) {
        right: 20px;
    }
    @media (min-width: 1600px) {
        right: 30px;
    }
    @media (min-width: 1650px) {
        right: 30px;
    }
    @media (min-width: 1750px) {
        right: 40px;
    }
    @media (min-width: 2000px) {
        right: 50px;
    }
    `

export const DeleteBtnWrapper = styled.div`
    background-color: ${COLORS.red};
    border-radius: 15px;
    padding: 6px 9px;
    `

export const Option = styled.div`
background-color: ${props => props.estado};
border-radius: 5px;
padding: 5px;
color: ${COLORS.dark};
border: 1px solid ${COLORS.dark};
transition: all ease .5s;
font-size: 13px;
`

export const BtnStages = styled.span`
    border-radius: 10px;
    background-color: ${props => props.color};
    padding: 2px 4px;
    display: inline-block;
    text-align: center;
    justify-content: center;
    margin: 0 3px;
    cursor: pointer;
    transition: .2s;
    * {
        margin-left: 8px;
        width: 18px;
    }
    :hover {
        background-color: ${props => props.hover};
    }
`

export const ModWrapper = styled.span`
padding: 0;
margin: 0;
margin-left: 30px;
`