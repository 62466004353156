import React, { useContext, useState, useEffect } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import EstudianteTareas from '../../Charts/Estudiante/Tareas';
import EstudianteIndicadores from '../../Charts/Estudiante/Indicadores';
import secureLocalStorage from 'react-secure-storage'
import axios from 'axios'
import SupportModal from '../../ModalComp/Support';
import EditUserModal from '../../ModalComp/EditUser';
ChartJS.register(ArcElement, Tooltip);

function timeOfDay() {
    let hour = new Date().getHours();
    if (hour >= 5 && hour <= 11) return 'Días';
    if (hour >= 12 && hour <= 19) return 'Tardes';
    if (hour >= 20 || hour <= 4) return 'Noches';

}

function letra() {
    let hour = new Date().getHours();
    let letra;
    if (hour >= 5 && hour <= 11) {
        letra = 'o'
        return letra
    }
    if (hour >= 12 && hour <= 19) {
        letra = 'a'
        return letra
    };
    if (hour >= 20 || hour <= 4) {
        letra = 'a'
        return letra
    };

}
/* Se agrega la opcion de editar el usuario (nombre y alias) - Rodrigo Montalvan */

function Home() {
    const [projects, setProjects] = useState(null)
    const [name, setName] = useState(secureLocalStorage.getItem('name'))
    const [alias, setAlias] = useState(secureLocalStorage.getItem("alias"))
    const isSupervisor = localStorage.getItem('supervisor')

    const getProjects = async () => {
        try {
            const res = await axios.get(`https://kpinza.cl/api/get-projects-task/${secureLocalStorage.getItem("user")}/`,
                {
                    headers: {
                        'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                    }
                })
            if (res.status === 200) {
                setProjects(res.data)
            }
        } catch (e) {
            setProjects(null)
        }
    }

    useEffect(() => {
        getProjects()
    }, [])

    return (
        <>
            <div className="container-fluid">
                <div className="col">
                    <div className='d-flex align-items-center align-middle'>
                        <p className='fw-light h2'
                        style={{paddingTop: "5px"}}>Buen{letra()}s {timeOfDay()}, {name}</p>
                        <EditUserModal name={name} setName={setName} alias={alias} setAlias={setAlias} />
                    </div>
                </div>
                {
                    isSupervisor === 'true' ? (
                        <div className='row gap-3'>
                            sdasd
                        </div>
                    ) : ''
                }
                {
                    isSupervisor === 'false' && (

                        <div className="row gap-3">
                            {
                                projects !== null && projects.map((item, index) => (
                                    // <div className='card p-3 col-12' key={index} style={{ width: '15rem' }}>
                                    <div className='card p-3 col-12' key={index} style={{ width: '50rem' }}>
                                        <p className='h5 fw-normal'>{item.project.project_name}</p>
                                        <div className='d-flex gap-3'>
                                            <div className="card" style={{ width: '15rem' }}>
                                                <div className="card-body">
                                                    <h6 className="card-subtitle mb-2 text-muted">Mis tareas</h6>
                                                    {/* <EstudianteTareas item={item} /> */}
                                                    {item.tasks.length == 0 ? (
                                                        <p className='fst-italic'>Comienza a tener tareas para ver el gráfico</p>
                                                    ) : <EstudianteTareas item={item} />}
                                                    {/* item.tasks.length == 0 */}
                                                </div>
                                            </div>
                                            <div className="card" style={{ width: '30rem' }}>
                                                <div className="card-body">
                                                    <h6 className="card-subtitle mb-2 text-muted">Mis indicadores</h6>
                                                    <EstudianteIndicadores item={item} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
            </div >
        </>
    )
}

export default Home