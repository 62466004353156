import React, { useState, useRef, useEffect } from 'react'
import { InfoProyectoBtn, EditProyecto } from './FormStyles'
import { RiDeleteBin6Fill } from 'react-icons/ri'
import Swal from 'sweetalert2'
import { DeleteBtnWrapper, DeleteButton } from '../List/styles'
import { FaUserTimes } from 'react-icons/fa'
import { MdEdit, MdEmail } from 'react-icons/md'
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import { IntegrantesList } from '../List/HeaderProyecto/IntegrantesList'
import { GoPlus } from 'react-icons/go'


import { useParams, useNavigate } from 'react-router-dom'

import secureLocalStorage from "react-secure-storage";
import { Close } from '../List/HeaderProyecto/styles'
import { GrClose } from 'react-icons/gr'
import Msg from '../../containers/Message'

function FormEditProyecto({ project, setProject, closeModal }) {
    const userEmail = useRef()
    const navigate = useNavigate();

    const [hoverInt, setHoverInt] = useState(false)
    const [hoverSuper, setHoverSuper] = useState(false)
    const [hoverName, setHoverName] = useState(false)
    const [hoverDesc, setHoverDesc] = useState(false)
    const [members, setMembers] = useState([])
    const [supervisor, setSupervisor] = useState()
    const { project_url } = useParams()



    useEffect(() => {
        const getProjects = async () => {
            try {
                const res = await axios.get(`https://kpinza.cl/api/projects/${project_url}/members/`,
                    {
                        headers: {
                            "Authorization": "Token " + secureLocalStorage.getItem("token")
                        }
                    })
                if (res.status === 200) {
                    setMembers(res.data.users)
                    setSupervisor(res.data.supervisor)
                }
            } catch (error) {
            }
        }
        getProjects()
    }, [project_url])

    const solicitarDesvinculo = () => {
        Swal.fire({
            title: '¿Solicitar desvinculación?',
            text: "Solicitar desvincular al integrante del proyecto.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, solicito desvinculacion',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire(
                    'Solicitud enviada',
                    'Llegará una notificación cuando el supervisor haya tomado la desición.',
                    'success'
                )
            }
        })
    }

    const inviteProject = async (e) => {
        e.preventDefault()
        if (userEmail.current.value.toString() === '') {
            toast(<Msg contenido={'El campo no puede estar vacío.'}/>, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                type: 'warning',
                theme: "light",
            });
        } else {

            try {
                const res = await axios.post(
                    `https://kpinza.cl/api/invite-project/${project_url}/`,
                    {
                        user_id: secureLocalStorage.getItem("user_id"),
                        name: secureLocalStorage.getItem("name"),
                        alias: secureLocalStorage.getItem("alias"),
                        user_email: userEmail.current.value.toString()
                    },
                    {
                        headers: {
                            Authorization: 'Token ' + secureLocalStorage.getItem('token')
                        }
                    }
                )
                if (res.status === 200) {
                    userEmail.current.value = ''
                    // MENSAJE
                    toast(<Msg contenido={'Se ha enviado la invitación.'}/>, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        type: 'success',
                        theme: "light",
                    });
                }
            } catch (error) {
                if (error.response.status === 404) {
                    toast(<Msg contenido={'Cuenta no existe o no se ha encontrado.'}/>, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        type: 'warning',
                        theme: "light",
                    });
                }
                if (error.response.status === 409) {
                    toast(<Msg contenido={"El usuario ya ha sido invitado."}/>, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        type: "warning",
                        theme: "light",
                    })
                }
            }
        }
    }

    const borrarProyecto = () => {
        Swal.fire({
            title: '¿Desea eliminar el proyecto?',
            text: "Se perderá todo el proyecto y sus tareas.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await axios.delete(
                    `https://kpinza.cl/api/delete-project/${secureLocalStorage.getItem("user")}/${project_url}/`,
                    {
                        headers: {
                            Authorization: 'Token ' + secureLocalStorage.getItem('token')
                        }
                    }
                )
                if (res.status === 200) {
                    Swal.fire(
                        'Proyecto eliminado',
                        'El proyecto ha sido eliminado permanentemente.',
                        'success'
                    )
                    navigate('/inicio')
                }
            }
        })
    }


    const editarNombreProyecto = () => {
        Swal.fire({
            title: 'Cambiar nombre del proyecto',
            input: 'text',
            inputValue: project.project_name,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Guardar',
            inputValidator: async (value) => {
                if (!value) {
                    return '¡No puede estar vacío!'
                } else {
                    const res = await axios.put(`https://kpinza.cl/api/edit-project/${project_url}/`,
                        { 
                            user_id: secureLocalStorage.getItem("user"),
                            project_name: value 
                        },
                        { 
                            headers: { Authorization: 'Token ' + secureLocalStorage.getItem('token') } 
                        })
                    if (res.status === 202) {
                        setProject({ ...project, project_name: value })
                    }
                }
            }
        })
    }
    const invitarSupervisor = () => {
        Swal.fire({
            title: 'Añadir Supervisor',
            input: 'text',
            inputValue: '',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Invitar',
            inputPlaceholder: "Correo electrónico",
            inputValidator: async (value) => {
                if (!value) {
                    return '!No puede estar vacío¡'
                } else {
                    try {
                        const res = await axios.post(
                            `https://kpinza.cl/api/invite-supervisor/${secureLocalStorage.getItem("user")}/${project_url}/`,
                            {
                                user_email: value
                            },
                            {
                                headers: {
                                    Authorization: 'Token ' + secureLocalStorage.getItem('token')
                                }
                            }

                        )
                        if (res.status == 200) {
                            toast(<Msg contenido={'Se ha enviado la invitación.'}/>, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                type: 'success',
                                theme: "light",
                            });
                        }
                    } catch (err) {
                    }
                }
            }
        })
    }

    const editarDescripcionProyecto = () => {
        Swal.fire({
            title: 'Cambiar descripcion del proyecto',
            input: 'text',
            inputValue: project.project_description,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Guardar',
            inputValidator: async (value) => {
                if (!value) {
                    return '¡No puede estar vacío!'
                } else {
                    const res = await axios.put(`https://kpinza.cl/api/edit-project/${project_url}/`,
                        { 
                            user_id : secureLocalStorage.getItem("user"),
                            project_description: value
                         },
                        { headers: { Authorization: 'Token ' + secureLocalStorage.getItem('token') } })
                    if (res.status === 202) {
                        setProject({ ...project, project_description: value })

                    }
                }
            }
        })
    }

    return (
        <EditProyecto className='p-3'>
            <Close onClick={closeModal}>
                <GrClose />
            </Close>
            <Tabs>
                <TabList style={{ border: 'none' }}>
                    <Tab>Informacion General</Tab>
                    <Tab>Integrantes</Tab>
                    {/* <Tab>Configuracion</Tab> */}
                </TabList>

                <TabPanel>
                    <div className="container mt-4">
                        <table className='table p-3 table-bordered'>
                            <tbody>
                                <tr >
                                    <td className='table-active' style={{ verticalAlign: "middle" }}>
                                        Nombre Proyecto
                                    </td>
                                    <td className='d-flex justify-content-between align-items-center' onMouseEnter={() => setHoverName(true)} onMouseLeave={() => setHoverName(false)}>
                                        <span className='p-1'>{project.project_name}</span>
                                        {hoverName && (
                                            <InfoProyectoBtn className='btn-medium' role='button' onClick={editarNombreProyecto}>
                                                <MdEdit />
                                            </InfoProyectoBtn>
                                        )}
                                    </td>
                                </tr>
                                <tr style={{ display: "none" }}>
                                    <td className='table-active'>Supervisor</td>
                                    <td className='d-flex justify-content-between align-items-center' onMouseEnter={() => setHoverSuper(true)} onMouseLeave={() => setHoverSuper(false)}>

                                        {supervisor !== undefined && supervisor !== null ? supervisor.name : <span className='p-1 pe-5 me-5'>No existe supervisor</span>}
                                        {hoverSuper && supervisor === null && (
                                            <InfoProyectoBtn className='btn-medium bg-success' role='button' onClick={invitarSupervisor}>
                                                <GoPlus />
                                            </InfoProyectoBtn>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='d-flex align-items-center' onMouseEnter={() => setHoverDesc(true)} onMouseLeave={() => setHoverDesc(false)}>
                            <p className="h5 p-2 ps-0">Descripción del proyecto</p>
                            {hoverDesc && (
                                <InfoProyectoBtn className='btn-medium' role='button' onClick={editarDescripcionProyecto}>
                                    <MdEdit />
                                </InfoProyectoBtn>
                            )}
                        </div>

                        <p>{project.project_description}</p>
                        <div className="container mt-4 p-0 d-flex justify-content-between">
                            <Link to={`/proyectos/${project_url}/historial-briefs`}>
                                <button className='btn btn-purple text-white'>Historial de Briefs</button>
                            </Link>
                            {/* {
                                supervisor !== undefined && supervisor !== null && secureLocalStorage.getItem('user') === supervisor.id && (
                                    <div>
                                        <button className='btn btn-danger' onClick={() => borrarProyecto()}>Eliminar proyecto</button>
                                    </div>
                                )
                            } */}
                            {
                                members.map((user) => {
                                    if (user.id == secureLocalStorage.getItem('user') && user.owner === true) {
                                        return (
                                            <div>
                                                <button className='btn btn-danger' onClick={() => borrarProyecto()}>Eliminar proyecto</button>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="container mt-4">
                        <table className="table table-sm p-3">

                            <tbody>
                                <div className='stripped pb-2' style={{ maxHeight: '300px', overflow: 'auto' }}>
                                    {
                                        members.map((item, index) => (
                                            <IntegrantesList solicitarDesvinculo={solicitarDesvinculo} user={item} key={index} />
                                        ))
                                    }
                                </div>
                                <tr>
                                    <td>
                                        <form onSubmit={inviteProject}>
                                            <div className="input-group d-flex">
                                                <input type="email form-control"
                                                    placeholder='Correo Integrante a Agregar'
                                                    ref={userEmail} style={{ flexGrow: '1' }} />
                                                <input type="submit" className='input-group-text btn btn-success' value="Añadir" />
                                            </div>
                                        </form>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </TabPanel>
                {/* <TabPanel>
                    <div className="container mt-4">
                        <button className='btn btn-danger' onClick={() => borrarProyecto()}>Eliminar proyecto</button>
                    </div>
                </TabPanel> */}
            </Tabs>
        </EditProyecto >
    )
}

export default FormEditProyecto