import { Droppable, Draggable } from "react-beautiful-dnd";
import { ListWrapper, ListItemsStyle, DeleteButton } from './styles';
import { __ } from 'lodash';
import 'react-dates-gte-react-17-21.8.0-version-fixed/initialize';
// import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates-gte-react-17-21.8.0-version-fixed';
import 'react-dates-gte-react-17-21.8.0-version-fixed/lib/css/_datepicker.css';
import Inputs from './Inputs';
import { useState } from 'react'
import React from "react";

import { RiDeleteBin6Fill } from 'react-icons/ri'
import DeleteTooltip from "../../ModalComp/EditProyectInfo";
import secureLocalStorage from "react-secure-storage";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

// const grid = 8;
function ListItems(props) {
    return (
        <Droppable droppableId={props.type} type={`droppableSubItem`}>
            {(provided, snapshot) => (
                <ListWrapper ref={provided.innerRef}>
                    {
                        props.tasks !== undefined && props.tasks.map((item, index) => {
                            if (props.check !== true) {
                                if (item.assigned_user_id === secureLocalStorage.getItem('user')) {
                                    return (
                                        <Draggable key={item.id} draggableId={item.task_name + item.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div>
                                                    <ListItemsStyle ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        key={item.id}
                                                        id={item.id}
                                                    >
                                                        <Inputs 
                                                        users={props.users} 
                                                        stages={props.stages} 
                                                        setStages={props.setStages} 
                                                        item={item} 
                                                        stageId={props.type}/>
                                                    </ListItemsStyle>
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                }
                            } else {
                                return (
                                    <Draggable key={item.id} draggableId={item.task_name + item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div>
                                                <ListItemsStyle ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    key={item.id}
                                                    id={item.id}
                                                >
                                                    <Inputs users={props.users} 
                                                    stages={props.stages} 
                                                    setStages={props.setStages} 
                                                    item={item} 
                                                    stageId={props.type} />
                                                </ListItemsStyle>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            }
                        })
                    }
                    {provided.placeholder}
                </ListWrapper >
            )}
        </Droppable >
    )
}

export default ListItems