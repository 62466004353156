import React from 'react'
import { useParams } from 'react-router-dom'
import NotificationView from '../components/Notifications/Notifications'
import Layout from '../hocs/Layout'

function Notifications() {

    return (
        <Layout>
            <NotificationView />
        </Layout>
    )
}

export default Notifications