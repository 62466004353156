import { useState } from 'react'
import 'react-dates-gte-react-17-21.8.0-version-fixed/initialize';
import { SingleDatePicker } from 'react-dates-gte-react-17-21.8.0-version-fixed';
import styled from "styled-components";

import 'react-dates-gte-react-17-21.8.0-version-fixed/lib/css/_datepicker.css';
import moment from 'moment'

const StyledDatePickerWrapper = styled.div`
  & .SingleDatePicker,
  .SingleDatePickerInput {
      .DateInput {
      width: 100%;
      height: 30px;
      display: flex;
      
      .DateInput_input {
        font-size: 13px;
        border-bottom: 0;
        text-align: center;
      }
    }

    .SingleDatePickerInput__withBorder {
      border-radius: 5px;
      vertical-align: middle;
      overflow: hidden;
      border: none;

      :hover,
      .DateInput_input__focused {
        /* border: 1px solid gray; */
      }

      .CalendarDay__selected {
        background: blue;
        border: blueviolet;
      }
    }

    .SingleDatePicker_picker.SingleDatePicker_picker {
      top: 43px;
      left: 2px;
      /* top: 43px !important;
      left: 2px !important; */
    }
    
}
`;

function DateSinglePicker({ fecha, placeholder, id, changeDate, disabled }) {
  const [date, setDate] = useState(fecha ? moment(fecha, 'YYYY-MM-DD') : null);
  const [focused, setFocused] = useState();

  const fnChangeDate = (date) => {
    changeDate(date)
    setDate(date)
    return date
  }

  return (
    <StyledDatePickerWrapper>
      {disabled ? 
      <SingleDatePicker
      disabled={true}
      date={date}
      onDateChange={(date) => fnChangeDate(date)}
      focused={focused}
      onFocusChange={({ focused }) => setFocused(focused)}
      id={id}
      numberOfMonths={1}
      small={true}
      placeholder={placeholder}
      displayFormat={() => "YYYY-MM-DD"}
    /> :
    <SingleDatePicker
        date={date}
        onDateChange={(date) => fnChangeDate(date)}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        id={id}
        numberOfMonths={1}
        small={true}
        placeholder={placeholder}
        displayFormat={() => "YYYY-MM-DD"}
      />}
    </StyledDatePickerWrapper>
  );
}

export default DateSinglePicker