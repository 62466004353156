import { useState, useEffect } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { DropdownLink, SidebarLabel, DropdownLinkMobile, LinkMobileWrapper } from './styles.js'
import { FiChevronRight, FiChevronDown, FiBox } from "react-icons/fi";
import { IoPerson, IoNotifications } from "react-icons/io5"
import axios from "axios";
import { useMediaQuery } from "react-responsive";

import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { IndicadorModal } from "../Estudiantes/styles";
import Navbar from "./Navbar";
import secureLocalStorage from "react-secure-storage";
import SideBarContext from "./SideBarContext.jsx";

const StyledPopup = styled(Popup)`
        &-overlay {
            background:rgba(0,0,0,.10);
            position: absolute;
            top: 0;
        }
    `

const Dot = styled.div`
      position: absolute;
      background-color: red;
      width: 10px;
      height: 10px;
      top: 10px;
      left: 33px;
      z-index: 1;
      border-radius: 5px;  
`

function CreateProjectModal({ createProject }) {

    const Modal = ({ createProject }) => (
        <StyledPopup
            trigger={<button className="btn btn-success btn-sm">
                Nuevo proyecto
            </button>}
            modal
            closeOnDocumentClick
        >
            <IndicadorModal style={{ minWidth: '500px' }}>
                <p className="h2 p-2">Nuevo proyecto</p>
                <form className='form' onSubmit={createProject}>
                    <input type="text" className='form-control mb-3 mt-2' placeholder='Nombre del proyecto' name="nombre-proyecto" maxLength={50} />
                    <div className="mb-3">
                        <textarea className="form-control" placeholder='Descripcion del proyecto' id="descripcion" rows="3" name="descripcion-proyecto" maxLength={150}></textarea>
                    </div>
                    <div>
                        <input className='btn btn-success btn-md' type="submit" value='Crear' />
                    </div>
                </form>
            </IndicadorModal>
        </StyledPopup >
    )

    return (
        <>
            <Modal createProject={createProject} />
        </>
    )
}



function Sidebar(props) {

    const cutString = (string) => {
        if (string.length > 10) {
            return string.substring(0, 12) + '...';
        }
        return string
    }

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 760px)' })

    const [show, setShow] = useState(false);
    const [dataProyectos, setProyectos] = useState([])
    const [subnav, setSubnav] = useState(false)

    // console.log(secureLocalStorage.getItem('supervisor'))
    // const rol = false

    const [rol, setRol] = useState(false)
    const [isSupervisor, setIsSupervisor] = useState()

    let navigate = useNavigate()

    useEffect(() => {
        if (localStorage.getItem('supervisor') == 'false') {
            setRol(false)
        } else if (localStorage.getItem('supervisor') == 'true') {
            setRol(true)
        } else {
            setRol(false)
        }

        const projects = async () => {
            try {
                const res = await axios.get(`https://kpinza.cl/api/projects/${secureLocalStorage.getItem("user")}/`,
                    {
                        headers: {
                            "Authorization": "Token " + secureLocalStorage.getItem("token"),
                            "supervisor": localStorage.getItem("supervisor")
                        }
                    })
                if (res.status === 200) {
                    setProyectos(res.data)
                }
            } catch (error) {
            }
        }
        
        projects()
    }, [])

    const showSubnav = () => {
        if ((rol === false) && !isTabletOrMobile) {
            setShow(true)
            setSubnav(!subnav)
        } else if (rol === true) {
            navigate('/listado-proyectos')
        } else {
            return
        }
    }

    async function createProject(e) {
        e.preventDefault()
        try {
            const res = await axios.post(
                'https://kpinza.cl/api/create-project/',
                {
                    user_id: secureLocalStorage.getItem("user"),
                    project_name: e.target["nombre-proyecto"].value,
                    project_description: e.target["descripcion-proyecto"].value
                },
                {
                    headers: {
                        Authorization: 'Token ' + secureLocalStorage.getItem('token')
                    }
                }
            )
            if (res.status === 200) {
                const newProject = {
                    id: res.data.data.id,
                    project_name: res.data.data.project_name,
                    project_description: res.data.data.project_description,
                    project_url: res.data.data.project_url,
                    created_at: res.data.data.created_at,
                    is_active: res.data.data.is_active
                }
                if (dataProyectos.length === undefined) {
                    setProyectos([newProject])
                } else {
                    setProyectos((prevProjects) => {
                        setProyectos([newProject, ...prevProjects])
                        return [newProject, ...prevProjects]
                    })
                }
            }
        } catch (error) {
        }

    }

    const MenuTablet = () => (
        <Popup
            trigger={
                <div className='nav-link d-flex' role='button'>
                    <i className='fa-sharp fa-solid fa-briefcase nav-link-icon'></i>
                    <span className='nav-link-name'>Proyectos</span>
                </div>
            }
            position="right top"
            on="click"
            closeOnDocumentClick
            contentStyle={{ padding: '0px', border: 'none' }}
            arrow={false}
        >
            <LinkMobileWrapper>
                {dataProyectos.length > 0 && dataProyectos.map(item => (
                    <DropdownLinkMobile className="isMobile" to={'/proyectos/' + item.project_url} key={item.id}>
                        <SidebarLabel>{item.project_name}</SidebarLabel>
                    </DropdownLinkMobile>
                ))}
            </LinkMobileWrapper>
        </Popup>
    )

    const logout = () => {
        localStorage.clear()
        navigate('iniciar-sesion')
    }

    return (
        <main className={show ? 'space-toggle' : null}>
            <Navbar show={show} setShow={setShow} />

            <aside className={`sidebar ${show ? 'show' : null}`}>
                <nav className='nav'>
                    <div>
                        <span to='/' className='nav-logo'>
                            <span>
                                <svg viewBox="0 0 414.43 406.66" style={{ width: '30px' }}>
                                    <g id="Grupo_1" data-name="Grupo 1" transform="translate(-867.584 -468.34)">
                                        <path id="Trazado_7" data-name="Trazado 7" d="M610.749,845.87c-8.481-.341,13.388.169,29.547-38.349s11.62-72.734,33.926-120.385,75.066-80.386,93.368-102.691,21.289-70.12,42.907-84.172c28.532-33.126,123.948-56.3,123.948-56.3s-66.3,28.477-87.851,39.025-26.667,43.631-24.732,50.643S838.168,629.9,812.133,660.2s-62.5,48.582-85.841,74.169-43.117,56.407-56.134,75.035S619.23,846.211,610.749,845.87Z" transform="translate(347.57 29.125)" fill="#2b7efe" />
                                        <path id="Trazado_8" data-name="Trazado 8" d="M520.014,439.215s38.328,83.561,82.18,129.831,93.23,55.247,93.23,55.247,25.034-.518,37.292-15.193,16.092-40.241,13.121-51.794c-6.215-24.171-35.475-38.234-50.413-44.888-16.825-6.366-93.23-29.7-93.23-29.7Z" transform="translate(347.57 29.125)" fill="#01d5ac" />
                                        <path id="Trazado_9" data-name="Trazado 9" d="M751.886,590a31.632,31.632,0,0,0,4.046-24.251c-3.3-14.066-13.161-22.506-17.248-32.015s-4.86-44.481,19.373-62.543,45.9-3.465,74.927-7.239,41.178-7.858,41.178-7.858L930.7,443.04s-94.558,27.092-110.495,37.717c-8.239,5.493-22.636,15.944-30.918,30.349-7.511,13.064-6.671,20.195-6.915,28.919C781.87,558.087,751.886,590,751.886,590Z" transform="translate(347.57 29.125)" fill="#9ee677" />
                                    </g>
                                </svg>
                            </span>
                            <span className='nav-logo-name'>Kpinza</span>
                        </span>

                        <div className='nav-list'>

                            <NavLink to='/inicio' className='nav-link' activeclassname="active">
                                <i className='fa-solid fa-house nav-link-icon'></i>
                                <span className='nav-link-name'>Inicio</span>
                            </NavLink>
                            <NavLink to='/notificaciones' className="nav-link" activeclassname="active">
                                {secureLocalStorage.getItem("isSeen") === 1 && <Dot />}
                                <IoNotifications className="nav-link-icon" />
                                <span className='nav-link-name'>Notificaciones</span>
                            </NavLink>
                            <>
                                {isTabletOrMobile && (
                                    <MenuTablet />
                                )}
                                {!isTabletOrMobile && (
                                    <div className='nav-link d-flex' role='button' onClick={showSubnav}>
                                        <i className='fa-sharp fa-solid fa-briefcase nav-link-icon'></i>
                                        <span className='nav-link-name'>Proyectos</span>
                                        {rol === false ?
                                            <span style={{ marginLeft: '-9px' }}>
                                                {!subnav ? <FiChevronRight /> : <FiChevronDown />}
                                            </span> : null
                                        }
                                    </div>
                                )}
                                {subnav && (show == true) && (rol === false) && !isTabletOrMobile && (
                                    <>
                                        <div className="text-center p-0 pb-3 m-0">
                                            <CreateProjectModal createProject={createProject} />
                                        </div>
                                        <div className="overflow-auto sidebarScroll">
                                            {dataProyectos.length > 0 && dataProyectos.map(item => (
                                                <DropdownLink to={'/proyectos/' + item.project_url} key={item.id} style={{ color: '#EAEDED' }}>
                                                    <FiBox />
                                                    <SidebarLabel>{cutString(item.project_name)}</SidebarLabel>
                                                </DropdownLink>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </>
                        </div>
                    </div>

                    <NavLink onClick={logout} to='/iniciar-sesion' className='nav-link' activeclassname="active">
                        <i className='fas fa-sign-out nav-link-icon'></i>
                        <span className='nav-link-name'>Salir</span>
                    </NavLink>
                </nav>
            </aside>
            {/* Luego se hace pasar el proveedor y sus valores.
                Ya deberia poder utilizarse useContext en los componentes
                anidados. - Rodrigo Montalvan */}
            {/* INICIO CONTENIDO */}
            <SideBarContext.Provider value={{ show, setShow }}>
                <div className="container-fluid mb-5 mt-5">
                    {props.content}
                </div>
            </SideBarContext.Provider>
            {/* FIN CONTENIDO */}

        </main>
    )
}


export default Sidebar