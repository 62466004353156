import { useRef, useState } from 'react'
import axios from 'axios'
import { useNavigate, Navigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import secureLocalStorage from 'react-secure-storage'
import { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Msg from '../../containers/Message';

function LoginView() {

    const [mensaje, setMensaje] = useState('')
    const [usado, setUsado] = useState()

    const supervisor = useRef()

    const Mensaje = () => {
        return (
            <div className="">
                {mensaje}
            </div>
        )
    }

    const handleError = (tipo) => {
        const inputEmailField = document.getElementById('input-email')
        const inputPasswordField = document.getElementById('input-password')
        const inputPassword = document.getElementById('signin').elements['password']

        if (tipo == 'email' && !usado) {
            inputEmailField.classList.add('registrarse-invalido')
            setMensaje('El usuario no se ha encontrado')
        } else if (tipo == 'password') {
            inputPasswordField.classList.add('registrarse-invalido')
            inputPassword.value === '' ? setMensaje('Ingrese contraseña.') : setMensaje('Contraseña incorrecta. Intente denuevo')
        } else if (tipo == 'verificar') {
            toast(<Msg contenido={"Debe verificar su cuenta."}
            subcontenido={"Revise su carpeta de spam en su correo."}/>, 
            {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                type: "warning",
                theme: "light"
            })
        }
    }

    const handleChange = (e, tipo) => {
        const inputEmailField = document.getElementById('input-email')
        const inputPasswordField = document.getElementById('input-password')
        if (tipo === 'email') {
            if (e.target.value !== '') {
                setMensaje('')
                inputEmailField.classList.remove('registrarse-invalido')
            }
        } else {
            setMensaje('')
            inputPasswordField.classList.remove('registrarse-invalido')
        }

    }

    const navigate = useNavigate()

    const login = async (e) => {
        e.preventDefault()
        try {
            const res = await axios.post(
                'https://kpinza.cl/api/signin/',
                {
                    email: e.target['email'].value,
                    password: e.target['password'].value
                }
            )

            if (res.status == 200) {
                secureLocalStorage.setItem('token', res.data.token)
                localStorage.setItem('supervisor', supervisor.current.checked)
                secureLocalStorage.setItem('user', res.data.user)
                secureLocalStorage.setItem('name', res.data.name)
                secureLocalStorage.setItem("alias", res.data.alias)
                secureLocalStorage.setItem('auth', true)
                secureLocalStorage.setItem("isSeen", 0)

                const notifs = await axios.get(`https://kpinza.cl/api/is-seen/${secureLocalStorage.getItem("user")}/`,
                    {
                        headers: {
                            "Authorization": "Token " + secureLocalStorage.getItem("token")
                        }
                    })

                if (notifs.status === 200) {
                    secureLocalStorage.setItem("isSeen", notifs.data.notifs)
                }

                return navigate('/inicio', { state: true })
            }
        } catch (error) {
            if (error.response.status === 404) {
                // Usuario no encontrado
                e.target['email'].value = ''
                handleError('email')
            } else if (error.response.status === 400) {
                // Contraseña incorrecta
                handleError('password')
            } else if (error.response.status === 403) {
                handleError('verificar')
            }
        }
    }

    return (
        <>
            <div className="humming">
                <svg className='birb'
                    viewBox="0 0 414.43 406.66"
                    style={{
                        filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, .4))"
                    }}>
                    <g id="Grupo_1" data-name="Grupo 1" transform="translate(-867.584 -468.34)">
                        <path id="Trazado_7" data-name="Trazado 7" d="M610.749,845.87c-8.481-.341,13.388.169,29.547-38.349s11.62-72.734,33.926-120.385,75.066-80.386,93.368-102.691,21.289-70.12,42.907-84.172c28.532-33.126,123.948-56.3,123.948-56.3s-66.3,28.477-87.851,39.025-26.667,43.631-24.732,50.643S838.168,629.9,812.133,660.2s-62.5,48.582-85.841,74.169-43.117,56.407-56.134,75.035S619.23,846.211,610.749,845.87Z" transform="translate(347.57 29.125)" fill="#2b7efe" />
                        <path id="Trazado_8" data-name="Trazado 8" d="M520.014,439.215s38.328,83.561,82.18,129.831,93.23,55.247,93.23,55.247,25.034-.518,37.292-15.193,16.092-40.241,13.121-51.794c-6.215-24.171-35.475-38.234-50.413-44.888-16.825-6.366-93.23-29.7-93.23-29.7Z" transform="translate(347.57 29.125)" fill="#01d5ac" />
                        <path id="Trazado_9" data-name="Trazado 9" d="M751.886,590a31.632,31.632,0,0,0,4.046-24.251c-3.3-14.066-13.161-22.506-17.248-32.015s-4.86-44.481,19.373-62.543,45.9-3.465,74.927-7.239,41.178-7.858,41.178-7.858L930.7,443.04s-94.558,27.092-110.495,37.717c-8.239,5.493-22.636,15.944-30.918,30.349-7.511,13.064-6.671,20.195-6.915,28.919C781.87,558.087,751.886,590,751.886,590Z" transform="translate(347.57 29.125)" fill="#9ee677" />
                    </g>
                </svg>
            </div>
            <div className='phrase'>
                <span
                    style={{
                        color: "white",
                        textShadow: "1px  1px  1px  black"
                    }}>
                    KPINZA
                </span>
                <div className='sub-phrase'>
                    <span>
                        <svg
                            width="112"
                            height="19"
                            viewBox="0 0 112 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style=
                            {{
                                filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, .7))"
                            }}>
                            <path d="M7.08542 1.5762C13.9413 1.21592 15.567 4.69331 15.5228 6.47704H12.1982C12.1982 4.73923 9.94644 4.19881 7.74769 4.19881C5.20455 4.19881 2.75413 5.64257 3.12501 8.22544C3.49588 10.8083 5.24429 11.762 7.74769 11.762C9.82094 11.762 11.9863 11.3514 12.4498 9.56324H7.74769V7.56317H15.5228V14.0932H13.1519V12.1329C13.1519 12.3978 11.112 13.5899 11.112 13.5899C7.74769 14.7952 0.674589 14.835 0.0520507 8.88772C-0.570487 2.94049 4.53345 1.50114 7.08542 1.5762Z" fill="white"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M31.0996 9.61622C31.0996 7.08633 30.0214 3.40143 23.3245 3.44381C18.2382 3.476 17.0461 6.47703 16.7414 8.62281C15.8057 15.2134 29.0995 16.4509 30.7949 10.4242H27.9074C27.2054 12.0137 20.2383 12.9541 19.8409 9.61622H31.0996ZM19.9866 7.62937H27.7087C27.6954 6.98475 26.8504 5.71407 23.5761 5.78824C20.6356 5.85485 19.8188 7.04656 19.9866 7.62937Z" fill="white"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M45.8418 14.0535C45.8418 7.72212 46.7955 4.14583 39.6694 3.44382C37.6605 3.57186 33.4732 3.97894 32.795 4.58293L33.7884 6.78168C36.7068 5.96488 43.206 5.28494 42.7026 8.17246C38.9983 7.23203 31.64 6.53532 31.8413 11.2719C31.7883 13.0777 33.8864 15.9423 42.7026 12.9541V14.0535H45.8418ZM42.7026 10.8613V10.0666C40.9675 9.32482 34.1805 8.71023 34.7421 10.8613C35.3037 13.0124 40.2831 11.7576 42.7026 10.8613Z" fill="white"></path>
                            <path d="M47.299 0.052948H50.2262V14.0534H47.299V0.052948Z" fill="white"></path>
                            <path d="M51.1798 3.64249H54.5177L58.9549 12.1593L62.9948 3.64249H66.2267L59.2993 18.3847H56.0144L58.1072 14.0535H56.5575L51.1798 3.64249Z" fill="white"></path>
                            <path d="M69.7235 1.54974L72.77 0.635803V3.72201H77.5251V6.2519H72.77C72.2534 13.8738 74.0283 11.4044 77.5251 11.7488V14.0535C67.432 15.3118 70.0679 10.0401 69.7235 6.2519H67.1406V3.72201H69.7235V1.54974Z" fill="white"></path>
                            <path d="M79.0085 3.65573H81.8961V14.0005H79.0085V3.65573Z" fill="white"></path>
                            <path d="M79.0085 2.54311H81.8961V-3.05176e-05H79.0085V2.54311Z" fill="white"></path>
                            <path d="M88.3335 3.61602C94.0953 2.87426 96.5633 4.79488 97.3669 7.69564H94.3204C92.1217 4.51673 85.5387 5.72207 86.1082 9.04669C86.1082 13.0971 94.3204 12.1594 94.3204 9.82817H97.3669C97.0384 13.5687 92.4625 14.3184 90.2143 14.3184C81.2074 14.3184 81.0105 4.55874 88.3335 3.61602Z" fill="white"></path>
                            <path d="M109.54 3.9869C111.871 4.5803 111.791 5.28496 111.791 6.7817H109.063C109.063 5.37768 103.235 5.33794 101.592 6.23863C101.341 6.7817 101.039 7.44662 105.394 7.66915C107.791 7.5102 111.586 7.97622 111.791 11.06C111.99 14.0402 107.747 14.1594 105.725 14.3184C99.1762 14.4773 98.0248 11.8503 98.2676 10.5169H101.049C100.562 11.9263 104.246 12.102 106.149 12.0137C109.095 12.1726 109.637 11.4441 109.54 11.06C110.133 10.1275 106.661 9.85907 104.851 9.84141C99.4862 9.78907 98.4051 8.2387 98.5326 6.23863C98.7445 2.91401 106.626 3.24515 109.54 3.9869Z" fill="white"></path>
                        </svg>
                    </span>
                </div>
            </div>
            <div className="containerLoginRegister">
                <ToastContainer/>
                <div className="login-register">
                    <form onSubmit={login} className="sign-up-form" id='signin' name='signin'>
                        <h2 className="title">Iniciar sesión</h2>
                        <Mensaje />
                        <div className="input-field" id='input-email'>
                            <i className="fas fa-envelope"></i>
                            <input type="email" placeholder="Email" name='email' onChange={(e) => handleChange(e, 'email')} />
                        </div>
                        <div className="input-field" id='input-password'>
                            <i className="fas fa-lock"></i>
                            <input type="password" placeholder="Contraseña" name='password' onChange={(e) => handleChange(e, 'password')} />
                        </div>
                        {/* Se esconde la opcion para ingresar como supervisor.
                            Solo para evitar que se rompa algo mas adelante - Rodrigo Montalvan*/}
                        <div className="form-check pt-2 pb-2" style={{ display: "none" }}>
                            <input className="form-check-input" ref={supervisor} type="checkbox" id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Ingresar como supervisor
                            </label>
                        </div>
                        <input type="submit" className="btnLoginRegister" value="Iniciar sesion" />
                        <a href="/cambio-pass">¿Ha olvidado su contraseña?</a>
                        <a href="/registrarse">¿Aun no eres miembro? <span className='fw-bold'>Registrarse</span></a>
                    </form>
                </div>
            </div>
        </>
    )
}

export default LoginView