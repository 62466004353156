import Collapsible from 'react-collapsible';
import { Contenido, EstudianteHeader, EstudiantesWrapper, Info, TresE } from './styles';
import EstInd from '../../ModalComp/EstudiantesIndicador';
import { InvisibleElement } from '../ProyectosList/styles';
import TaskSwitch from '../List/HeaderProyecto/TaskSwitch';
import { useState } from 'react'

function EstudiantesList() {

    const [open, setOpen] = useState(false)
    const [content, setContent] = useState('Abrir todos')

    return (
        <EstudiantesWrapper className='container-fluid m-auto p-0'>
            <div className="row align-items-center mb-3">
                <div className="col-12 col-lg-3">
                    <p className='h2'>Estudiantes</p>
                </div>
                <div className="col" style={{ flexGrow: '4' }}>
                    <table className='table d-flex m-0'>
                        <tbody className='table-none'>
                            <tr className='text-center'>
                                <td style={{ minWidth: '120px' }}>Eficiencia</td>
                                <td style={{ minWidth: '120px' }}>Eficacia</td>
                                <td style={{ minWidth: '120px' }}>Efectividad</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col">
                    <TaskSwitch content={content} setContent={setContent} open={open} setOpen={setOpen} type='estudiantes' />
                </div>
            </div>
            <Collapsible transitionTime={200} overflowWhenOpen={"visible"} easing='ease' open={open} trigger={<EstudianteHeader>
                Denisse Esperanza Alfaro Pérez
            </EstudianteHeader>}>
                <Contenido className='border-bottom'>
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-3">
                            <span className='ms-5'>E-Commerce + Admin Panel</span>
                        </div>
                        <div className="col d-flex" style={{ flexGrow: '4' }}>
                            <table className='table d-flex m-0'>
                                <tbody className='table-none'>
                                    <tr className='text-center'>
                                        <td style={{ minWidth: '120px' }}>20%</td>
                                        <td style={{ minWidth: '120px' }}>13%</td>
                                        <td style={{ minWidth: '120px' }}>10%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col">
                            <EstInd />
                        </div>
                    </div>
                </Contenido>
            </Collapsible>
            <Collapsible transitionTime={200} overflowWhenOpen={"visible"} easing='ease' open={open} trigger={<EstudianteHeader>
                Denisse Esperanza Alfaro Pérez
            </EstudianteHeader>}>
                <Contenido className='border-bottom'>
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-3">
                            <span className='ms-5'>E-Commerce + Admin Panel</span>
                        </div>
                        <div className="col d-flex" style={{ flexGrow: '4' }}>
                            <table className='table d-flex m-0'>
                                <tbody className='table-none'>
                                    <tr className='text-center'>
                                        <td style={{ minWidth: '120px' }}>20%</td>
                                        <td style={{ minWidth: '120px' }}>13%</td>
                                        <td style={{ minWidth: '120px' }}>10%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col">
                            <EstInd />
                        </div>
                    </div>
                </Contenido>
            </Collapsible>
            <Collapsible transitionTime={200} overflowWhenOpen={"visible"} easing='ease' open={open} trigger={<EstudianteHeader>
                Denisse Esperanza Alfaro Pérez
            </EstudianteHeader>}>
                <Contenido className='border-bottom'>
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-3">
                            <span className='ms-5'>E-Commerce + Admin Panel</span>
                        </div>
                        <div className="col d-flex" style={{ flexGrow: '4' }}>
                            <table className='table d-flex m-0'>
                                <tbody className='table-none'>
                                    <tr className='text-center'>
                                        <td style={{ minWidth: '120px' }}>20%</td>
                                        <td style={{ minWidth: '120px' }}>13%</td>
                                        <td style={{ minWidth: '120px' }}>10%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col">
                            <EstInd />
                        </div>
                    </div>
                </Contenido>
            </Collapsible>
        </EstudiantesWrapper>
    )
}

export default EstudiantesList