import { Component, useState } from "react";
import Switch from "react-switch";
import { COLORS } from "../../../colors";


class MaterialDesignSwitch extends Component {
    constructor() {
        super();
        this.state = { checked: true };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(checked) {
        this.setState({ checked });
        if (this.props.type === 'lista') {
            this.props.setCheck(this.state.checked)
            // this.props.setChecked(this.state.checked)
        } else if (this.props.type === 'estudiantes') {
            if (this.state.checked === false) {
                // Abierto
                this.props.setContent('Cerrar todos')
                this.props.setOpen(true)
            } else {
                // Cerrado
                this.props.setContent('Abrir todos')
                this.props.setOpen(false)
            }
        }
    }

    render() {
        return (
            <div className="ms-3">
                <label htmlFor="material-switch" className="d-flex gap-2" role='button'>
                    <span className="disableSelect">{this.props.content}</span>
                    <Switch

                        checked={this.state.checked}
                        onChange={this.handleChange}
                        onColor={COLORS.dark}
                        onHandleColor={COLORS.light}
                        handleDiameter={15}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={25}
                        width={48}
                        className="react-switch"
                        id="material-switch"
                    />
                </label>
            </div>
        )
    }
}

export default MaterialDesignSwitch