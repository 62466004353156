import React, { useContext, useState } from "react"
import styled from "styled-components"
import Popup from "reactjs-popup"
import { EditHeader } from "../components/List/HeaderProyecto/styles"
import { MdEdit } from "react-icons/md"
import FormEditUser from "../components/Form/FormEditUser"
import SideBarContext from "../components/navigation/SideBarContext"

const StyledPopup = styled(Popup)`
        &-overlay {
            background:rgba(0,0,0,.10);
            position: absolute;
            top: 0;
        }
    `
/* Modal que contiene el form para editar usuario - Rodrigo Montalvan */
function EditUserModal({ name, setName, alias, setAlias }) {

    const [open, setOpen] = useState(false)
    const {show, setShow} = useContext(SideBarContext)
    
    const openModal = () => {
        if (show === true) {
            setShow(false)
        }
        setOpen(true)
    }
    const closeModal = () => setOpen(false)


    const Modal = ({ name }) => (
        <>
            <EditHeader
                className="btn-medium" role="button"
                onClick={openModal}>
                <MdEdit />
            </EditHeader>
            <StyledPopup open={open} 
            closeOnDocumentClick
            onClose={closeModal}>
                <FormEditUser name={name} setName={setName}
                    alias={alias} setAlias={setAlias}
                    closeModal={closeModal}></FormEditUser>
            </StyledPopup>
        </>
    )

    return (
        <>
            <Modal name={name} />
        </>
    )
}

export default EditUserModal