import React from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
function Desvincular({ item }) {
    return (
        <>
            <div className='notification-header'>
                <p className='h3 fw-light mt-3 mb-3'>Te han desvinculado de un proyecto</p>
                <p className='h4 pb-3'><span className='fw-semibold'>Proyecto:</span>{item.data.project_name}</p>
            </div>

            <div className="row mt-5 m-auto">
                <div className="bg-light rounded p-3">
                    <p className='p-0 m-0'>El supervisor del proyecto ha autorizado tu desvinculación.</p>
                    <span>No tienes permiso para ingresar al proyecto.</span>
                </div>
            </div>
        </>
    )
}

export default Desvincular