import React, { useContext, useRef, useState } from 'react';
import { useEffect } from 'react';
import Select from 'react-select';
import { COLORS } from '../../colors';
import { Options } from './styles';
import chroma from 'chroma-js'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import SideBarContext from '../navigation/SideBarContext';
import Msg from '../../containers/Message';

const options = [
  { value: 1, label: 'Pendiente', color: COLORS.estados.Pendiente },
  { value: 2, label: 'Entregado', color: COLORS.estados.Entregado },
  { value: 3, label: 'En Curso', color: COLORS.estados.EnCurso },
  { value: 4, label: 'No Entregado', color: COLORS.estados.NoEntregado }
];

export default function EstadoTarea({ item, disabled, setStatus }) {

  const [selectValue, setValue] = useState(options[item.task_state_id - 1])
  const [bgColor, setBgColor] = useState()
  const { project_url } = useParams()
  const {show, setShow} = useContext(SideBarContext)

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: bgColor }),
    singleValue: (styles, { data }) => ({ ...styles, ...setBgColor(data.color) }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? color.alpha(0.1).css()
              : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
            ? chroma.contrast(color, 'white') > 2
              ? 'white'
              : 'black'
            : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',

        textShadow: "1px  1px  1px  black",

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
      };
    },
  }

  const changeState = async (state) => {
    if (state.value === 2) {
      if (show === true) {
        setShow(false)
      }
      if (checkTimeDates(item)) {
        Swal.fire({
          title: "¿Estás seguro?",
          text: "No podrás volver a editar ni eliminar la tarea.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Entregar tarea',
          cancelButtonText: 'Cancelar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const res = await axios.put(`https://kpinza.cl/api/change-task-state/${project_url}/`,
                {
                  user_id: secureLocalStorage.getItem("user"),
                  task_id: item.id,
                  task_state_id: state.value
                },
                {
                  headers: { 'Authorization': 'Token ' + secureLocalStorage.getItem('token') }
    
                })
              if (res.status === 200) {
                setStatus(state.value)
                setValue(options[state.value - 1])
              }
            } catch (error) {
              if (error.response.status === 401) {
                toast(<Msg contenido="Error: No autorizado." subcontenido={error.response.data.message} />, {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  type: 'warning',
                  theme: 'light'
                })
              } else if (result.isConfirmed) {
                Swal.fire(
                  'No cambios!',
                  '',
                  'error'
                )
              }
            }
          }
        })
      } else {
        setValue(options[item.task_state_id - 1])
        toast(<Msg contenido="Hay fechas y/o horas faltantes." subcontenido="No pueden faltar datos si quiere marcar una tarea como Entregada." />, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'warning',
          theme: 'light'
        })
      }
    } else {
      try {
        const res = await axios.put(`https://kpinza.cl/api/change-task-state/${project_url}/`,
          {
            user_id: secureLocalStorage.getItem("user"),
            task_id: item.id,
            task_state_id: state.value
          },
          {
            headers: { 'Authorization': 'Token ' + secureLocalStorage.getItem('token') }
          })
        if (res.status === 200) {
          setValue(options[state.value - 1])
        }
      } catch (error) {
        if (error.response.status === 401) {
          toast(<Msg contenido="Error: No autorizado." subcontenido={error.response.data.message} />, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: 'warning',
            theme: 'light'
          })
        }
      }
    }
  }

  function checkTimeDates(item) {
    //se revisa primero si los atributos estan en el item
    if ("frit" in item || "frtt" in item) {
      if (item.feit === null || item.fett === null || item.frit === null || item.frtt === null) {
        return false
      }
    } else {
      return false
    }
    if ("estimated_time" in item || "real_time" in item) {
      if (item.estimated_time === "" || item.real_time === "" || item.real_time === "0000") {
        return false
      }
    } else {
      return false
    }

    return true
  }

  return (
    <>
      {disabled ?
        <Select
          isDisabled={true}
          className='reactSelect'
          value={selectValue}
          onChange={(e) => changeState(e)}
          classNamePrefix='reactSelect'
          isSearchable={false}
          options={options}
          styles={colourStyles}
          components={{
            IndicatorSeparator: () => null
          }}
        /> :
        <Select
          className='reactSelect'
          value={selectValue}
          onChange={(e) => changeState(e)}
          classNamePrefix='reactSelect'
          isSearchable={false}
          options={options}
          styles={colourStyles}
          components={{
            IndicatorSeparator: () => null
          }}
        />}
    </>
  )
}