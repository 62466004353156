import styled from 'styled-components';
import { COLORS } from '../../colors'

export const FormContainer = styled.div`
        width: 400px;
        height: ${props => props.altura};
        background-color: white;
        padding: 5px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 5px;
        overflow: hidden;
        margin: 0 auto;
        margin-top: ${props => props.posicion};
        `

export const EditProyecto = styled(FormContainer)`
    position: absolute;
    width: 600px;
    right: -300px;
    margin-top: -190px;
`

export const ButtonBox = styled.div`
        width: 220px;
        margin: 10px auto;
        position: relative;
    `

export const AddButton = styled.button`
    background-color: ${COLORS.aero};
    &:hover {
        background-color: ${COLORS.aeroDark};
    }
`

export const BtnToggle = styled.button`
        padding: 10px 30px;
        background-color: transparent;
        border: 0;
        outline: none;
        position: relative;
    `

export const BtnBackground = styled.div`
        top: 0;
        left: ${props => props.slider + 'px'};
        position: absolute;
        width: 96px;
        height: 100%;
        background-color: ${COLORS.darkLight};
        border-radius: 30px;
        transition: .4s;
    `
export const FormInputs = styled.form`
    position: absolute;
    width: 300px;
    left: 50px;
`

export const SelectForm = styled.select`
text-align: left;
`
export const InfoProyectoBtn = styled.div`
    background-color: ${props => props.color};
    padding: 4px 9px;
    border-radius: 5px;
    * {
        font-size: 16px;
        color: white;
    }
`
