import { useContext, useState } from 'react'
import { DeleteBtnWrapper, DeleteButton, BtnStages, ModWrapper } from './styles'
import { MdModeEditOutline, MdClear } from 'react-icons/md'
import { COLORS } from '../../colors'
import CambiarNombreEtapa from './CambiarInputs/CambiarNombreEtapa'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import SideBarContext from '../navigation/SideBarContext'


function EtapaComp({ item, index, stages, setStages }) {

    const [hover, setHover] = useState(false)
    const { project_url } = useParams()
    const {show, setShow} = useContext(SideBarContext)

    async function deleteStage() {
        if (show === true) {
            setShow(false)
        }
        Swal.fire({
            title: '¿Eliminar la etapa?',
            text: "Se eliminarán también todas las tareas en su interior.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await axios.delete(
                        `https://kpinza.cl/api/delete-task-stage/${secureLocalStorage.getItem("user")}/${project_url}/${item.id}/`,
                        { 
                            headers: { 
                                'Authorization': 'Token ' + secureLocalStorage.getItem('token') 
                            } 
                        }
                    )
                    if (res.status == 200) {
                        Swal.fire(
                            'Se ha eliminado la etapa',
                            '',
                            'success'
                        )
                        setStages(stages.filter((x) => x.id !== item.id))
                    }
                } catch (error) {
                    Swal.fire(
                        'no se  ha eliminado la etapa',
                        '',
                        'error'
                    )
                }

            }
        })
    }
    function editStage() {
        if (show === true) {
            setShow(false)
        }
        Swal.fire({
            title: 'Cambiar nombre',
            input: 'text',
            inputValue: item.stage_name,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Guardar',
            inputValidator: async (value) => {
                if (!value) {
                    return 'No puedes dejar el campo en blanco.'
                }
                try {
                    const res = await axios.put(`https://kpinza.cl/api/change-stage-name/${project_url}/`,
                        {
                            user_id: secureLocalStorage.getItem("user"),
                            task_stage_id: item.id,
                            stage_name: value
                        },
                        {
                            headers: {
                                'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                            }
                        })
                    if (res.status === 200) {
                        // setProject({ ...project, project_name: value })
                        setStages(stages.map(x => x.id === item.id ? { ...x, stage_name: value } : x)
                        )
                    }
                } catch (e) {
                }
            }
        })
    }

    return (
        <>
            <span className='d-flex align-items-center' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
                <span className='p-3 m-0'>
                    Etapa {index + 1}: {item.stage_name}
                </span>
                {
                    hover && (
                        <ModWrapper>
                            <BtnStages hover={COLORS.darkred} color={COLORS.red} onClick={deleteStage}>
                                <MdClear />
                            </BtnStages>
                            <BtnStages hover={COLORS.light} color={COLORS.darkLight} onClick={editStage}>
                                <MdModeEditOutline />
                            </BtnStages>
                        </ModWrapper>
                    )
                }
            </span>
        </>
    )
}

export default EtapaComp