import styled from "styled-components";
import { COLORS } from "../../colors";


export const BriefsWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
`

export const BriefsHeader = styled.div`
    border-radius: 7px;
    padding: 15px 10px;
    margin-bottom: 5px;
    border: 1px solid ${COLORS.charcoalDark};
    color: ${COLORS.charcoal};
    transition: .3s;
    :hover {
        background-color: ${COLORS.purpleLight};
    }
`
/* Añadi varios estilos para el historial. - Rodrigo Montalvan */
export const RecordHeader = styled.div`
    background-color: ${COLORS.charcoalDark};
    border-radius: 7px;
    padding: 15px 10px;
    margin-bottom: 5px;
    color: ${COLORS.charcoal};
`

export const DeliveredTask = styled.div`
    border-radius: 7px;
    padding: 15px 10px;
    margin-bottom: 5px;
    border: 3px solid ${COLORS.estados.Entregado};
    color: ${COLORS.charcoal};
    transition: .3s;
    :hover {
        background-color: ${COLORS.estados.Entregado};
    }
`
export const DeliveredCollapse = styled.div`
    border-radius: 7px;
    padding: 15px 10px;
    margin-bottom: 5px;
    border: 3px solid ${COLORS.estados.Entregado};
    color: ${COLORS.charcoal};
`

export const PendingTask = styled.div`
    border-radius: 7px;
    padding: 15px 10px;
    margin-bottom: 5px;
    border: 3px solid ${COLORS.estados.Pendiente};
    color: ${COLORS.charcoal};
    transition: .3s;
    :hover {
        background-color: ${COLORS.estados.Pendiente};
    }
`

export const PendingCollapse = styled.div`
    border-radius: 7px;
    padding: 15px 10px;
    margin-bottom: 5px;
    border: 3px solid ${COLORS.estados.Pendiente};
    color: ${COLORS.charcoal};
`

export const InProgressTask = styled.div`
    border-radius: 7px;
    padding: 15px 10px;
    margin-bottom: 5px;
    border: 3px solid ${COLORS.estados.EnCurso};
    color: ${COLORS.charcoal};
    transition: .3s;
    :hover {
        background-color: ${COLORS.estados.EnCurso};
    }
`

export const InProgressCollapse = styled.div`
    border-radius: 7px;
    padding: 15px 10px;
    margin-bottom: 5px;
    border: 3px solid ${COLORS.estados.EnCurso};
    color: ${COLORS.charcoal};
`

export const UndeliveredTask = styled.div`
    border-radius: 7px;
    padding: 15px 10px;
    margin-bottom: 5px;
    border: 3px solid ${COLORS.estados.NoEntregado};
    color: ${COLORS.charcoal};
    transition: .3s;
    :hover {
        background-color: ${COLORS.estados.NoEntregado};
    }
`

export const UndeliveredCollapse = styled.div`
    border-radius: 7px;
    padding: 15px 10px;
    margin-bottom: 5px;
    border: 3px solid ${COLORS.estados.NoEntregado};
    color: ${COLORS.charcoal};
`

export const EditedTask = styled.div`
    border-radius: 7px;
    padding: 15px 10px;
    margin-bottom: 5px;
    border: 3px solid ${COLORS.yellow};
    color: ${COLORS.charcoal};
    transition: .3s;
    :hover {
        background-color: ${COLORS.yellow};
    }
`

export const EditedCollapse = styled.div`
    border-radius: 7px;
    padding: 15px 10px;
    margin-bottom: 5px;
    border: 3px solid ${COLORS.yellow};
    color: ${COLORS.charcoal};
`

export const DeletedTask = styled.div`
    border-radius: 7px;
    padding: 15px 10px;
    margin-bottom: 5px;
    border: 3px solid ${COLORS.red};
    color: ${COLORS.charcoal};
    transition: .3s;
    :hover {
        background-color: ${COLORS.red};
    }
`

export const DeletedCollapse = styled.div`
    border-radius: 7px;
    padding: 15px 10px;
    margin-bottom: 5px;
    border: 3px solid ${COLORS.red};
    color: ${COLORS.charcoal};
`

export const Arrow = styled.div`
    float: inline-start;
    font-size: xx-large;
    position: relative;
    top: -2px;
    left: -5px;
    line-height: 0px
`

export const FixedTable = styled.table`
    table-layout : fixed;
    width: 100%;
`