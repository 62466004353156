import styled from "styled-components";
import { COLORS } from "../../colors";

export const EstudiantesWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
`

export const EstudianteHeader = styled.div`
    background-color: ${COLORS.dark};
    padding: 10px;
    color: ${COLORS.whitesmoke};
    `

export const Contenido = styled.div`
    background-color: ${COLORS.light};
    padding: 5px;
    `

export const Info = styled.div`
    max-width: 70%;
    align-items: center;
`

export const TresE = styled.table`
    padding: 0;
    margin: 0;
    border-collapse: collapse;
    border-radius: .5em;
    overflow: hidden;
    background-color: lightgray;
    th, td {
        text-align: center;
    }
    td:nth-child(2) {
        border-left: 2px solid black;
        border-right: 2px solid black;
    }
    th:nth-child(2) {
        border-right: 2px solid black;
        border-left: 2px solid black;
    }
`

export const IndicadorModal = styled.div`
    max-width: 800px;
    background-color: white;
    padding: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 auto;
`
