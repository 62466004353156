import React from "react";
import { Component } from "react";

// Creat an CambiarNombreTarea component
class CambiarNombreEtapa extends Component {
    // Render a <span> element
    render() {
        return (
            <span>
                {
                    this.props.showInputEle ? (
                        <input
                            type="text"
                            value={this.props.value}
                            onChange={this.props.handleChange}
                            onBlur={this.props.handleBlur}
                            onKeyDown={this.props.handleKeyDown}
                            autoFocus
                            className="form-control form-control-sm"
                        />
                    ) : (
                        <span
                            onDoubleClick={this.props.handleDoubleClick}
                            className='remedial-span p-1 disableSelect nombre-tarea text-start'
                            // className="form-control"
                            style={{
                                display: "inline-block",
                                // height: "25px",
                                // minWidth: "",
                            }}
                        >
                            {this.props.value}
                        </span>
                    )
                }
            </span>
        );
    }
}

export default CambiarNombreEtapa;