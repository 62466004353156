import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'

const BriefSnap = () => {
    const [data, setData] = useState()

    const { project_url, id_brief } = useParams()

    // let data

    useEffect(() => {
        async function getData() {
            const res = await axios.get(`https://kpinza.cl/api/get-brief/${secureLocalStorage.getItem("user")}/${project_url}/${id_brief}/`,
                {
                    headers: {
                        'Authorization': 'Token ' + secureLocalStorage.getItem('token')
                    }
                })
            if (res.status === 200) {
                setData(res.data)
            }
        }
        getData()
    }, [])

    return (
        <div className='container-fluid'>
            <p className='display-6'>Estado del Proyecto</p>

            <table className='table table-brief table-bordered table-striped table-sm table-hover'>
                <thead>
                    <tr>
                        <th colSpan={4}>Nombre Etapa</th>
                        <th colSpan={2}>Tareas Etapa</th>
                        <th>Tareas Solicitadas a la Fecha</th>
                        <th>Tareas Realizadas a la Fecha</th>
                        <th>Duracion Estimada Total</th>
                        <th>Duracion Real</th>
                        <th>% Avance Etapa</th>
                        <th>% Atraso</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data !== undefined && data.data.etapas.map((etapa, index) => {
                            return (
                                <tr>
                                    <td className='text-start p-2' colSpan={4}>Etapa {index + 1}: {etapa.stage_name}</td>
                                    <td colSpan={2}>{etapa.totales}</td>
                                    <td>{etapa.estimadas}</td>
                                    <td>{etapa.entregadas}</td>
                                    <td>{etapa.tiempo_estimado}</td>
                                    <td>{etapa.tiempo_real}</td>
                                    <td>{etapa.avance}</td>
                                    <td>{etapa.atraso}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                <thead>
                    <tr>
                        <th colSpan={3}>% Avance Proyecto</th>
                        <th colSpan={3}>% Atraso Proyecto</th>
                        <th className='fst-italic' colSpan={1}>Eficacia</th>
                        <th className='fst-italic' colSpan={1}>Eficiencia</th>
                        <th className='fst-italic' colSpan={1}>Efectividad</th>
                        <th colSpan={7}>Remediales</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={3}>{data !== undefined && data.data.avance}</td>
                        <td colSpan={3}>{data !== undefined && data.data.atraso}</td>
                        <td colSpan={1}>{data !== undefined && data.data.indicadores.eficacia}%</td>
                        <td colSpan={1}>{data !== undefined && data.data.indicadores.efiencia}%</td>
                        <td colSpan={1}>{data !== undefined && data.data.indicadores.efectividad}%</td>
                        <td className='text-start p-4' colSpan={7}>
                            <p dangerouslySetInnerHTML={{ __html: data !== undefined && data.remedials }}></p>
                            {/* Doble click para añadir remedial (opcional) */}
                            {/* <Remedial
                            ref={remedialRef}
                                // value={texto}
                                // handleChange={(e) =>
                                //     setTexto(e.target.value)
                                // }
                            /> */}
                        </td>
                    </tr>
                </tbody>
            </table>
            
        </div >
    )
}

export default BriefSnap