import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { MdEdit } from 'react-icons/md'
import { GrClose } from "react-icons/gr"
import { EditProyecto, InfoProyectoBtn } from "./FormStyles";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Close } from "../List/HeaderProyecto/styles";
import Msg from "../../containers/Message";

/* Form para editar nombre y alias de usuario - Rodrigo Montalvan */
function FormEditUser({ name, setName, alias, setAlias, closeModal }) {
    const [hoverName, setHoverName] = useState(false)
    const [hoverAlias, setHoverAlias] = useState(false)

    const Header = styled.div`
        border-bottom: 1px solid gray;
        text-align: center;
        padding: 5px;
    `
    const editarNombreUsuario = () => {
        Swal.fire({
            title: 'Cambiar Nombre de Usuario',
            input: 'text',
            inputValue: name,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Guardar",
            inputValidator: async (value) => {
                if (!value) {
                    return "¡No puede ser vacío!"
                } else {
                    const res = await axios.put(`https://kpinza.cl/api/edit-user/`,
                        {
                            user_id: secureLocalStorage.getItem("user"),
                            name: value
                        },
                        { headers: { Authorization: "Token " + secureLocalStorage.getItem('token') } })
                    if (res.status === 200) {
                        secureLocalStorage.setItem("name", value)
                        setName(value)
                        toast(<Msg contenido={res.data.message} />, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            type: "success",
                            theme: "light"
                        })
                    }
                }
            }
        })
    }

    const editarAliasUsuario = () => {
        Swal.fire({
            title: "Cambiar Alias de Usuario",
            input: "text",
            inputValue: alias,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Guardar",
            inputValidator: async (value) => {
                if (!value) {
                    return "¡No pueder ser vacío!"
                } else {
                    const res = await axios.put(`https://kpinza.cl/api/edit-user/`,
                        {
                            user_id: secureLocalStorage.getItem("user"),
                            alias: value
                        },
                        { headers: { Authorization: "Token " + secureLocalStorage.getItem("token") } })
                    if (res.status === 200) {
                        secureLocalStorage.setItem("alias", value)
                        setAlias(value)
                        toast(<Msg contenido={res.data.message} />, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            type: "success",
                            theme: "light"
                        })
                    }
                }
            }
        })
    }

    return (
        <EditProyecto>
            <div className="container mt-4">
                <Close onClick={closeModal}>
                    <GrClose/> 
                </Close>
                <Header>
                    <p className="fw-light h2">Editar Usuario</p>
                </Header>
                <table className="table p-3 table-bordered">
                    <tbody>
                        <tr>
                            <td className="table-active" style={{verticalAlign: "middle"}}>
                                <span className="p-1">Nombre de Usuario</span>
                            </td>
                            <td className="d-flex justify-content-between align-items-center"
                                onMouseEnter={() => setHoverName(true)}
                                onMouseLeave={() => setHoverName(false)}>
                                <span className="p-1">{name}</span>
                                {hoverName && (
                                    <InfoProyectoBtn className="btn-medium"
                                        role="button"
                                        onClick={editarNombreUsuario}>
                                        <MdEdit />
                                    </InfoProyectoBtn>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td className="table-active">
                                <span className="p-1">Alias de Usuario</span>
                            </td>
                            <td className="d-flex justify-content-between align-items-center"
                                onMouseEnter={() => setHoverAlias(true)}
                                onMouseLeave={() => setHoverAlias(false)}>
                                <span className="p-1">{alias}</span>
                                {hoverAlias && (
                                    <InfoProyectoBtn className="btn-medium"
                                        role="button"
                                        onClick={editarAliasUsuario}>
                                        <MdEdit />
                                    </InfoProyectoBtn>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </EditProyecto>
    )
}

export default FormEditUser